import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert,
    Button,
    Form,
    Label,
    Input,
    FormGroup
} from "reactstrap";
import "../Tables/datatables.scss";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {api_url} from '../../components/Common/Variables';
import {Link} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PaginationControl} from 'react-bootstrap-pagination-control';

class Stanza extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t('Dashboard'), link: "#"},
                {title: this.props.t('Stanza Management'), link: "#"},
            ],
            updateModal: false,
            addModal: false,
            deleteModal: false,
            token: '',
            isloading: false,
            spinner: false,
            allPoems: [],
            getAllPoemsError: false,
            allStanzas: [],
            stanza_id: '',
            getAllStanzasError: false,
            poem_id: '',
            successAlert: false,
            successMsg: '',
            error: false,
            errorMsg: '',
            stanza_desc: '',
            stanza_full: '',
            stanza_translation: '',
            stanza_lang: 'ur',
            errorDesc: '',
            errorFull: '',
            errorTranslation: '',
            errorLang: '',
            errorPoem: '',
            allKeywords: [],
            keyword_id: '',
            keydeleteModal: false,
            keyNameError: '',
            keyStanzaError: '',
            currentPage: 1,
            total: 0,
            pageSize: 0,
            search_term: '',
        };

    }

    componentDidMount = async () => {
        const token = JSON.parse(localStorage.getItem('token'))
        this.setState({token: token}, () => {
            if (this.state.token) {
                this.getAllPoems();
                this.getAllStanzas(1);
                this.getAllkeywordsOfStanza()
            } else {
                console.log('no token')
                this.props.history.push('/login')
            }
        });
    }
    getAllStanzas = async (pageNo) => {
        this.setState({isloading: true})
        try {
            let allStanzas = await fetch(api_url + "get_all_stanza?page=" + pageNo + '&is_paginated=true', {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allStanzas.ok) {
                this.setState({isloading: false, getAllStanzasError: true});
                throw Error('Error');
            } else {
                allStanzas = await allStanzas.json();
                this.setState({
                    allStanzas: allStanzas.data.data, isloading: false,
                    total: allStanzas.data.total, pageSize: allStanzas.data.per_page
                });
                console.warn(this.state.allStanzas, 'all data', allStanzas);
            }
        } catch (err) {
            console.log('Error')
        }
    }
    getAllPoems = async () => {
        this.setState({isloading: true})
        try {
            let allPoems = await fetch(api_url + "get_all_poems?is_paginated=false", {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allPoems.ok) {
                this.setState({isloading: false, getAllPoemsError: true});
                throw Error('Error');
            } else {
                allPoems = await allPoems.json();
                this.setState({allPoems: allPoems.data, isloading: false});
                console.warn(this.state.allPoems);
            }
        } catch (err) {
            console.log('Error')
        }
    }
    deleteStanza = async () => {
        this.setState({spinner: true})
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("stanza_id", this.state.stanza_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "delete_stanza", requestOptions)
            .then(response => response.json())
            .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            spinner: false,
                            deleteModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Stanza') + this.props.t('Deleted Successfully')
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllStanzas();
                    }
                }
            )
            .catch(error => {
                console.log('error', error)
                this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
            });
    }
    addStanza = async () => {
        this.setState({spinner: true})
        console.log('desc', this.state.stanza_desc, 'full', this.state.stanza_full, 'trans', this.state.stanza_translation, 'lang', this.state.stanza_lang)
        if (this.state.stanza_desc) {
            this.setState({errorDesc: ''})
        } else {
            this.setState({errordesc: 'The Description field is required'})
        }
        if (this.state.stanza_full) {
            this.setState({errorFull: ''})
        } else {
            this.setState({errorFull: 'The Full Stanza field is required'})
        }
        if (this.state.stanza_translation) {
            this.setState({errorTranslation: ''})
        } else {
            this.setState({errorTranslation: 'The Translation field is required'})
        }
        if (this.state.stanza_lang) {
            this.setState({errorLang: ''})
        } else {
            this.setState({errorLang: 'The Stanza Language field is required'})
        }
        if (this.state.poem_id) {
            this.setState({errorPoem: ''})
        } else {
            this.setState({errorPoem: 'The Poem ID field is required'})
        }
        if (this.state.errorDesc || this.state.errorFull || this.state.errorTranslation || this.state.errorLang || this.state.errorPoem) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("full_stanza", this.state.stanza_full);
            formdata.append("desc", this.state.stanza_desc);
            formdata.append("translation", this.state.stanza_translation);
            formdata.append("language", this.state.stanza_lang);
            formdata.append("poem_id", this.state.poem_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "insert_stanza", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            addModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Stanza') + this.props.t('Added Successfully'),
                            spinner: false,
                            stanza_desc: '', stanza_full: '', stanza_translation: '', stanza_lang: '', poem_id: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllStanzas();
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    getStanzaDetail = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("stanza_id", this.state.stanza_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "detail_stanza", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === false) {
                    this.setState({error: true, errorMsg: 'Something Wrong'});
                } else {
                    this.setState({
                        stanza_desc: result.data.stanza.desc,
                        stanza_full: result.data.stanza.full_stanza,
                        stanza_translation: result.data.stanza.translation,
                        stanza_lang: result.data.stanza.language,
                        poem_id: result.data.stanza.poem_id
                    });
                }
            })
            .catch(error => console.log('error', error));
    }
    updateStanza = async () => {
        this.setState({spinner: true})
        if (this.state.stanza_desc) {
            this.setState({errorDesc: ''})
        } else {
            this.setState({errordesc: 'The Description field is required'})
        }
        if (this.state.stanza_full) {
            this.setState({errorFull: ''})
        } else {
            this.setState({errorFull: 'The Full Stanza field is required'})
        }
        if (this.state.stanza_translation) {
            this.setState({errorTranslation: ''})
        } else {
            this.setState({errorTranslation: 'The Translation field is required'})
        }
        if (this.state.stanza_lang) {
            this.setState({errorLang: ''})
        } else {
            this.setState({errorLang: 'The Stanza Language field is required'})
        }
        if (this.state.poem_id) {
            this.setState({errorPoem: ''})
        } else {
            this.setState({errorPoem: 'The Poem ID field is required'})
        }
        if (this.state.errorDesc || this.state.errorFull || this.state.errorTranslation || this.state.errorLang || this.state.errorPoem) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("full_stanza", this.state.stanza_full);
            formdata.append("desc", this.state.stanza_desc);
            formdata.append("translation", this.state.stanza_translation);
            formdata.append("language", this.state.stanza_lang);
            formdata.append("poem_id", this.state.poem_id);
            formdata.append("stanza_id", this.state.stanza_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "update_stanza", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            updateModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Stanza') + this.props.t('Updated Successfully'),
                            spinner: false,
                            stanza_desc: '', stanza_full: '', stanza_translation: '', stanza_lang: '', poem_id: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllStanzas();
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    getAllkeywordsOfStanza = async () => {
        this.setState({isloading: true})
        try {
            let allKeywords = await fetch(api_url + "get_list_keywordsstanza", {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allKeywords.ok) {
                this.setState({isloading: false});
                throw Error('Error');
            } else {
                allKeywords = await allKeywords.json();
                this.setState({allKeywords: allKeywords.data, isloading: false})
                console.warn(this.state.allKeywords);
            }
        } catch (err) {
            console.log('Error')
        }
    }
    deleteKeyword = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("keywords_stanza_id", this.state.keyword_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "deleteKeywordsstanza", requestOptions)
            .then(response => response.json())
            .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            spinner: false,
                            keydeleteModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Keyword') + this.props.t('Deleted Successfully')
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllkeywordsOfStanza();
                    }
                }
            )
            .catch(error => {
                console.log('error', error)
                this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
            });
    }
    addKeyword = async () => {
        this.setState({spinner: true, keyNameError: '', keyStanzaError: ''})
        if (this.state.keyName) {
            this.setState({keyNameError: ''})
        } else {
            this.setState({keyNameError: 'The Name field is required', spinner: false})
        }
        if (this.state.stanza_id) {
            this.setState({keyStanzaError: ''})
        } else {
            this.setState({keyStanzaError: 'The Stanza ID field is required', spinner: false})
        }
        if (this.state.keyNameError || this.state.keyStanzaError) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("keywords_name", this.state.keyName);
            formdata.append("stanza_id", this.state.stanza_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "insertKeywordsstanza", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: result.message, spinner: false});
                    } else {
                        this.setState({
                            successAlert: true,
                            successMsg: this.props.t('Keyword') + this.props.t('Added Successfully'),
                            spinner: false,
                            keyName: '', stanza_id: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllkeywordsOfStanza()
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    Search = async () => {
        this.setState({isloading: true });
        console.log(this.state.search_term);

        if(this.state.search_term==='')
        {
            this.getAllPoems();
            this.getAllStanzas(1);
            this.getAllkeywordsOfStanza()
        }
        else{
            try {
                let allStanzas = await fetch(api_url + "get_all_stanza?search=" + this.state.search_term, {
                    method: 'get',
                    headers: {
                        "Authorization": `Bearer ${this.state.token}`
                    },
                });
                if (!allStanzas.ok) {
                    this.setState({isloading: false, getAllStanzasError: true});
                    throw Error('Error');
                } else {
                    allStanzas = await allStanzas.json();
                    this.setState({
                        allStanzas: allStanzas.data, isloading: false,
                        total: 0, pageSize: 1
                    });
                    console.warn(this.state.allStanzas, 'all data', allStanzas);
                }
            } catch (err) {
                console.log('Error')
            }
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title={this.props.t('Stanza Management')} breadcrumbItems={this.state.breadcrumbItems}/>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Input
                                            type="text"
                                            className="mb-3 float-left"
                                            value={this.state.search_term}
                                            onChange={(e) => {
                                                this.setState({ search_term: e.target.value }, () => {
                                                    this.Search();
                                                });
                                            }}
                                            placeholder={this.props.t("Search")}
                                            style={{ width: '20vw' }}
                                        />
                                        <Button color="success" className="mb-3 float-right"
                                                onClick={() => this.setState({
                                                    addModal: true,
                                                    stanza_desc: '',
                                                    stanza_full: '',
                                                    stanza_translation: '',
                                                    stanza_lang: '',
                                                    poem_id: ''
                                                })}>
                                            <i className="mdi mdi-card-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Stanza')}
                                        </Button>
                                        {/* <MDBDataTable responsive striped bordered data={data} /> */}
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" striped bordered responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>{this.props.t('Id')}</th>
                                                        <th>{this.props.t('Stanza')}</th>
                                                        <th>{this.props.t('Description')}</th>
                                                        <th>{this.props.t('Translation')}</th>
                                                        <th>{this.props.t('Language')}</th>
                                                        <th>{this.props.t('Actions')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.isloading ?
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <center>
                                                                    <div className="lds-ripple">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        :
                                                        this.state.allStanzas ?
                                                            this.state.allStanzas.length > 0 ?
                                                                this.state.allStanzas.map((n) => (
                                                                    <tr>
                                                                        <th>{n.id}</th>
                                                                        <th style={{whiteSpace: 'pre'}}>
                                                                            <Link to={{
                                                                                pathname: "/stanzaDetail/" + n.id,
                                                                                state: n.id
                                                                            }}>{n.full_stanza}</Link>
                                                                        </th>
                                                                        <td>{n.desc}</td>
                                                                        <td>{n.translation}</td>
                                                                        <td>{n.language == 'ur' ? this.props.t('Urdu') : n.language == 'fa' ? this.props.t('Persian') : ''}</td>
                                                                        <td>
                                                                            <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
                                                                               title='Edit'
                                                                               onClick={(event) => this.setState({
                                                                                   stanza_id: event.currentTarget.id,
                                                                                   updateModal: true,
                                                                                   error: false,
                                                                                   errorMsg: ''
                                                                               }, this.getStanzaDetail)} id={n.id}
                                                                            ></i>
                                                                            <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
                                                                               title='Delete'
                                                                               onClick={(event) => this.setState({
                                                                                   stanza_id: event.currentTarget.id,
                                                                                   deleteModal: true
                                                                               })} id={n.id}
                                                                            ></i>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td colSpan={8}>
                                                                        <center>
                                                                            <div>{this.props.t('No Stanza Found')}</div>
                                                                        </center>
                                                                    </td>
                                                                </tr>
                                                            :
                                                            ''

                                                    }
                                                    </tbody>
                                                </Table>
                                                {this.state.total < this.state.pageSize ?
                                                    ''
                                                    :
                                                    <PaginationControl
                                                        page={this.state.currentPage}
                                                        between={3}
                                                        total={this.state.total}
                                                        limit={this.state.pageSize}
                                                        changePage={(page) => this.setState({currentPage: page}, () => {
                                                            this.getAllStanzas(page)
                                                        })}
                                                        ellipsis={1}
                                                    />
                                                }
                                            </div>
                                        </div>
                                        {/* <h3>{this.props.t('Keyword')}</h3>
                        <div className='d-flex'>
                        <Input className="w-25 mr-3" type="text" placeholder={this.props.t('Add Keyword')} value={this.state.keyName} onChange={(e)=>this.setState({keyName:e.target.value})}/>
                        <select id="stanza" onChange={(e)=>this.setState({stanza_id:e.target.value})} className='form-control w-25 mr-3'>
            <option selected disabled>{this.props.t('select a stanza')}</option>
          {this.state.allStanzas ?
                            this.state.allStanzas.length > 0 ?
                            this.state.allStanzas.map((n)=>(
                             <option value={n.id}>{n.full_stanza}</option>
                            ))
                            :
                            <option>{this.props.t('No Stanza Found')}</option>
                            :
                            ''
                           
  }
          </select>
                        {this.state.spinner ? <Spinner type="border" color="primary" className=" d-flex justify-content-center align-items-center" size="sm"/> :
                        <i className="mdi mdi-plus-outline font-size-18 cursor-pointer text-primary d-flex justify-content-center align-items-center" title={this.props.t('Add Keyword')} onClick={this.addKeyword}></i>}
                        </div>
                        {this.state.keyNameError ? <span className='text-danger'>{this.state.keyNameError}</span>:''}
                        {this.state.keyStanzaError ? <span className='text-danger'>{this.state.keyStanzaError}</span>:''}
                        <div className='keywords d-flex'>
                            {this.state.allKeywords ? 
                            this.state.allKeywords.length > 0 ?
                            this.state.allKeywords.map((n)=>(
                            <div className='keyword'>
                            <span className='mr-1'>{n.keywords_name}</span> 
                            <i className='mdi mdi-close cursor-pointer' title={this.props.t('Delete')} onClick={(event) => this.setState({ keyword_id: event.currentTarget.id,keydeleteModal:true })} id={n.id}></i>
                            </div>
                            ))
                            :
                            ''
                            :
                            ''
    }
                        </div> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Add modal */}
                        <Modal isOpen={this.state.addModal} centered={true} scrollable={true}>
                            <ModalHeader>{this.props.t('Add Stanza')}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="fullStanza">{this.props.t('Full Stanza')}</Label>
                                        <Input id="fullStanza" type="textarea" rows={5} value={this.state.stanza_full}
                                               onChange={(e) => this.setState({stanza_full: e.target.value})}/>
                                        {this.state.errorFull ?
                                            <span className='text-danger'>{this.state.errorFull}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t('Description')}</Label>
                                        <Input id="desc" type="textarea" rows={3} value={this.state.stanza_desc}
                                               onChange={(e) => this.setState({stanza_desc: e.target.value})}/>
                                        {this.state.errorDesc ?
                                            <span className='text-danger'>{this.state.errorDesc}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="translation">{this.props.t('Translation')}</Label>
                                        <Input id="translation" type="textarea" rows={5}
                                               value={this.state.stanza_translation}
                                               onChange={(e) => this.setState({stanza_translation: e.target.value})}/>
                                        {this.state.errorTranslation ?
                                            <span className='text-danger'>{this.state.errorTranslation}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="language">{this.props.t('Language')}</Label>
                                        <select id="language"
                                                onChange={(e) => this.setState({stanza_lang: e.target.value})}
                                                className='form-control'>
                                            <option value="fa"
                                                    selected={this.state.stanza_lang === 'fa' ? true : false}>{this.props.t('Persian')}</option>
                                            <option value="ur"
                                                    selected={this.state.stanza_lang === 'ur' ? true : false}>{this.props.t('Urdu')}</option>
                                        </select>
                                        {this.state.errorLang ?
                                            <span className='text-danger'>{this.state.errorLang}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="poem">{this.props.t('Poem')}</Label>
                                        <select id="poem" onChange={(e) => this.setState({poem_id: e.target.value})}
                                                className='form-control'>
                                            <option selected disabled>{this.props.t('Select a Poem')}</option>
                                            {this.state.allPoems ?
                                                this.state.allPoems.length > 0 ?
                                                    this.state.allPoems.map((n) => (
                                                        <option value={n.id}>{n.poem_name}</option>
                                                    ))
                                                    :
                                                    <option>{this.props.t('No Poem Found')}</option>
                                                :
                                                ''

                                            }
                                        </select>
                                        {this.state.errorPoem ?
                                            <span className='text-danger'>{this.state.errorPoem}</span> : ''}
                                    </FormGroup>
                                </Form>
                                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className="w-100"
                                            onClick={this.addStanza}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Add Stanza')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className="w-100"
                                            onClick={() => this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* update modal */}
                        <Modal isOpen={this.state.updateModal} centered={true} scrollable={true}>
                            <ModalHeader>{this.props.t('Stanza')} {this.props.t('Update')}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="fullStanza">{this.props.t('Full Stanza')}</Label>
                                        <Input id="fullStanza" type="textarea" rows={5} value={this.state.stanza_full}
                                               onChange={(e) => this.setState({stanza_full: e.target.value})}/>
                                        {this.state.errorFull ?
                                            <span className='text-danger'>{this.state.errorFull}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t('Description')}</Label>
                                        <Input id="desc" type="textarea" rows={3} value={this.state.stanza_desc}
                                               onChange={(e) => this.setState({stanza_desc: e.target.value})}/>
                                        {this.state.errorDesc ?
                                            <span className='text-danger'>{this.state.errorDesc}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="translation">{this.props.t('Translation')}</Label>
                                        <Input id="translation" type="textarea" rows={5}
                                               value={this.state.stanza_translation}
                                               onChange={(e) => this.setState({stanza_translation: e.target.value})}/>
                                        {this.state.errorTranslation ?
                                            <span className='text-danger'>{this.state.errorTranslation}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="language">{this.props.t('Language')}</Label>
                                        <select id="language"
                                                onChange={(e) => this.setState({stanza_lang: e.target.value})}
                                                className='form-control'>
                                            <option value="fa"
                                                    selected={this.state.stanza_lang === 'fa' ? true : false}>{this.props.t('Persian')}</option>
                                            <option value="ur"
                                                    selected={this.state.stanza_lang === 'ur' ? true : false}>{this.props.t('Urdu')}</option>
                                        </select>
                                        {this.state.errorLang ?
                                            <span className='text-danger'>{this.state.errorLang}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="poem">{this.props.t('Poem')}</Label>
                                        <select id="poem" onChange={(e) => this.setState({poem_id: e.target.value})}
                                                className='form-control'>
                                            <option selected disabled>{this.props.t('Select a Poem')}</option>
                                            {this.state.allPoems ?
                                                this.state.allPoems.length > 0 ?
                                                    this.state.allPoems.map((n) => (
                                                        <option value={n.id}
                                                                selected={n.id === this.state.poem_id}>{n.poem_name}</option>
                                                    ))
                                                    :
                                                    <option>{this.props.t('No Poem Found')}</option>
                                                :
                                                ''

                                            }
                                        </select>
                                        {this.state.errorPoem ?
                                            <span className='text-danger'>{this.state.errorPoem}</span> : ''}
                                    </FormGroup>
                                </Form>
                                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className="w-100"
                                            onClick={this.updateStanza}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Update')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className="w-100"
                                            onClick={() => this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* Delete modal */}
                        <Modal isOpen={this.state.deleteModal} centered={true}>
                            <ModalHeader>{this.props.t('Delete')} {this.props.t('Stanza')}</ModalHeader>
                            <ModalBody>
                                {this.props.t('delete text')}
                                {/* Are you sure to delete this? */}
                                {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className="w-100"
                                            onClick={this.deleteStanza}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Yes')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className="w-100"
                                            onClick={() => this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* keyword Delete modal */}
                        <Modal isOpen={this.state.keydeleteModal} centered={true}>
                            <ModalHeader>{this.props.t('Delete')} {this.props.t('Keyword')}</ModalHeader>
                            <ModalBody>
                                {this.props.t('delete text')}
                                {/* Are you sure to delete this? */}
                                {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className="w-100"
                                            onClick={this.deleteKeyword}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Yes')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className="w-100"
                                            onClick={() => this.setState({keydeleteModal: false})}>{this.props.t('No')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* success alert */}
                        {this.state.successAlert ?
                            <Alert color="success"
                                   style={{position: 'absolute', top: '50px', right: '25px', zIndex: '10000'}}>
                                {this.state.successMsg}
                            </Alert>
                            :
                            ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Stanza);