import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import {
	TabContent,
	TabPane,
	Collapse,
	NavLink,
	NavItem,
	CardText,
	Nav,
	Row,
	Col,
	Card,
	CardBody,
	Container,
	Table,
	Modal,
	ModalHeader,
	ModalFooter,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input,
	Button,
	Spinner,
	Alert,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { api_url } from "../../components/Common/Variables";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Select from "react-select";
class PoemDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ title: this.props.t("Dashboard"), link: "#" },
				{ title: this.props.t("Poems Management"), link: "#" },
			],
			token: "",
			isloading: false,
			poem_id: this.props.location.state,
			allStanzas: [],
			allKeywords: [],
			keyword_id: "",
			updatePoemModal: false,
			updateStanzaModal: false,
			addStanzaModal: false,
			deleteStanzaModal: false,
			keyupdateStanzaModal: false,
			deleteKeywordModal: false,
			addWordModal: false,
			updateWordModal: false,
			deleteWordModal: false,
			allPoems: [],
			allBooks: [],
			stanza_id: "",
			getAllStanzasError: false,
			successAlert: false,
			successMsg: "",
			error: false,
			errorMsg: "",
			poem_name: "",
			poem_desc: "",
			poem_full: "",
			poem_translation: "",
			poem_lang: "",
			poem_tagline: "",
			book_id: "",
			poem_stanzas: [],
			poem_keywords: [],
			poem_words: [],
			poem_audios: [],
			poem_videos: [],
			stanza_desc: "",
			stanza_full: "",
			stanza_translation: "",
			stanza_lang: "ur",
			word_id: "",
			word: "",
			meaning: "",
			errorDesc: "",
			errorFull: "",
			errorTranslation: "",
			errorLang: "",
			errorPoem: "",
			keyName: "",
			keyNameError: "",
			errorWord: "",
			errorMeaning: "",
			customActiveTab: "1",
			audio_id: "",
			audioTag: "",
			audio: "",
			newAudio: "",
			title: "",
			audioError: "",
			audioTagError: "",
			url: "",
			urlError: "",
			titleError: "",
			addAudioModal: false,
			updateAudioModal: false,
			deleteAudioModal: false,
			addVideoModal: false,
			updateVideoModal: false,
			deleteVideoModal: false,
			video: "",
			newVideo: "",
			videoError: "",
			v_url: "",
			v_urlError: "",
			videoTag: "",
			videoTagError: "",
			allSuggestions: [],
			currentPage: 1,
			total: 0,
			pageSize: 0,
			key_id: "",
			allCategories: [],
			category_id: [],
		};
		this.toggleCustom = this.toggleCustom.bind(this);
	}

	toggleCustom(tab) {
		if (this.state.customActiveTab !== tab) {
			this.setState({
				customActiveTab: tab,
			});
		}
	}
	componentDidMount = async () => {
		console.log("poem id is", this.state.poem_id);
		const token = JSON.parse(localStorage.getItem("token"));
		this.setState({ token: token }, () => {
			if (this.state.token) {
				this.getPoemDetail();
				this.getAllStanzas(1);
				this.getAllBooks();
				this.getAllStanzasWithoutPagination();
				this.getAllCategories();
			} else {
				console.log("no token");
				this.props.history.push("/login");
			}
		});
	};
	getAllBooks = async () => {
		try {
			let allBooks = await fetch(api_url + "get_all_books", {
				method: "get",
				headers: {
					Authorization: `Bearer ${this.state.token}`,
				},
			});
			if (!allBooks.ok) {
				this.setState({ isloading: false, getAllBooksError: true });
				throw Error("Error");
			} else {
				allBooks = await allBooks.json();
				this.setState({ allBooks: allBooks.data, isloading: false });
				console.warn(this.state.allBooks);
			}
		} catch (err) {
			console.log("Error");
		}
	};
	getPoemDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("poem_id", this.state.poem_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_poem", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: "Something Wrong" });
				} else {
					this.setState(
						{
							poem_name: result.data.poems.poem_name,
							poem_desc: result.data.poems.desc,
							poem_full: result.data.poems.full_poem,
							book_id: result.data.poems.book_id,
							poem_lang: result.data.poems.language,
							poem_tagline: result.data.poems.poem_tagline,
							isloading: false,
							poem_translation: result.data.poems.translation,
							poem_keywords: result.data.keywordspoem,
							poem_words: result.data.poems.words_meanings,
							poem_audios: result.data.poems.poem_audios,
							poem_videos: result.data.poems.poem_videos,
						},
						() => {
							console.log("keywords are", this.state.poem_keywords);
						}
					);
				}
			})
			.catch((error) => console.log("error", error));
	};

	getVideoIdFromUrl = (url) => {
		const urlParams = new URLSearchParams(new URL(url).search);
		return urlParams.get("v");
	};

	updatePoem = async () => {
		this.setState({ spinner: true });
		if (this.state.poem_name) {
			this.setState({ errorName: "" });
		} else {
			this.setState({ errorName: "The Name field is required" });
		}
		if (this.state.poem_full) {
			this.setState({ errorFull: "" });
		} else {
			this.setState({ errorFull: "The full poem field is required" });
		}
		if (this.state.poem_lang) {
			this.setState({ errorLang: "" });
		} else {
			this.setState({ errorLang: "The Poem Language field is required" });
		}
		if (this.state.errorName || this.state.errorFull || this.state.errorLang) {
			// if(this.state.errorDesc || this.state.errorFull || this.state.errorTranslation){
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("poem_name", this.state.poem_name);
			formdata.append("full_poem", this.state.poem_full);
			if (this.state.poem_tagline != null) {
				formdata.append("poem_tagline", this.state.poem_tagline);
			}
			if (this.state.poem_desc != null) {
				formdata.append("desc", this.state.poem_desc);
			}
			if (this.state.poem_translation != null) {
				formdata.append("translation", this.state.poem_translation);
			}
			formdata.append("language", this.state.poem_lang);
			formdata.append("book_id", this.state.book_id);
			formdata.append("poem_id", this.state.poem_id);

			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "update_poem", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							updatePoemModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Poem") + this.props.t("Updated Successfully"),
							spinner: false,
							isloading: true,
							poem_name: "",
							poem_tagline: "",
							poem_desc: "",
							poem_lang: "",
							poem_full: "",
							book_id: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("1");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	getAllCategories = async () => {
		this.setState({ isloading: true });
		try {
			let allCate = await fetch(api_url + "get_list_category", {
				method: "get",
				headers: {
					Authorization: `Bearer ${this.state.token}`,
				},
			});
			if (!allCate.ok) {
				this.setState({ getAllCateError: true });
				throw Error("Error");
			} else {
				allCate = await allCate.json();
				this.setState({ allCategories: allCate.data });
				console.warn("all categories", this.state.allCategories);
			}
		} catch (err) {
			console.log("Error");
		}
	};
	//  Stanza Actions
	getAllStanzas = async (pageNo) => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("poem_id", this.state.poem_id);
		formdata.append("is_defult_pagination", "yes");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "search_poems_stanza?page=" + pageNo, requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				this.setState({
					poem_stanzas: result.data.data,
					isloading: false,
					total: result.data.total,
					pageSize: result.data.per_page,
				});
			})
			.catch((error) => console.log("error", error));
	};
	getAllStanzasWithoutPagination = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("poem_id", this.state.poem_id);
		formdata.append("is_defult_pagination", "");

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "search_poems_stanza", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				this.setState({ allStanzas: result.data });
			})
			.catch((error) => console.log("error", error));
	};
	addStanza = async () => {
		this.setState({ spinner: true });
		console.log(
			"desc",
			this.state.stanza_desc,
			"full",
			this.state.stanza_full,
			"trans",
			this.state.stanza_translation,
			"lang",
			this.state.stanza_lang
		);
		if (this.state.stanza_desc) {
			this.setState({ errorDesc: "" });
		} else {
			this.setState({ errordesc: "The Description field is required" });
		}
		if (this.state.stanza_full) {
			this.setState({ errorFull: "" });
		} else {
			this.setState({ errorFull: "The Full Stanza field is required" });
		}
		if (this.state.stanza_translation) {
			this.setState({ errorTranslation: "" });
		} else {
			this.setState({ errorTranslation: "The Translation field is required" });
		}
		if (this.state.stanza_lang) {
			this.setState({ errorLang: "" });
		} else {
			this.setState({ errorLang: "The Stanza Language field is required" });
		}
		if (
			this.state.errorDesc ||
			this.state.errorFull ||
			this.state.errorTranslation ||
			this.state.errorLang
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("full_stanza", this.state.stanza_full);
			formdata.append("desc", this.state.stanza_desc);
			formdata.append("translation", this.state.stanza_translation);
			formdata.append("language", this.state.stanza_lang);
			formdata.append("poem_id", this.state.poem_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insert_stanza", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							addStanzaModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Stanza") + this.props.t("Added Successfully"),
							spinner: false,
							stanza_desc: "",
							stanza_full: "",
							stanza_translation: "",
							stanza_lang: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("4");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	getStanzaDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("stanza_id", this.state.stanza_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_stanza", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: "Something Wrong" });
				} else {
					this.setState({
						stanza_desc: result.data.stanza.desc,
						stanza_full: result.data.stanza.full_stanza,
						stanza_translation: result.data.stanza.translation,
						stanza_lang: result.data.stanza.language,
						poem_id: result.data.stanza.poem_id,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
	updateStanza = async () => {
		this.setState({ spinner: true });
		if (this.state.stanza_desc) {
			this.setState({ errorDesc: "" });
		} else {
			this.setState({ errordesc: "The Description field is required" });
		}
		if (this.state.stanza_full) {
			this.setState({ errorFull: "" });
		} else {
			this.setState({ errorFull: "The Full Stanza field is required" });
		}
		if (this.state.stanza_translation) {
			this.setState({ errorTranslation: "" });
		} else {
			this.setState({ errorTranslation: "The Translation field is required" });
		}
		if (this.state.stanza_lang) {
			this.setState({ errorLang: "" });
		} else {
			this.setState({ errorLang: "The Stanza Language field is required" });
		}
		if (
			this.state.errorDesc ||
			this.state.errorFull ||
			this.state.errorTranslation ||
			this.state.errorLang
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("full_stanza", this.state.stanza_full);
			formdata.append("desc", this.state.stanza_desc);
			formdata.append("translation", this.state.stanza_translation);
			formdata.append("language", this.state.stanza_lang);
			formdata.append("poem_id", this.state.poem_id);
			formdata.append("stanza_id", this.state.stanza_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "update_stanza", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							updateStanzaModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Stanza") + this.props.t("Updated Successfully"),
							spinner: false,
							stanza_desc: "",
							stanza_full: "",
							stanza_translation: "",
							stanza_lang: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("4");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	deleteStanza = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("stanza_id", this.state.stanza_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "delete_stanza", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: "Something Wrong",
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteStanzaModal: false,
						successAlert: true,
						successMsg:
							this.props.t("Stanza") + this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getPoemDetail();
					this.toggleCustom("4");
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	// Stanza actions end
	// keyword actions
	addKeyword = async () => {
		this.setState({ spinner: true });
		if (this.state.keyName) {
			this.setState({ keyNameError: "" });
		} else {
			this.setState({ keyNameError: "The Name field is required" });
		}
		if (this.state.keyNameError) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			if (this.state.key_id) {
				formdata.append("keywords_id", this.state.key_id);
			} else {
				formdata.append("keywords_name", this.state.keyName);
			}
			formdata.append("poem_id", this.state.poem_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insertKeywordspoem", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							successAlert: true,
							successMsg:
								this.props.t("Keyword") + this.props.t("Added Successfully"),
							spinner: false,
							keyName: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("5");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	deleteKeyword = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("keywords_poem_id", this.state.keyword_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "deleteKeywordspoems", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: "Something Wrong",
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteKeywordModal: false,
						successAlert: true,
						successMsg:
							this.props.t("Keyword") + this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getPoemDetail();
					this.toggleCustom("5");
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	getSuggestions = async () => {
		var formdata = new FormData();
		formdata.append("word", this.state.keyName);

		var requestOptions = {
			method: "POST",
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "show-suggestions", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				this.setState({ allSuggestions: result.data });
			})
			.catch((error) => console.log("error", error));
	};
	// keyword actions end
	// words/meanings actions
	addNewWord = async () => {
		this.setState({ spinner: true });
		if (this.state.word) {
			this.setState({ errorWord: "" });
		} else {
			this.setState({ errorWord: "The Word field is required" });
		}
		if (this.state.meaning) {
			this.setState({ errorMeaning: "" });
		} else {
			this.setState({ errorMeaning: "The Meaning field is required" });
		}
		if (this.state.errorWord || this.state.errorMeaning) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("word", this.state.word);
			formdata.append("meaning", this.state.meaning);
			formdata.append("type", "Poem");
			formdata.append("id", this.state.poem_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insert_word_meaning", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							addWordModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Word/Meaning") +
								this.props.t("Added Successfully"),
							spinner: false,
							word: "",
							meaning: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("6");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	deleteWord = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("wm_id", this.state.word_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "delete_word_meaning", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: result.message,
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteWordModal: false,
						successAlert: true,
						successMsg:
							this.props.t("Word/Meaning") +
							this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getPoemDetail();
					this.toggleCustom("6");
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	getWordDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("wm_id", this.state.word_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_word_meaning", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: "Something Wrong" });
				} else {
					this.setState({
						word: result.data.word,
						meaning: result.data.meaning,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
	updateWord = async () => {
		this.setState({ spinner: true });
		if (this.state.word) {
			this.setState({ errorWord: "" });
		} else {
			this.setState({ errorWord: "The Word field is required" });
		}
		if (this.state.meaning) {
			this.setState({ errorMeaning: "" });
		} else {
			this.setState({ errorMeaning: "The Meaning field is required" });
		}
		if (this.state.errorWord || this.state.errorMeaning) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("word", this.state.word);
			formdata.append("meaning", this.state.meaning);
			formdata.append("wm_id", this.state.word_id);
			formdata.append("type", "Poem");
			formdata.append("id", this.state.poem_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "update_word_meaning", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: "Something Wrong",
							spinner: false,
						});
					} else {
						this.setState({
							updateWordModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Word/Meaning") +
								this.props.t("Updated Successfully"),
							spinner: false,
							word: "",
							meaning: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("6");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	// words/meanings actions end
	// audio actions
	addAudio = async () => {
		console.log(
			this.state.audio,
			this.state.url,
			this.state.poem_id,
			this.state.stanza_id
		);
		this.setState({ spinner: true });
		if (this.state.audio || this.state.url) {
			this.setState({ audioError: "" });
		} else {
			this.setState({
				audioError: "Either audio or URL is required",
				spinner: false,
			});
		}
		if (this.state.title) {
			this.setState({ titleError: "" });
		} else {
			this.setState({
				titleError: "The Audio Title field is required",
				spinner: false,
			});
		}
		if (this.state.audioTag) {
			this.setState({ audioTagError: "" });
		} else {
			this.setState({
				audioTagError: "The Audio Tag field is required",
				spinner: false,
			});
		}
		if (this.state.poem_id || this.state.stanza_id) {
			this.setState({ stanzaError: "" });
		} else {
			this.setState({
				stanzaError: "Either Poem id or Stanza id is required",
				spinner: false,
			});
		}
		if (
			this.state.audioError ||
			this.state.stanzaError ||
			this.state.audioTagError ||
			this.state.titleError
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.audio);
			formdata.append("youtubeurl", this.state.url);
			formdata.append("stanza_id", this.state.stanza_id);
			formdata.append("poem_id", this.state.poem_id);
			formdata.append("audio_tag", this.state.audioTag);
			formdata.append("audio_title", this.state.title);
			{
				this.state.category_id.map((n) => {
					formdata.append("category_id[]", n);
				});
			}
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insertaudio", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						if (result.message === "The youtubeurl format is invalid.") {
							this.setState({
								urlError: "The youtubeurl format is invalid.",
								spinner: false,
							});
						} else {
							this.setState({
								error: true,
								errorMsg: result.message,
								spinner: false,
							});
						}
					} else {
						this.setState({
							addAudioModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Audio") + this.props.t("Added Successfully"),
							spinner: false,
							audio: "",
							url: "",
							stanza_id: "",
							audioTag: "",
							title: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("7");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	getAudioDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("audio_id", this.state.audio_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_audio", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: "Something Wrong" });
				} else {
					result.data.audio_cat.map((n) => {
						this.setState(
							{ category_id: [...this.state.category_id, n.category_id] },
							() => {
								console.log("category id", this.state.category_id);
							}
						);
					});
					this.setState({
						audio: result.data.audio[0].name,
						url: result.data.audio[0].youtubeurl,
						poem_id: result.data.audio[0].poem_id,
						stanza_id: result.data.audio[0].stanza_id,
						audioTag: result.data.audio[0].audio_tag,
						title: result.data.audio[0].audio_title,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
	updateAudio = async () => {
		console.log(
			this.state.newAudio,
			this.state.url,
			this.state.poem_id,
			this.state.stanza_id
		);
		this.setState({ spinner: true });
		if (this.state.url || this.state.newAudio) {
			this.setState({ audioError: "" });
		} else {
			this.setState({
				audioError: "Either audio or URL is required",
				spinner: false,
			});
		}
		if (this.state.audioTag) {
			this.setState({ audioTagError: "" });
		} else {
			this.setState({
				audioTagError: "The Tag field is required",
				spinner: false,
			});
		}
		if (this.state.stanza_id) {
			this.setState({ stanzaError: "" });
		} else {
			this.setState({ stanzaError: "Stanza id is required", spinner: false });
		}
		if (
			this.state.audioError ||
			this.state.stanzaError ||
			this.state.titleError ||
			this.state.audioTagError
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.newAudio);
			formdata.append("youtubeurl", this.state.url);
			formdata.append("stanza_id", this.state.stanza_id);
			formdata.append("poem_id", this.state.poem_id);
			formdata.append("audio_id", this.state.audio_id);
			formdata.append("audio_tag", this.state.audioTag);
			{
				this.state.category_id.map((n) => {
					formdata.append("category_id[]", n);
				});
			}
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "updateaudio", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						if (result.message === "The youtubeurl format is invalid.") {
							this.setState({
								urlError: "The youtubeurl format is invalid.",
								spinner: false,
							});
						} else {
							this.setState({
								error: true,
								errorMsg: result.message,
								spinner: false,
							});
						}
					} else {
						this.setState({
							updateAudioModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Audio") + this.props.t("Updated Successfully"),
							spinner: false,
							audio: "",
							url: "",
							stanza_id: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("7");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	deleteAudio = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("audio_id", this.state.audio_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "deleteaudio", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: "Something Wrong",
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteAudioModal: false,
						successAlert: true,
						successMsg:
							this.props.t("Audio") + this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getPoemDetail();
					this.toggleCustom("7");
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	// audio actions end
	// video actions
	deleteVideo = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("video_id", this.state.video_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "deletevideo", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: "Something Wrong",
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteVideoModal: false,
						successAlert: true,
						successMsg:
							this.props.t("Video") + this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getPoemDetail();
					this.toggleCustom("8");
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	addVideo = async () => {
		this.setState({ spinner: true });
		if (this.state.video || this.state.v_url) {
			this.setState({ videoError: "" });
		} else {
			this.setState({
				videoError: "Either video or URL is required",
				spinner: false,
			});
		}
		if (this.state.videoTag) {
			this.setState({ videoTagError: "" });
		} else {
			this.setState({
				videoTagError: "The Video Tag field is required",
				spinner: false,
			});
		}
		if (this.state.stanza_id) {
			this.setState({ stanzaError: "" });
		} else {
			this.setState({ stanzaError: "The Stanza is required", spinner: false });
		}
		if (
			this.state.videoError ||
			this.state.stanzaError ||
			this.state.videoTagError
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.video);
			formdata.append("youtubeurl", this.state.v_url);
			formdata.append("stanza_id", this.state.stanza_id);
			formdata.append("poem_id", this.state.poem_id);
			formdata.append("video_tag", this.state.videoTag);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insertvideo", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						if (result.message === "The youtubeurl format is invalid.") {
							this.setState({
								urlError: "The youtubeurl format is invalid.",
								spinner: false,
							});
						} else {
							this.setState({
								error: true,
								errorMsg: result.message,
								spinner: false,
							});
						}
					} else {
						this.setState({
							addVideoModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Video") + this.props.t("Added Successfully"),
							spinner: false,
							video: "",
							v_url: "",
							stanza_id: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("8");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	getVideoDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("video_id", this.state.video_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_video", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: "Something Wrong" });
				} else {
					this.setState({
						video: result.data[0].name,
						v_url: result.data[0].youtubeurl,
						stanza_id: result.data[0].stanza_id,
						videoTag: result.data[0].video_tag,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
	updateVideo = async () => {
		console.log(
			this.state.newVideo,
			this.state.url,
			this.state.poem_id,
			this.state.stanza_id
		);
		this.setState({ spinner: true });
		if (this.state.url || this.state.video) {
			this.setState({ videoError: "" });
		} else {
			this.setState({
				videoError: "Either Video or URL field is required",
				spinner: false,
			});
		}
		if (this.state.videoTag) {
			this.setState({ videoTagError: "" });
		} else {
			this.setState({
				videoTagError: "The Video Tag field is required",
				spinner: false,
			});
		}
		if (this.state.stanza_id) {
			this.setState({ stanzaError: "" });
		} else {
			this.setState({ stanzaError: "The Stanza is required", spinner: false });
		}
		if (
			this.state.videoError ||
			this.state.stanzaError ||
			this.state.videoTagError
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.newVideo);
			formdata.append("youtubeurl", this.state.v_url);
			formdata.append("stanza_id", this.state.stanza_id);
			formdata.append("poem_id", this.state.poem_id);
			formdata.append("video_id", this.state.video_id);
			formdata.append("video_tag", this.state.videoTag);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "updatevideo", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						if (result.message === "The youtubeurl format is invalid.") {
							this.setState({
								urlError: "The youtubeurl format is invalid.",
								spinner: false,
							});
						} else {
							this.setState({
								error: true,
								errorMsg: result.message,
								spinner: false,
							});
						}
					} else {
						this.setState({
							updateVideoModal: false,
							successAlert: true,
							successMsg:
								this.props.t("Video") + this.props.t("Updated Successfully"),
							spinner: false,
							video: "",
							v_url: "",
							stanza_id: "",
							newVideo: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getPoemDetail();
						this.toggleCustom("8");
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	// video actions end
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							title={this.props.t("Poems Management")}
							breadcrumbItems={this.state.breadcrumbItems}
						/>

						<Row>
							<Col xs={12}>
								{this.state.isloading ? (
									<center>
										<div className="lds-ripple">
											<div></div>
											<div></div>
										</div>
									</center>
								) : (
									<Card>
										<CardBody>
											<h1 className="text-center">{this.state.poem_name}</h1>
											<Button
												color="secondary"
												outline
												title="Edit"
												className="float-right detail-edit-btn"
												onClick={() =>
													this.setState({
														updatePoemModal: true,
														error: false,
														errorMsg: "",
													})
												}
											>
												<i className="mdi mdi-pencil mr-3 font-size-18"></i>
												{this.props.t("Update Poem")}
											</Button>
											<Row className="mt-3 vertical-tab">
												<Col md={2}>
													<Nav
														pills
														className="flex-column"
														id="v-pills-tab"
														role="tablist"
														aria-orientation="vertical"
													>
														<NavLink
															id="v-pills-full-poem"
															style={{ cursor: "pointer" }}
															className={classnames(
																{ active: this.state.customActiveTab === "1" },
																"mb-2"
															)}
															onClick={() => {
																this.toggleCustom("1");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Full Poem")}
														</NavLink>
														<NavLink
															id="v-pills-poem-desc"
															style={{ cursor: "pointer" }}
															className={classnames(
																{ active: this.state.customActiveTab === "2" },
																"mb-2"
															)}
															onClick={() => {
																this.toggleCustom("2");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Poem Desc")}
														</NavLink>
														<NavLink
															id="v-pills-poem-trans"
															style={{ cursor: "pointer" }}
															className={classnames(
																{ active: this.state.customActiveTab === "3" },
																"mb-2"
															)}
															onClick={() => {
																this.toggleCustom("3");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Translation")}
														</NavLink>
														<NavLink
															id="v-pills-poem-stanza"
															style={{ cursor: "pointer" }}
															className={classnames({
																active: this.state.customActiveTab === "4",
															})}
															onClick={() => {
																this.toggleCustom("4");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Stanza or")}
														</NavLink>
														<NavLink
															id="v-pills-poem-keywords"
															style={{ cursor: "pointer" }}
															className={classnames({
																active: this.state.customActiveTab === "5",
															})}
															onClick={() => {
																this.toggleCustom("5");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Keyword")}
														</NavLink>
														<NavLink
															id="v-pills-poem-words"
															style={{ cursor: "pointer" }}
															className={classnames({
																active: this.state.customActiveTab === "6",
															})}
															onClick={() => {
																this.toggleCustom("6");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Word/Meaning")}
														</NavLink>
														<NavLink
															id="v-pills-poem-audio"
															style={{ cursor: "pointer" }}
															className={classnames({
																active: this.state.customActiveTab === "7",
															})}
															onClick={() => {
																this.toggleCustom("7");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Audio")}
														</NavLink>
														<NavLink
															id="v-pills-poem-video"
															style={{ cursor: "pointer" }}
															className={classnames({
																active: this.state.customActiveTab === "8",
															})}
															onClick={() => {
																this.toggleCustom("8");
															}}
															aria-controls="v-pills-home"
															aria-selected="true"
														>
															{this.props.t("Video")}
														</NavLink>
													</Nav>
												</Col>
												<Col md={10}>
													<TabContent
														activeTab={this.state.customActiveTab}
														className="text-muted mt-4 mt-md-0"
														id="v-pills-tabContent"
													>
														<TabPane
															tabId="1"
															role="tabpanel"
															aria-labelledby="v-pills-full-poem"
														>
															<center>
																<Input
																	type="textarea"
																	className="show-poem-field"
																	rows={15}
																	value={this.state.poem_full}
																	disabled
																	style={{ border: "none" }}
																/>
															</center>
														</TabPane>
														<TabPane
															tabId="2"
															role="tabpanel"
															aria-labelledby="v-pills-poem-desc"
														>
															<div>
																<Input
																	type="textarea"
																	className="show-poem-field"
																	rows={15}
																	value={this.state.poem_desc}
																	disabled
																	style={{ border: "none" }}
																/>
															</div>
														</TabPane>
														<TabPane
															tabId="3"
															role="tabpanel"
															aria-labelledby="v-pills-poem-trans"
														>
															<div>
																<Input
																	type="textarea"
																	className="show-poem-field"
																	rows={15}
																	value={this.state.poem_translation}
																	disabled
																	style={{ border: "none" }}
																/>
															</div>
														</TabPane>
														<TabPane
															tabId="4"
															role="tabpanel"
															aria-labelledby="v-pills-poem-stanza"
														>
															<div className="d-flex justify-content-between">
																<h3>{this.props.t("Stanza")}</h3>
																<Button
																	color="success"
																	title={this.props.t("Add Stanza")}
																	className="mb-3 float-right"
																	onClick={() =>
																		this.setState({
																			addStanzaModal: true,
																			stanza_desc: "",
																			stanza_full: "",
																			stanza_translation: "",
																			stanza_lang: "",
																		})
																	}
																>
																	<i className="mdi mdi-plus-outline mr-3 font-size-18"></i>
																	{this.props.t("Add Stanza")}
																</Button>
															</div>
															<div className="table-rep-plugin">
																<div
																	className="table-responsive mb-0"
																	data-pattern="priority-columns"
																>
																	<Table
																		id="tech-companies-1"
																		striped
																		bordered
																		responsive
																	>
																		<thead>
																			<tr>
																				<th>{this.props.t("Id")}</th>
																				<th>{this.props.t("Stanza")}</th>
																				<th>{this.props.t("Language")}</th>
																				<th>{this.props.t("Actions")}</th>
																			</tr>
																		</thead>
																		<tbody>
																			{this.state.poem_stanzas ? (
																				this.state.poem_stanzas.length > 0 ? (
																					this.state.poem_stanzas.map((n) => (
																						<tr>
																							<th>{n.id}</th>
																							<th>
																								<Link
																									to={{
																										pathname:
																											"/stanzaDetail/" + n.id,
																										state: n.id,
																									}}
																								>
																									{n.full_stanza}
																								</Link>
																							</th>
																							<td>
																								{n.language == "ur"
																									? this.props.t("Urdu")
																									: n.language == "fa"
																									? this.props.t("Persian")
																									: ""}
																							</td>
																							<td>
																								<i
																									className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
																									title="Edit"
																									onClick={(event) =>
																										this.setState(
																											{
																												stanza_id:
																													event.currentTarget
																														.id,
																												updateStanzaModal: true,
																												error: false,
																												errorMsg: "",
																											},
																											this.getStanzaDetail
																										)
																									}
																									id={n.id}
																								></i>
																								<i
																									className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
																									title="Delete"
																									onClick={(event) =>
																										this.setState({
																											stanza_id:
																												event.currentTarget.id,
																											deleteStanzaModal: true,
																										})
																									}
																									id={n.id}
																								></i>
																							</td>
																						</tr>
																					))
																				) : (
																					<tr>
																						<td colSpan={6}>
																							<center>
																								<div>
																									{this.props.t(
																										"No Stanza Found"
																									)}
																								</div>
																							</center>
																						</td>
																					</tr>
																				)
																			) : (
																				""
																			)}
																		</tbody>
																	</Table>
																	{this.state.total <= this.state.pageSize ? (
																		""
																	) : (
																		<PaginationControl
																			page={this.state.currentPage}
																			between={3}
																			total={this.state.total}
																			limit={this.state.pageSize}
																			changePage={(page) =>
																				this.setState(
																					{ currentPage: page },
																					() => {
																						this.getAllStanzas(page);
																					}
																				)
																			}
																			ellipsis={1}
																		/>
																	)}
																</div>
															</div>
														</TabPane>
														<TabPane
															tabId="5"
															role="tabpanel"
															aria-labelledby="v-pills-poem-keywords"
														>
															<div className="d-flex">
																<Input
																	className="w-25 mr-3"
																	type="text"
																	placeholder={this.props.t("Add Keyword")}
																	value={this.state.keyName}
																	onChange={(e) =>
																		this.setState(
																			{ keyName: e.target.value },
																			this.getSuggestions
																		)
																	}
																/>
																{this.state.spinner ? (
																	<Spinner
																		type="border"
																		color="primary"
																		className=" d-flex justify-content-center align-items-center"
																		size="sm"
																	/>
																) : (
																	<i
																		className="mdi mdi-plus-outline font-size-18 cursor-pointer text-primary d-flex justify-content-center align-items-center"
																		title={this.props.t("Add Keyword")}
																		onClick={this.addKeyword}
																	></i>
																)}
															</div>
															{this.state.allSuggestions ? (
																<ul className="keywords-list w-25">
																	{this.state.allSuggestions.length > 0
																		? this.state.allSuggestions.map((n) => (
																				<li
																					key={n.id}
																					onClick={() =>
																						this.setState({
																							allSuggestions: [],
																							keyName: n.keyword_name,
																							key_id: n.id,
																						})
																					}
																				>
																					{n.keyword_name}
																				</li>
																		  ))
																		: ""}
																</ul>
															) : (
																""
															)}
															{this.state.keyNameError ? (
																<span className="text-danger">
																	{this.state.keyNameError}
																</span>
															) : (
																""
															)}
															<div className="keywords d-flex">
																{this.state.poem_keywords
																	? this.state.poem_keywords.length > 0
																		? this.state.poem_keywords.map((n) => (
																				<div className="keyword">
																					<span className="mr-1">
																						{n.keywords.keyword_name}
																					</span>
																					<i
																						className="mdi mdi-close cursor-pointer"
																						title={this.props.t("Delete")}
																						onClick={(event) =>
																							this.setState({
																								keyword_id:
																									event.currentTarget.id,
																								deleteKeywordModal: true,
																							})
																						}
																						id={n.id}
																					></i>
																				</div>
																		  ))
																		: ""
																	: ""}
															</div>
														</TabPane>
														<TabPane
															tabId="6"
															role="tabpanel"
															aria-labelledby="v-pills-poem-words"
														>
															<div className="d-flex justify-content-between">
																<h3>{this.props.t("Word/Meaning")}</h3>
																<Button
																	color="success"
																	className="mb-3 float-right"
																	title={this.props.t("Add Word/Meaning")}
																	onClick={() =>
																		this.setState({ addWordModal: true })
																	}
																>
																	<i className="mdi mdi-plus-outline font-size-18"></i>
																	{this.props.t("Add Word/Meaning")}
																</Button>
															</div>
															<div className="table-rep-plugin">
																<div
																	className="table-responsive mb-0"
																	data-pattern="priority-columns"
																>
																	<Table
																		id="tech-companies-1"
																		striped
																		bordered
																		responsive
																	>
																		<thead>
																			<tr>
																				<th>{this.props.t("Word")}</th>
																				<th>{this.props.t("Meaning")}</th>
																				<th>{this.props.t("Actions")}</th>
																			</tr>
																		</thead>
																		<tbody>
																			{this.state.poem_words ? (
																				this.state.poem_words.length > 0 ? (
																					this.state.poem_words.map((n) => (
																						<tr>
																							<th>{n.word}</th>
																							<td>{n.meaning}</td>
																							<td>
																								<i
																									className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
																									title="Edit"
																									onClick={(event) =>
																										this.setState(
																											{
																												word_id:
																													event.currentTarget
																														.id,
																												updateWordModal: true,
																											},
																											this.getWordDetail
																										)
																									}
																									id={n.id}
																									//  onClick={()=>this.setState({updateStanzaModal:true})}
																								></i>
																								<i
																									className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
																									title="Delete"
																									onClick={(event) =>
																										this.setState({
																											word_id:
																												event.currentTarget.id,
																											deleteWordModal: true,
																										})
																									}
																									id={n.id}
																									// onClick={()=>this.setState({deleteStanzaModal:true})}
																								></i>
																							</td>
																						</tr>
																					))
																				) : (
																					<tr>
																						<td colSpan={6}>
																							<center>
																								<div>
																									{this.props.t(
																										"No Word/Meaning Found"
																									)}
																								</div>
																							</center>
																						</td>
																					</tr>
																				)
																			) : (
																				""
																			)}
																		</tbody>
																	</Table>
																</div>
															</div>
														</TabPane>
														<TabPane
															tabId="7"
															role="tabpanel"
															aria-labelledby="v-pills-poem-audio"
														>
															<div className="d-flex justify-content-between">
																<h3>{this.props.t("Audio")}</h3>
																<Button
																	color="success"
																	className="mb-3 float-right"
																	title={this.props.t("Add Audio")}
																	onClick={() =>
																		this.setState({ addAudioModal: true })
																	}
																>
																	<i className="mdi mdi-plus-outline font-size-18"></i>
																	{this.props.t("Add Audio")}
																</Button>
															</div>
															{this.state.poem_audios
																? this.state.poem_audios.length > 0
																	? this.state.poem_audios.map((n) => (
																			<Row>
																				<Col md={2}>{n.audio_tag}</Col>
																				<Col md={6}>
																					<audio src={n.youtubeurl} controls />
																				</Col>
																				<Col md={2}>
																					<i
																						className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
																						title="Edit"
																						onClick={(event) =>
																							this.setState(
																								{
																									audio_id:
																										event.currentTarget.id,
																									updateAudioModal: true,
																								},
																								this.getAudioDetail
																							)
																						}
																						id={n.id}
																					></i>
																				</Col>
																				<Col md={2}>
																					<i
																						className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
																						title="Delete"
																						onClick={(event) =>
																							this.setState({
																								audio_id:
																									event.currentTarget.id,
																								deleteAudioModal: true,
																							})
																						}
																						id={n.id}
																					></i>
																				</Col>
																			</Row>
																	  ))
																	: this.props.t("No Audio Found")
																: ""}
														</TabPane>
														<TabPane
															tabId="8"
															role="tabpanel"
															aria-labelledby="v-pills-poem-video"
														>
															<div className="d-flex justify-content-between">
																<h3>{this.props.t("Video")}</h3>
																<Row>
																	<Button
																		color="success"
																		className="mb-3 float-right"
																		title={this.props.t("Add Video")}
																		onClick={() =>
																			this.setState({ addVideoModal: true })
																		}
																	>
																		<i className="mdi mdi-plus-outline font-size-18"></i>
																		{this.props.t("Add Video")}
																	</Button>
																</Row>
															</div>
															{this.state.poem_videos ? (
																this.state.poem_videos.length > 0 ? (
																	<Row>
																		{this.state.poem_videos.map((n) => (
																			<Col md={4} lg={4} key={n.id}>
																				<div className="video-detail-container">
																					<iframe
																						src={`https://www.youtube.com/embed/${this.getVideoIdFromUrl(
																							n.youtubeurl
																						)}?rel=0&amp;enablejsapi=1&amp;showinfo=0`}
																						frameBorder="0"
																						allowFullScreen
																					></iframe>
																					<div className="overlay">
																						<div className="tag-text">
																							{n.video_tag}
																						</div>
																						<div className="edit-icons">
																							<div className="edit">
																								<i
																									className="mdi mdi-pencil font-size-18 cursor-pointer"
																									title="Edit"
																									onClick={(event) =>
																										this.setState(
																											{
																												video_id:
																													event.currentTarget
																														.id,
																												updateVideoModal: true,
																											},
																											this.getVideoDetail
																										)
																									}
																									id={n.id}
																								></i>
																							</div>
																							<div className="delete">
																								<i
																									className="mdi mdi-trash-can font-size-18 cursor-pointer"
																									title="Delete"
																									onClick={(event) =>
																										this.setState({
																											video_id:
																												event.currentTarget.id,
																											deleteVideoModal: true,
																										})
																									}
																									id={n.id}
																								></i>
																							</div>
																						</div>
																					</div>
																				</div>
																			</Col>
																		))}
																	</Row>
																) : (
																	<div>{this.props.t("No Video Found")}</div>
																)
															) : (
																""
															)}

															{/* {this.state.poem_videos ?
                              this.state.poem_videos.length > 0 ?
                              this.state.poem_videos.map((n=>(
                               
                                <Row>
                                <Col md={2}>
                                  {n.video_tag}
                                </Col>
                                <Col md={6}>
                                <video width="320" height="240" src={n.video_path} controls />
                                </Col>
                                <Col md={2}>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                onClick={(event) => this.setState({ video_id: event.currentTarget.id,updateVideoModal:true },this.getVideoDetail)} id={n.id}
                              ></i>
                                </Col>
                                <Col md={2}>
                              <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                              onClick={(event) => this.setState({ video_id: event.currentTarget.id,deleteVideoModal:true })} id={n.id}
                              ></i>
                                </Col>
                              </Row>
                              )))
                              :
                              this.props.t('No Video Found')
                            :
                            ''
                            } */}
														</TabPane>
													</TabContent>
												</Col>
											</Row>
										</CardBody>
									</Card>
								)}
							</Col>
						</Row>
						{/* update poem modal */}
						<Modal
							isOpen={this.state.updatePoemModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>
								{this.props.t("Update")} {this.props.t("Poem")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="poemName">{this.props.t("Name")}</Label>
										<Input
											id="poemName"
											type="text"
											value={this.state.poem_name}
											onChange={(e) =>
												this.setState({ poem_name: e.target.value })
											}
										/>
										{this.state.errorName ? (
											<span className="text-danger">
												{this.state.errorName}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="tagline">{this.props.t("Tag Line")}</Label>
										<Input
											id="tagline"
											type="text"
											value={this.state.poem_tagline}
											onChange={(e) =>
												this.setState({ poem_tagline: e.target.value })
											}
										/>
										{this.state.errorTagline ? (
											<span className="text-danger">
												{this.state.errorTagline}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="desc">{this.props.t("Description")}</Label>
										<Input
											id="desc"
											type="textarea"
											rows={3}
											value={this.state.poem_desc}
											onChange={(e) =>
												this.setState({ poem_desc: e.target.value })
											}
										/>
										{this.state.errorDesc ? (
											<span className="text-danger">
												{this.state.errorDesc}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="fullPoem">{this.props.t("Full Poem")}</Label>
										<Input
											id="fullPoem"
											type="textarea"
											rows={5}
											value={this.state.poem_full}
											onChange={(e) =>
												this.setState({ poem_full: e.target.value })
											}
										/>
										{this.state.errorFull ? (
											<span className="text-danger">
												{this.state.errorFull}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="translation">
											{this.props.t("Translation")}
										</Label>
										<Input
											id="translation"
											type="textarea"
											rows={5}
											value={this.state.poem_translation}
											onChange={(e) =>
												this.setState({ poem_translation: e.target.value })
											}
										/>
										{this.state.errorTranslation ? (
											<span className="text-danger">
												{this.state.errorTranslation}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<Row>
										<Col>
											<FormGroup>
												<Label for="language">{this.props.t("Language")}</Label>
												<select
													id="language"
													onChange={(e) =>
														this.setState({ poem_lang: e.target.value })
													}
													className="form-control"
												>
													<option
														value="fa"
														selected={
															this.state.poem_lang === "fa" ? true : false
														}
													>
														{this.props.t("Persian")}
													</option>
													<option
														value="ur"
														selected={
															this.state.poem_lang === "ur" ? true : false
														}
													>
														{this.props.t("Urdu")}
													</option>
												</select>
												{this.state.errorLang ? (
													<span className="text-danger">
														{this.state.errorLang}
													</span>
												) : (
													""
												)}
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label for="book">{this.props.t("Book")}</Label>
												<select
													id="book"
													onChange={(e) =>
														this.setState({ book_id: e.target.value })
													}
													className="form-control"
												>
													<option selected disabled>
														{this.props.t("Select a Book")}
													</option>
													{this.state.allBooks ? (
														this.state.allBooks.length > 0 ? (
															this.state.allBooks.map((n) => (
																<option
																	value={n.id}
																	selected={n.id === this.state.book_id}
																>
																	{n.name}
																</option>
															))
														) : (
															<option>{this.props.t("No Book Found")}</option>
														)
													) : (
														""
													)}
												</select>
												{this.state.errorBook ? (
													<span className="text-danger">
														{this.state.errorBook}
													</span>
												) : (
													""
												)}
											</FormGroup>
										</Col>
									</Row>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updatePoem}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updatePoemModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* stanza Add modal */}
						<Modal
							isOpen={this.state.addStanzaModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>{this.props.t("Add Stanza")}</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="fullStanza">
											{this.props.t("Full Stanza")}
										</Label>
										<Input
											id="fullStanza"
											type="textarea"
											rows={5}
											value={this.state.stanza_full}
											onChange={(e) =>
												this.setState({ stanza_full: e.target.value })
											}
										/>
										{this.state.errorFull ? (
											<span className="text-danger">
												{this.state.errorFull}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="desc">{this.props.t("Description")}</Label>
										<Input
											id="desc"
											type="textarea"
											rows={3}
											value={this.state.stanza_desc}
											onChange={(e) =>
												this.setState({ stanza_desc: e.target.value })
											}
										/>
										{this.state.errorDesc ? (
											<span className="text-danger">
												{this.state.errorDesc}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="translation">
											{this.props.t("Translation")}
										</Label>
										<Input
											id="translation"
											type="textarea"
											rows={5}
											value={this.state.stanza_translation}
											onChange={(e) =>
												this.setState({ stanza_translation: e.target.value })
											}
										/>
										{this.state.errorTranslation ? (
											<span className="text-danger">
												{this.state.errorTranslation}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="language">{this.props.t("Language")}</Label>
										<select
											id="language"
											onChange={(e) =>
												this.setState({ stanza_lang: e.target.value })
											}
											className="form-control"
										>
											<option
												value="fa"
												selected={
													this.state.stanza_lang === "fa" ? true : false
												}
											>
												{this.props.t("Persian")}
											</option>
											<option
												value="ur"
												selected={
													this.state.stanza_lang === "ur" ? true : false
												}
											>
												{this.props.t("Urdu")}
											</option>
										</select>
										{this.state.errorLang ? (
											<span className="text-danger">
												{this.state.errorLang}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.addStanza}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Add Stanza")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ addStanzaModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/*stanza update modal */}
						<Modal
							isOpen={this.state.updateStanzaModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>
								{this.props.t("Stanza")} {this.props.t("Update")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="fullStanza">
											{this.props.t("Full Stanza")}
										</Label>
										<Input
											id="fullStanza"
											type="textarea"
											rows={5}
											value={this.state.stanza_full}
											onChange={(e) =>
												this.setState({ stanza_full: e.target.value })
											}
										/>
										{this.state.errorFull ? (
											<span className="text-danger">
												{this.state.errorFull}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="desc">{this.props.t("Description")}</Label>
										<Input
											id="desc"
											type="textarea"
											rows={3}
											value={this.state.stanza_desc}
											onChange={(e) =>
												this.setState({ stanza_desc: e.target.value })
											}
										/>
										{this.state.errorDesc ? (
											<span className="text-danger">
												{this.state.errorDesc}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="translation">
											{this.props.t("Translation")}
										</Label>
										<Input
											id="translation"
											type="textarea"
											rows={5}
											value={this.state.stanza_translation}
											onChange={(e) =>
												this.setState({ stanza_translation: e.target.value })
											}
										/>
										{this.state.errorTranslation ? (
											<span className="text-danger">
												{this.state.errorTranslation}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="language">{this.props.t("Language")}</Label>
										<select
											id="language"
											onChange={(e) =>
												this.setState({ stanza_lang: e.target.value })
											}
											className="form-control"
										>
											<option
												value="fa"
												selected={
													this.state.stanza_lang === "fa" ? true : false
												}
											>
												{this.props.t("Persian")}
											</option>
											<option
												value="ur"
												selected={
													this.state.stanza_lang === "ur" ? true : false
												}
											>
												{this.props.t("Urdu")}
											</option>
										</select>
										{this.state.errorLang ? (
											<span className="text-danger">
												{this.state.errorLang}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updateStanza}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updateStanzaModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* stanza Delete modal */}
						<Modal isOpen={this.state.deleteStanzaModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("Stanza")}
							</ModalHeader>
							<ModalBody>
								{this.props.t("delete text")}
								{/* Are you sure to delete this? */}
								{this.state.error ? (
									<div className="text-danger">{this.state.errorMsg}</div>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteStanza}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteStanzaModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* keyword Delete modal */}
						<Modal isOpen={this.state.deleteKeywordModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("Keyword")}
							</ModalHeader>
							<ModalBody>
								{this.props.t("delete text")}
								{/* Are you sure to delete this? */}
								{this.state.error ? (
									<div className="text-danger">{this.state.errorMsg}</div>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteKeyword}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteKeywordModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Add Word/Meaning modal */}
						<Modal isOpen={this.state.addWordModal} centered={true}>
							<ModalHeader>{this.props.t("Add Word/Meaning")}</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="Word">{this.props.t("Word")}</Label>
										<Input
											id="Word"
											type="text"
											value={this.state.word}
											onChange={(e) => this.setState({ word: e.target.value })}
										/>
										{this.state.errorWord ? (
											<span className="text-danger">
												{this.state.errorWord}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="meaning">{this.props.t("Meaning")}</Label>
										<Input
											id="meaning"
											type="text"
											value={this.state.meaning}
											onChange={(e) =>
												this.setState({ meaning: e.target.value })
											}
										/>
										{this.state.errorMeaning ? (
											<span className="text-danger">
												{this.state.errorMeaning}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.addNewWord}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Add Word/Meaning")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ addWordModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* update Word/Meaning modal */}
						<Modal isOpen={this.state.updateWordModal} centered={true}>
							<ModalHeader>
								{this.props.t("Update")} {this.props.t("Word/Meaning")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="Word">{this.props.t("Word")}</Label>
										<Input
											id="Word"
											type="text"
											value={this.state.word}
											onChange={(e) => this.setState({ word: e.target.value })}
										/>
										{this.state.errorWord ? (
											<span className="text-danger">
												{this.state.errorWord}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="meaning">{this.props.t("Meaning")}</Label>
										<Input
											id="meaning"
											type="text"
											value={this.state.meaning}
											onChange={(e) =>
												this.setState({ meaning: e.target.value })
											}
										/>
										{this.state.errorMeaning ? (
											<span className="text-danger">
												{this.state.errorMeaning}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updateWord}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updateWordModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Delete Word/Meaning modal */}
						<Modal isOpen={this.state.deleteWordModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("Word/Meaning")}
							</ModalHeader>
							<ModalBody>{this.props.t("delete text")}</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteWord}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteWordModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Add audio modal */}
						<Modal isOpen={this.state.addAudioModal} centered={true} scrollable>
							<ModalHeader>{this.props.t("Add Audio")}</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="audio">{this.props.t("Audio")}</Label>
										<Input
											id="audio"
											type="file"
											onChange={(e) =>
												this.setState({ audio: e.target.files[0] })
											}
											accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"
										/>
										{this.state.audioError ? (
											<span className="text-danger">
												{this.state.audioError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="url">{this.props.t("URL")}</Label>
										<Input
											id="url"
											type="url"
											value={this.state.url}
											onChange={(e) => this.setState({ url: e.target.value })}
										/>
										{/* {this.state.urlError ? <span className='text-danger'>{this.state.urlError}</span>:''} */}
									</FormGroup>
									<FormGroup>
										<Label for="tag">{this.props.t("Tag")}</Label>
										<Input
											id="tag"
											type="text"
											value={this.state.audioTag}
											onChange={(e) =>
												this.setState({ audioTag: e.target.value })
											}
										/>
										{this.state.audioTagError ? (
											<span className="text-danger">
												{this.state.audioTagError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="title">{this.props.t("Title")}</Label>
										<Input
											id="title"
											type="text"
											value={this.state.title}
											onChange={(e) => this.setState({ title: e.target.value })}
										/>
										{this.state.titleError ? (
											<span className="text-danger">
												The Audio Title field is required
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="category">{this.props.t("Category")}</Label>
										<Select
											id="category"
											isMulti={true}
											onChange={this.handleMulti}
											options={this.state.allCategories.map((e) => ({
												label: e.name,
												value: e.id,
											}))}
											classNamePrefix="select2-selection"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="stanza">{this.props.t("Stanza")}</Label>
										<select
											id="stanza"
											onChange={(e) =>
												this.setState({ stanza_id: e.target.value })
											}
											className="form-control"
										>
											<option selected disabled>
												{this.props.t("select a stanza")}
											</option>
											{this.state.allStanzas ? (
												this.state.allStanzas.length > 0 ? (
													this.state.allStanzas.map((n) => (
														<option value={n.id}>{n.full_stanza}</option>
													))
												) : (
													<option>{this.props.t("No Stanza Found")}</option>
												)
											) : (
												""
											)}
										</select>
										{this.state.stanzaError ? (
											<span className="text-danger">
												{this.state.stanzaError}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.addAudio}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Add Audio")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ addAudioModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* update modal */}
						<Modal
							isOpen={this.state.updateAudioModal}
							centered={true}
							scrollable
						>
							<ModalHeader>
								{this.props.t("Update")} {this.props.t("Audio")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="audio">{this.props.t("Audio")}</Label>
										<Input
											id="audio"
											type="file"
											onChange={(e) =>
												this.setState({ newAudio: e.target.files[0] })
											}
											accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"
										/>
										<audio src={this.state.audio} controls />
										{this.state.audioError ? (
											<span className="text-danger">
												{this.state.audioError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="url">{this.props.t("URL")}</Label>
										<Input
											id="url"
											type="url"
											value={this.state.url}
											onChange={(e) => this.setState({ url: e.target.value })}
										/>
										{/* {this.state.urlError ? <span className='text-danger'>{this.state.urlError}</span>:''} */}
									</FormGroup>
									<FormGroup>
										<Label for="tag">{this.props.t("Tag")}</Label>
										<Input
											id="tag"
											type="text"
											value={this.state.audioTag}
											onChange={(e) =>
												this.setState({ audioTag: e.target.value })
											}
										/>
										{this.state.audioTagError ? (
											<span className="text-danger">
												{this.state.audioTagError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="title">{this.props.t("Title")}</Label>
										<Input
											id="title"
											type="text"
											value={this.state.title}
											onChange={(e) => this.setState({ title: e.target.value })}
										/>
										{this.state.titleError ? (
											<span className="text-danger">
												The Audio Title field is required
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="category">{this.props.t("Category")}</Label>
										<Select
											id="category"
											isMulti={true}
											onChange={this.handleMulti}
											options={this.state.allCategories.map((e) => ({
												label: e.name,
												value: e.id,
											}))}
											classNamePrefix="select2-selection"
										/>
									</FormGroup>
									<FormGroup>
										<Label for="stanza">{this.props.t("Stanza")}</Label>
										<select
											id="stanza"
											onChange={(e) =>
												this.setState({ stanza_id: e.target.value })
											}
											className="form-control"
										>
											<option selected disabled>
												{this.props.t("select a stanza")}
											</option>
											{this.state.allStanzas ? (
												this.state.allStanzas.length > 0 ? (
													this.state.allStanzas.map((n) => (
														<option
															value={n.id}
															selected={
																this.state.stanza_id === n.id ? true : false
															}
														>
															{n.full_stanza}
														</option>
													))
												) : (
													<option>{this.props.t("No Stanza Found")}</option>
												)
											) : (
												""
											)}
										</select>
										{this.state.stanzaError ? (
											<span className="text-danger">
												{this.state.stanzaError}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updateAudio}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updateAudioModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Delete modal */}
						<Modal isOpen={this.state.deleteAudioModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("Audio")}
							</ModalHeader>
							<ModalBody>
								{this.props.t("delete text")}
								{this.state.error ? (
									<div className="text-danger">{this.state.errorMsg}</div>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteAudio}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteAudioModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Add video modal */}
						<Modal
							isOpen={this.state.addVideoModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>{this.props.t("Add Video")}</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="video">{this.props.t("Video")}</Label>
										<Input
											id="video"
											type="file"
											onChange={(e) =>
												this.setState({ video: e.target.files[0] })
											}
											accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"
										/>
										{this.state.videoError ? (
											<span className="text-danger">
												{this.state.videoError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="url">{this.props.t("URL")}</Label>
										<Input
											id="url"
											type="url"
											value={this.state.v_url}
											onChange={(e) => this.setState({ v_url: e.target.value })}
										/>
										{/* {this.state.v_urlError ? <span className='text-danger'>{this.state.v_urlError}</span>:''} */}
									</FormGroup>
									<FormGroup>
										<Label for="tag">{this.props.t("Tag")}</Label>
										<Input
											id="tag"
											type="text"
											value={this.state.videoTag}
											onChange={(e) =>
												this.setState({ videoTag: e.target.value })
											}
										/>
										{this.state.videoTagError ? (
											<span className="text-danger">
												{this.state.videoTagError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="stanza">{this.props.t("Stanza")}</Label>
										<select
											id="stanza"
											onChange={(e) =>
												this.setState({ stanza_id: e.target.value })
											}
											className="form-control"
										>
											<option selected disabled>
												{this.props.t("select a stanza")}
											</option>
											{this.state.allStanzas ? (
												this.state.allStanzas.length > 0 ? (
													this.state.allStanzas.map((n) => (
														<option value={n.id}>{n.full_stanza}</option>
													))
												) : (
													<option>{this.props.t("No Stanza Found")}</option>
												)
											) : (
												""
											)}
										</select>
										{this.state.stanzaError ? (
											<span className="text-danger">
												{this.state.stanzaError}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.addVideo}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Add Video")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ addVideoModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* update video modal */}
						<Modal
							isOpen={this.state.updateVideoModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>
								{this.props.t("Update")} {this.props.t("Video")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="video">{this.props.t("Video")}</Label>
										<Input
											id="video"
											type="file"
											onChange={(e) =>
												this.setState({ newVideo: e.target.files[0] })
											}
											accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"
										/>
										<center>
											<video
												width="220"
												height="140"
												src={
													this.state.newVideo
														? this.state.newVideo
														: this.state.video
												}
												controls
											/>
										</center>
										{this.state.videoError ? (
											<span className="text-danger">
												{this.state.videoError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="url">{this.props.t("URL")}</Label>
										<Input
											id="url"
											type="url"
											value={this.state.v_url}
											onChange={(e) => this.setState({ v_url: e.target.value })}
										/>
										{/* {this.state.v_urlError ? <span className='text-danger'>{this.state.v_urlError}</span>:''} */}
									</FormGroup>
									<FormGroup>
										<Label for="tag">{this.props.t("Tag")}</Label>
										<Input
											id="tag"
											type="text"
											value={this.state.videoTag}
											onChange={(e) =>
												this.setState({ videoTag: e.target.value })
											}
										/>
										{this.state.videoTagError ? (
											<span className="text-danger">
												{this.state.videoTagError}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="stanza">{this.props.t("Stanza")}</Label>
										<select
											id="stanza"
											onChange={(e) =>
												this.setState({ stanza_id: e.target.value })
											}
											className="form-control"
										>
											<option selected disabled>
												{this.props.t("select a stanza")}
											</option>
											{this.state.allStanzas ? (
												this.state.allStanzas.length > 0 ? (
													this.state.allStanzas.map((n) => (
														<option
															value={n.id}
															selected={
																this.state.stanza_id === n.id ? true : false
															}
														>
															{n.full_stanza}
														</option>
													))
												) : (
													<option>{this.props.t("No Stanza Found")}</option>
												)
											) : (
												""
											)}
										</select>
										{this.state.stanzaError ? (
											<span className="text-danger">
												{this.state.stanzaError}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updateVideo}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updateVideoModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Delete video modal */}
						<Modal isOpen={this.state.deleteVideoModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("Video")}
							</ModalHeader>
							<ModalBody>
								{this.props.t("delete text")}
								{this.state.error ? (
									<div className="text-danger">{this.state.errorMsg}</div>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteVideo}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteVideoModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* success alert */}
						{this.state.successAlert ? (
							<Alert
								color="success"
								style={{
									position: "absolute",
									top: "50px",
									right: "25px",
									zIndex: "10000",
								}}
							>
								{this.state.successMsg}
							</Alert>
						) : (
							""
						)}
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(PoemDetail);
