import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	Container,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Spinner,
	Alert,
	Form,
	Label,
	Input,
	FormGroup,
	Progress,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { api_url } from "../../components/Common/Variables";
function ImportFile(props) {
	const [breadcrumbItems, setBreadcrumbItems] = useState([
		{ title: props.t("Dashboard"), link: "#" },
		{ title: props.t("امپورٹ فائل"), link: "#" },
	]);
	const [file, setFile] = useState("");
	const [token, setToken] = useState("");
	const [uploadProgress, setUploadProgress] = useState(0);
	const [msg, setMsg] = useState("");

	useEffect(() => {
		const token = JSON.parse(localStorage.getItem("token"));
		setToken(token);
		if (token) {
		} else {
			console.log("no token");
			props.history.push("/login");
		}
		console.log("File", file);
	}, [token]);

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		setFile(selectedFile);
		console.log("File", file);
	};

	const addFile = () => {
		if (!file) {
			console.error("No file selected.");
			setMsg("Please select file");
			return;
		}
		console.log("Selected file:", file);
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("import_file", file);
		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "import_books_poems_data", requestOptions)
			.then((response) => {
				response.json();
				setTimeout(() => {
					clearInterval(interval);
					setUploadProgress(0);
					setMsg("File has been uploaded");
				}, 1000);
			})
			.then((result) => {
				console.log(result);
			})
			.catch((error) => {
				console.log("error", error);
				setMsg("Error in uploading file");
			});

		const interval = setInterval(() => {
			setUploadProgress((prevProgress) =>
				prevProgress < 100 ? prevProgress + 10 : prevProgress
			);
		}, 100);
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs
						title={props.t("Banner Management")}
						breadcrumbItems={breadcrumbItems}
					/>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Form>
										<Label for="import">فائل سلیکٹ کریں</Label>
										<Input
											id="import"
											type="file"
											accept=".xls, .xlsx"
											onChange={handleFileChange}
										/>
										<Button
											color="success"
											className="my-3 float-left"
											onClick={addFile}
										>
											{uploadProgress > 0
												? `  اپلوڈنگ %${uploadProgress}`
												: "فائل اپلوڈ کریں"}
											{uploadProgress > 0 && (
												<Progress
													value={uploadProgress}
													style={{ width: "200px" }}
												/>
											)}
										</Button>
									</Form>
								</CardBody>
							</Card>{" "}
							<p
								style={{
									width: "100%",
									textAlign: "right",
									fontSize: "14px",
								}}
							>
								{msg}
							</p>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
}

export default withNamespaces()(ImportFile);
