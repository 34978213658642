
import React, { Component } from "react";
import { Row, Col, Alert, Button ,Container, FormGroup, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { forgetUser } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
// import images
import logodark from "../../assets/images/logo-dark.png";
import { api_url } from "../../components/Common/Variables";
import logo from '../../assets/images/logo new-01.png'

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        token:'',
        old:'',
        new:'',
        confirm:'',
        success:false,
        successMsg:'',
        error:false,
        errorMsg:'',
        spinner:false
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
    
    });
  }
  // handleValidSubmit
  handleValidSubmit(event, values) {
    // this.props.forgetUser(values, this.props.history);
    this.setState({spinner:true,error:false,errorMsg:''})
    console.log(this.state.old,this.state.new,this.state.confirm)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
var formdata = new FormData();
formdata.append("oldpassword", this.state.old);
formdata.append("newpassword", this.state.new);
formdata.append("confirmpassword", this.state.confirm);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"changepassword", requestOptions)
  .then(response => response.json())
  .then(result =>{
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: result.message,spinner:false });
      } else {
        this.setState({
          spinner:false ,
          success: true,
          successMsg: result.message
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ success: false,successMsg:'' });
          this.props.history.push('/dashboard')
        }, 2000);
      }
    })
  .catch(error => console.log('error', error));
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
            <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
        </div>
        <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                            <div>
                                                    <Link to="/"><img className="login-logo" src={logo} alt="logo"/></Link>
                                                </div>
    
                                                <h1 className="font-size-18 mt-4 text-center">{this.props.t('Reset Password')}</h1>
                                                {/* <p className="text-muted">Reset your password to Nazox.</p> */}
                                            </div>

                                            <div className="p-2 mt-5">
                                            {this.state.error?
                                                <Alert color="danger" className="mb-4">{this.state.errorMsg}</Alert> : null }
                                            {
                                            this.state.success ? 
                                                <Alert color="success" className="mb-4">{this.state.successMsg}</Alert> : null
                                            }
                                                <AvForm className="form-horizontal" onValidSubmit={this.handleValidSubmit}>
                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="old">{this.props.t('Old Password')}</Label>
                                                        <AvField name="old"  value={this.state.old} type="text" onChange={(e)=>this.setState({old:e.target.value})} validate={{required: true}} className="form-control" placeholder={this.props.t('Enter Old Password')}/>
                                                    </FormGroup>
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="new">{this.props.t('New Password')}</Label>
                                                        <AvField name="new"  value={this.state.new} type="text" onChange={(e)=>this.setState({new:e.target.value})} validate={{ required: true}} className="form-control" placeholder={this.props.t('Enter New Password')}/>
                                                    </FormGroup>
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-password-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="confirm">{this.props.t('Confirm Password')}</Label>
                                                        <AvField name="confirm"  value={this.state.confirm} type="text" onChange={(e)=>this.setState({confirm:e.target.value})} validate={{required: true}} className="form-control" placeholder={this.props.t('Enter Confirm Password')}/>
                                                    </FormGroup>

                                                    <div className="mt-4 text-center">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">{this.state.spinner ? this.props.t('Loading...') : this.props.t('Reset')}</Button>
                                                    </div>
                                                </AvForm>
                                            </div>

                                            {/* <div className="mt-5 text-center">
                                                <p>Don't have an account ? <Link to="/login" className="font-weight-medium text-primary"> Log in </Link> </p>
                                                <p>© 2020 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div> */}
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
      </React.Fragment>
    );
  }
}

// const mapStatetoProps = state => {
//   const { message, forgetError, loading } = state.Forget;
//   return { message, forgetError, loading };
// }

// export default withRouter(
//   connect(mapStatetoProps, { forgetUser })(ForgetPasswordPage)
// );
export default withNamespaces()(ForgetPasswordPage)