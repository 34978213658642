import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Button, Spinner, Alert } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { api_url } from '../../components/Common/Variables';
import Select from "react-select";
import classnames from "classnames";
class StanzaDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t('Dashboard'), link: "#" },
        { title: this.props.t('Stanza Management'), link: "#" },
      ],
      token: '',
      isloading: false,
      stanza_id: this.props.location.state,
      allKeywords: [],
      keyword_id: '',
      updatePoemModal: false,
      updateStanzaModal: false,
      addStanzaModal: false,
      deleteStanzaModal: false,
      keyupdateStanzaModal: false,
      deleteKeywordModal: false,
      addWordModal: false,
      updateWordModal: false,
      deleteWordModal: false,
      allPoems: [],
      allBooks: [],
      getAllPoemsError: false,
      poem_id: '',
      successAlert: false,
      successMsg: '',
      error: false,
      errorMsg: '',
      poem_name: '',
      poem_desc: '',
      poem_full: '',
      poem_translation: '',
      poem_lang:'',
      poem_tagline:'',
      book_id:'',
      stanza_keywords:[],
      stanza_words:[],
      stanza_audios:[],
      stanza_videos:[],
      stanza_desc: '',
      stanza_full: '',
      stanza_translation: '',
      stanza_lang: 'ur',
      word_id:'',
      word:'',
      meaning:'',
      errorDesc: '',
      errorFull: '',
      errorTranslation: '',
      errorLang: '',
      poemError: '',
      keyName: '',
      keyNameError: '',
      errorWord:'',
      errorMeaning:'',
      customActiveTab: "1",
      audio_id:'',
      audioTag:'',
      title:'',
      titleError:'',
      audio:'',
      newAudio:'',
      audioError:'',
      audioTagError:'',
      url:'',
      urlError:'',
      addAudioModal:false,
      updateAudioModal:false,
      deleteAudioModal:false,
      addVideoModal:false,
      updateVideoModal:false,
      deleteVideoModal:false,
      video:'',
      newVideo:'',
      videoError:'',
      v_url:'',
      v_urlError:'',
      videoTag:'',
      videoTagError:'',
      allSuggestions:[],
      key_id:'',
      allCategories:[],
      category_id:[],
    };
    this.toggleCustom = this.toggleCustom.bind(this);
  }
  toggleCustom(tab) {
    if (this.state.customActiveTab !== tab) {
      this.setState({
        customActiveTab: tab
      });
    }
  }
  componentDidMount = async () => {
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({ token: token }, () => {
      if (this.state.token) {
        this.getAllPoems();
        // this.getPoemDetail()
        this.getStanzaDetail()
        this.getAllBooks()
        this.getAllCategories()
      } else {
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getAllBooks = async () => {
    try {
      let allBooks = await fetch(api_url + "get_all_books", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allBooks.ok) {
        this.setState({ isloading: false,getAllBooksError:true });
        throw Error('Error');
      } else {
        allBooks = await allBooks.json();
        this.setState({ allBooks: allBooks.data, isloading: false });
        console.warn(this.state.allBooks);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  getPoemDetail = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);

    var formdata = new FormData();
    formdata.append("poem_id", this.state.poem_id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "detail_poem", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            poem_name: result.data.poem_name, poem_desc: result.data.desc, poem_full: result.data.full_poem,book_id:result.data.book_id,
            poem_lang:result.data.language,poem_tagline:result.data.poem_tagline, isloading:false,
            poem_translation: result.data.translation,poem_stanzas:result.data.poem_stanza,stanza_keywords:result.data.stanza_keywords,
            stanza_words:result.data.words_meanings,stanza_audios:result.data.stanza_audios, stanza_videos:result.data.stanza_videos

          });
        }
      })
      .catch(error => console.log('error', error));
  }
  getAllCategories = async()=>{
    this.setState({isloading:true})
    try {
      let allCate = await fetch(api_url + "get_list_category", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allCate.ok) {
        this.setState({getAllCateError:true });
        throw Error('Error');
      } else {
        allCate = await allCate.json();
        this.setState({ allCategories: allCate.data });
        console.warn('all categories',this.state.allCategories);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  getAllPoems = async () => {
    this.setState({ isloading: true })
    try {
      let allPoems = await fetch(api_url + "get_all_poems?is_paginated=false", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allPoems.ok) {
        this.setState({ isloading: false, getAllPoemsError: true });
        throw Error('Error');
      } else {
        allPoems = await allPoems.json();
        this.setState({ allPoems: allPoems.data, isloading: false });
        console.warn(this.state.allPoems);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  updatePoem = async()=>{
    this.setState({spinner:true})
    if(this.state.poem_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.poem_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.poem_full){
      this.setState({errorFull:''})
    }else{
      this.setState({errorFull:'The full poem field is required'})
    }
    if(this.state.poem_translation){
      this.setState({errorTranslation:''})
    }else{
      this.setState({errorTranslation:'The translation field is required'})
    }
    if(this.state.poem_lang){
      this.setState({errorLang:''})
    }else{
      this.setState({errorLang:'The Poem Language field is required'}) 
    }
    if(this.state.errorName || this.state.errorDesc || this.state.errorFull || this.state.errorTranslation || this.state.errorLang){
    // if(this.state.errorDesc || this.state.errorFull || this.state.errorTranslation){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("poem_name", this.state.poem_name);
formdata.append("poem_tagline", this.state.poem_tagline);
formdata.append("full_poem", this.state.poem_full);
formdata.append("desc", this.state.poem_desc);
formdata.append("translation", this.state.poem_translation);
formdata.append("language", this.state.poem_lang);
formdata.append("book_id", this.state.book_id);
formdata.append("poem_id", this.state.poem_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"update_poem", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
        } else {
          this.setState({
            updatePoemModal: false,
            successAlert: true,
            successMsg: this.props.t('Poem') + this.props.t('Updated Successfully'),
            spinner:false,
            isloading:true,
            poem_name:'',poem_tagline:'',poem_desc:'',poem_lang:'',poem_full:'',book_id:''
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false,successMsg:'' });
          }, 2000);
          this.getPoemDetail();
          this.toggleCustom("1")
        }
      })
      .catch(error => console.log('error', error));
    }
  }
//  Stanza Actions
  addStanza = async () => {
    this.setState({ spinner: true })
    console.log('desc', this.state.stanza_desc, 'full', this.state.stanza_full, 'trans', this.state.stanza_translation, 'lang', this.state.stanza_lang)
    if (this.state.stanza_desc) {
      this.setState({ errorDesc: '' })
    } else {
      this.setState({ errordesc: 'The Description field is required' })
    }
    if (this.state.stanza_full) {
      this.setState({ errorFull: '' })
    } else {
      this.setState({ errorFull: 'The Full Stanza field is required' })
    }
    if (this.state.stanza_translation) {
      this.setState({ errorTranslation: '' })
    } else {
      this.setState({ errorTranslation: 'The Translation field is required' })
    }
    if (this.state.stanza_lang) {
      this.setState({ errorLang: '' })
    } else {
      this.setState({ errorLang: 'The Stanza Language field is required' })
    }
    if (this.state.errorDesc || this.state.errorFull || this.state.errorTranslation || this.state.errorLang) {
      console.log('all field are required')
      this.setState({ spinner: false })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);

      var formdata = new FormData();
      formdata.append("full_stanza", this.state.stanza_full);
      formdata.append("desc", this.state.stanza_desc);
      formdata.append("translation", this.state.stanza_translation);
      formdata.append("language", this.state.stanza_lang);
      formdata.append("poem_id", this.state.poem_id);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(api_url + "insert_stanza", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
          } else {
            this.setState({
              addStanzaModal: false,
              successAlert: true,
              successMsg: this.props.t('Stanza') + this.props.t('Added Successfully'),
              spinner: false,
              stanza_desc: '', stanza_full: '', stanza_translation: '', stanza_lang: ''
            });
            setTimeout(() => {//hide success msg after 2 seconds
              this.setState({ successAlert: false, successMsg: '' });
            }, 2000);
            this.getPoemDetail();
            this.toggleCustom("4")
          }
        })
        .catch(error => console.log('error', error));
    }
  }
  getStanzaDetail = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);

    var formdata = new FormData();
    formdata.append("stanza_id", this.state.stanza_id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "detail_stanza", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            stanza_desc: result.data.stanza.desc, stanza_full: result.data.stanza.full_stanza, stanza_translation: result.data.stanza.translation, stanza_lang: result.data.stanza.language, poem_id: result.data.stanza.poem_id,
            stanza_keywords:result.data.keywordsstanza,stanza_words:result.data.stanza.words_meanings,stanza_audios:result.data.stanza.stanza_audios,
            stanza_videos:result.data.stanza.stanza_videos,isloading:false
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateStanza = async () => {
    this.setState({ spinner: true })
    if (this.state.stanza_desc) {
      this.setState({ errorDesc: '' })
    } else {
      this.setState({ errordesc: 'The Description field is required' })
    }
    if (this.state.stanza_full) {
      this.setState({ errorFull: '' })
    } else {
      this.setState({ errorFull: 'The Full Stanza field is required' })
    }
    if (this.state.stanza_translation) {
      this.setState({ errorTranslation: '' })
    } else {
      this.setState({ errorTranslation: 'The Translation field is required' })
    }
    if (this.state.stanza_lang) {
      this.setState({ errorLang: '' })
    } else {
      this.setState({ errorLang: 'The Stanza Language field is required' })
    }
    if (this.state.errorDesc || this.state.errorFull || this.state.errorTranslation || this.state.errorLang ) {
      console.log('all field are required')
      this.setState({ spinner: false })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);

      var formdata = new FormData();
      formdata.append("full_stanza", this.state.stanza_full);
      formdata.append("desc", this.state.stanza_desc);
      formdata.append("translation", this.state.stanza_translation);
      formdata.append("language", this.state.stanza_lang);
      formdata.append("poem_id", this.state.poem_id);
      formdata.append("stanza_id", this.state.stanza_id);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(api_url + "update_stanza", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
          } else {
            this.setState({
              updateStanzaModal: false,
              successAlert: true,
              successMsg: this.props.t('Stanza') + this.props.t('Updated Successfully'),
              spinner: false,
              isloading:true,
              stanza_desc: '', stanza_full: '', stanza_translation: '', stanza_lang: ''
            });
            setTimeout(() => {//hide success msg after 2 seconds
              this.setState({ successAlert: false, successMsg: '' });
            }, 2000);
            this.getStanzaDetail()
          }
        })
        .catch(error => console.log('error', error));
    }
  }
  deleteStanza = async () => {
    this.setState({ spinner: true })
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);

    var formdata = new FormData();
    formdata.append("stanza_id", this.state.stanza_id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "delete_stanza", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
        } else {
          this.setState({
            spinner: false,
            deleteStanzaModal: false,
            successAlert: true,
            successMsg: this.props.t('Stanza') + this.props.t('Deleted Successfully')
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false, successMsg: '' });
          }, 2000);
          this.getPoemDetail();
          this.toggleCustom("4");

        }
      }
      )
      .catch(error => {
        console.log('error', error)
        this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
      });
  }
  // Stanza actions end
  // keyword actions
  getSuggestions = async()=>{
    var formdata = new FormData();
formdata.append("word", this.state.keyName);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"show-suggestions", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({allSuggestions:result.data})
  })
  .catch(error => console.log('error', error));
  }
  deleteKeyword = async() =>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("keywords_stanza_id", this.state.keyword_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deleteKeywordsstanza", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false ,
        deleteKeywordModal: false,
        successAlert: true,
        successMsg: this.props.t('Keyword') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getStanzaDetail()
      this.toggleCustom('5')
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  addKeyword = async()=>{
    this.setState({spinner:true,keyNameError:'',keyStanzaError:''})
    if(this.state.keyName){
        this.setState({keyNameError:''})
    }else{
        this.setState({keyNameError:'The Name field is required',spinner:false})
    }
    if(this.state.keyNameError){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  if(this.state.key_id){
    formdata.append("keywords_id", this.state.key_id);
  }else{
    formdata.append("keywords_name", this.state.keyName);
  }
  formdata.append("stanza_id", this.state.stanza_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertKeywordsstanza", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: result.message,spinner:false });
      } else {
        this.setState({
          successAlert: true,
          successMsg: this.props.t('Keyword') + this.props.t('Added Successfully'),
          spinner:false,
          keyName:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail()
      this.toggleCustom('5')
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  // keyword actions end
  // words/meanings actions
  addNewWord = async()=>{
    this.setState({spinner:true})
    if(this.state.word){
      this.setState({errorWord:''})
    }else{
      this.setState({errorWord:'The Word field is required'})
    }
    if(this.state.meaning){
      this.setState({errorMeaning:''})
    }else{
      this.setState({errorMeaning:'The Meaning field is required'})
    }
    if(this.state.errorWord || this.state.errorMeaning){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("word", this.state.word);
formdata.append("meaning", this.state.meaning);
formdata.append("type", "Stanza");
formdata.append("id", this.state.stanza_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insert_word_meaning", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addWordModal: false,
          successAlert: true,
          successMsg: this.props.t('Word/Meaning') + this.props.t('Added Successfully'),
          spinner:false,
          word:'',meaning:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail()
        this.toggleCustom("6")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteWord = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("wm_id", this.state.word_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"delete_word_meaning", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: result.message,spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteWordModal: false,
        successAlert: true,
        successMsg: this.props.t('Word/Meaning') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getStanzaDetail()
      this.toggleCustom("6")
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  getWordDetail = async ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("wm_id", this.state.word_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_word_meaning", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            word:result.data.word,meaning:result.data.meaning
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateWord = async () =>{
    this.setState({spinner:true})
    if(this.state.word){
      this.setState({errorWord:''})
    }else{
      this.setState({errorWord:'The Word field is required'})
    }
    if(this.state.meaning){
      this.setState({errorMeaning:''})
    }else{
      this.setState({errorMeaning:'The Meaning field is required'})
    }
    if(this.state.errorWord || this.state.errorMeaning){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("word", this.state.word);
formdata.append("meaning", this.state.meaning);
formdata.append("wm_id", this.state.word_id);
formdata.append("type", "Stanza");
formdata.append("id", this.state.stanza_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"update_word_meaning", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateWordModal: false,
          successAlert: true,
          successMsg: this.props.t('Word/Meaning') + this.props.t('Updated Successfully'),
          spinner:false,
          word:'',meaning:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail()
        this.toggleCustom("6")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  // words/meanings actions end
  // audio actions 
  addAudio = async()=>{
    console.log(this.state.audio,this.state.url,this.state.poem_id,this.state.stanza_id)
    this.setState({spinner:true})
    if(this.state.audio || this.state.url){
      this.setState({audioError:''})
    }else{
      this.setState({audioError:'Either audio or URL is required',spinner:false,})
    }
    if(this.state.title){
      this.setState({titleError:''})
    }else{
      this.setState({titleError:'The Audio Title field is required',spinner:false,})
    }
    if(this.state.audioTag){
      this.setState({audioTagError:''})
    }else{
      this.setState({audioTagError:'The Audio Tag field is required',spinner:false,})
    }
    if(this.state.poem_id || this.state.stanza_id){
      this.setState({poemError:''})
    }else{
      this.setState({poemError:'Either Poem ID or Stanza ID is required',spinner:false,})
    }
    if(this.state.audioError || this.state.titleError || this.state.poemError || this.state.audioTagError ){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("name", this.state.audio);
  formdata.append("youtubeurl", this.state.url);
  formdata.append("stanza_id", this.state.stanza_id);
  formdata.append("poem_id", this.state.poem_id);
  formdata.append("audio_title", this.state.title);
  formdata.append("audio_tag", this.state.audioTag);
  {this.state.category_id.map((n)=>{
    formdata.append("category_id[]", n);
  })}
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertaudio", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        if(result.message === "The youtubeurl format is invalid."){
          this.setState({urlError: 'The youtubeurl format is invalid.',spinner:false})
        }else{
          this.setState({ error: true, errorMsg: result.message,spinner:false });
        }
      } else {
        this.setState({
          addAudioModal: false,
          successAlert: true,
          successMsg: this.props.t('Audio') + this.props.t('Added Successfully'),
          spinner:false,
          audio:'',url:'',audioTag:'',poem_id:'',title:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail()
        this.toggleCustom("7")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  getAudioDetail = async()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("audio_id", this.state.audio_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_audio", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            audio:result.data.audio[0].name,url:result.data.audio[0].youtubeurl,poem_id:result.data.audio[0].poem_id,
            audioTag:result.data.audio[0].audio_tag,title:result.data.audio[0].audio_title
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateAudio = async()=>{
    console.log(this.state.newAudio,this.state.url,this.state.poem_id,this.state.stanza_id)
    this.setState({spinner:true})
    if(this.state.url || this.state.newAudio){
      this.setState({urlError:''})
    }else{
      this.setState({urlError:'Either audio or URL field is required',spinner:false,})
    }
    if(this.state.poem_id){
      this.setState({poemError:''})
    }else{
      this.setState({poemError:'The Poem is required',spinner:false,})
    }  
    if(this.state.audioTag){
      this.setState({audioTagError:''})
    }else{
      this.setState({audioTagError:'The Tag field is required',spinner:false,})
    }
    if(this.state.title){
        this.setState({titleError:''})
      }else{
        this.setState({titleError:'The Audio title is required',spinner:false,})
      }
    if( this.state.urlError || this.state.poemError || this.state.titleError || this.state.audioTagError){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("name", this.state.newAudio);
  formdata.append("youtubeurl", this.state.url);
  formdata.append("stanza_id", this.state.stanza_id);
  formdata.append("poem_id", this.state.poem_id);
  formdata.append("audio_id", this.state.audio_id);
  formdata.append("audio_tag", this.state.audioTag);
  formdata.append("audio_title", this.state.title);
  {this.state.category_id.map((n)=>{
    formdata.append("category_id[]", n);
  })}
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"updateaudio", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        if(result.message === "The youtubeurl format is invalid."){
          this.setState({urlError: 'The youtubeurl format is invalid.',spinner:false})
        }else{
          this.setState({ error: true, errorMsg: result.message,spinner:false });
        }
      } else {
        this.setState({
          updateAudioModal: false,
          successAlert: true,
          successMsg: this.props.t('Audio') + this.props.t('Updated Successfully'),
          spinner:false,
          audio:'',url:'',poem_id:'',title:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail();
        this.toggleCustom("7")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteAudio = async () => {
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("audio_id", this.state.audio_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deleteaudio", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteAudioModal: false,
        successAlert: true,
        successMsg: this.props.t('Audio') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getStanzaDetail();
        this.toggleCustom("7")
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  // audio actions end
  // video actions
  deleteVideo = async () => {
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("video_id", this.state.video_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deletevideo", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteVideoModal: false,
        successAlert: true,
        successMsg: this.props.t('Video') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000); 
      this.getStanzaDetail();
      this.toggleCustom("8")
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  addVideo = async()=>{
    this.setState({spinner:true})
    if(this.state.video){
      this.setState({videoError:''})
    }else{
      this.setState({videoError:'The video is required',spinner:false,})
    }
    if(this.state.v_url){
      this.setState({v_urlError:''})
    }else{
      this.setState({v_urlError:'The URL field is required',spinner:false,})
    }
    if(this.state.videoTag){
      this.setState({videoTagError:''})
    }else{
      this.setState({videoTagError:'The Video Tag field is required',spinner:false,})
    }
    if(this.state.poem_id){
      this.setState({poemError:''})
    }else{
      this.setState({poemError:'The Poem is required',spinner:false,})
    }
    if(this.state.videoError || this.state.v_urlError || this.state.poemError || this.state.videoTagError){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("name", this.state.video);
  formdata.append("youtubeurl", this.state.v_url);
  formdata.append("stanza_id", this.state.stanza_id);
  formdata.append("poem_id", this.state.poem_id);
  formdata.append("video_tag", this.state.videoTag);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertvideo", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        if(result.message === "The youtubeurl format is invalid."){
          this.setState({urlError: 'The youtubeurl format is invalid.',spinner:false})
        }else{
          this.setState({ error: true, errorMsg: result.message,spinner:false });
        }
      } else {
        this.setState({
          addVideoModal: false,
          successAlert: true,
          successMsg: this.props.t('Video') + this.props.t('Added Successfully'),
          spinner:false,
          video:'',v_url:'',poem_id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail();
        this.toggleCustom("8")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  getVideoDetail = async()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("video_id", this.state.video_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_video", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            video:result.data[0].name,v_url:result.data[0].youtubeurl,poem_id:result.data[0].poem_id,
            videoTag:result.data[0].video_tag
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateVideo = async()=>{
    console.log(this.state.newVideo,this.state.url,this.state.poem_id,this.state.poem_id)
    this.setState({spinner:true})
    if(this.state.url){
      this.setState({urlError:''})
    }else{
      this.setState({urlError:'The URL field is required',spinner:false,})
    }
    if(this.state.videoTag){
      this.setState({videoTagError:''})
    }else{
      this.setState({videoTagError:'The Video Tag field is required',spinner:false,})
    }
    if(this.state.poem_id){
      this.setState({poemError:''})
    }else{
      this.setState({poemError:'The Poem is required',spinner:false,})
    }
    if(this.state.urlError || this.state.poemError || this.state.videoTagError){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("name", this.state.newVideo);
  formdata.append("youtubeurl", this.state.v_url);
  formdata.append("stanza_id", this.state.stanza_id);
  formdata.append("poem_id", this.state.poem_id);
  formdata.append("video_id", this.state.video_id);
  formdata.append("video_tag", this.state.videoTag);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"updatevideo", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        if(result.message === "The youtubeurl format is invalid."){
          this.setState({urlError: 'The youtubeurl format is invalid.',spinner:false})
        }else{
          this.setState({ error: true, errorMsg: result.message,spinner:false });
        }
      } else {
        this.setState({
          updateVideoModal: false,
          successAlert: true,
          successMsg: this.props.t('Video') + this.props.t('Updated Successfully'),
          spinner:false,
          video:'',v_url:'',newVideo:'',poem_id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getStanzaDetail();
        this.toggleCustom("8")
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  // video actions end
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t('Stanza Management')} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                {this.state.isloading ?
                  <center>
                    <div className="lds-ripple"><div></div><div></div></div>
                  </center>
                  :
                  <Card>
                    <CardBody>
                      <div className='d-flex justify-content-between'>
                        <h1>{this.state.stanza_full}</h1>
                        <Button color="secondary" outline title='Edit' className="float-right" onClick={()=>this.setState({updateStanzaModal: true, error: false, errorMsg: ''})}>
                      <i className="mdi mdi-pencil mr-3 font-size-18"></i>{this.props.t('Update Stanza')}
                    </Button>
                      </div>
                      <Row className="mt-3 vertical-tab">
                        <Col md={2}>
                          <Nav pills className="flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <NavLink id="v-pills-full-poem" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "1"}, "mb-2")}
                              onClick={() => {this.toggleCustom("1");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Stanza or')}
                            </NavLink>
                            <NavLink id="v-pills-poem-desc" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "2"}, "mb-2")}
                              onClick={() => {this.toggleCustom("2");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Poem Desc')}
                            </NavLink>
                            <NavLink id="v-pills-poem-trans" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "3"}, "mb-2")}
                              onClick={() => {this.toggleCustom("3");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Translation')}
                            </NavLink>
                            <NavLink id="v-pills-poem-keywords" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "5"})}
                              onClick={() => { this.toggleCustom("5");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Keyword')}
                            </NavLink>
                            <NavLink id="v-pills-poem-words" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "6"})}
                              onClick={() => { this.toggleCustom("6");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Word/Meaning')}
                            </NavLink>
                            <NavLink id="v-pills-poem-audio" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "7"})}
                              onClick={() => { this.toggleCustom("7");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Audio')}
                            </NavLink>
                            <NavLink id="v-pills-poem-video" style={{ cursor: "pointer" }} className={classnames({active: this.state.customActiveTab === "8"})}
                              onClick={() => { this.toggleCustom("8");}} aria-controls="v-pills-home" aria-selected="true">
                              {this.props.t('Video')}
                            </NavLink>
                          </Nav>
                        </Col>
                        <Col md={10}>
                          <TabContent activeTab={this.state.customActiveTab} className="text-muted mt-4 mt-md-0" id="v-pills-tabContent">
                            <TabPane tabId="1" role="tabpanel" aria-labelledby="v-pills-full-poem">
                            <center>
                        <Input type="textarea" className="show-poem-field" rows={15} value={this.state.stanza_full} disabled style={{ border: 'none' }} />
                      </center>

                            </TabPane>
                            <TabPane tabId="2" role="tabpanel" aria-labelledby="v-pills-poem-desc">
                            <div>
                        <Input type="textarea" className="show-poem-field" rows={15} value={this.state.stanza_desc} disabled style={{ border: 'none' }} />
                      </div>
                            </TabPane>
                            <TabPane tabId="3" role="tabpanel" aria-labelledby="v-pills-poem-trans">
                            <div>
                        <Input type="textarea" className="show-poem-field" rows={15} value={this.state.stanza_translation} disabled style={{ border: 'none' }} />
                      </div>
                            </TabPane>
                            <TabPane tabId="5" role="tabpanel" aria-labelledby="v-pills-poem-keywords">
                            <div className='d-flex'>
                        <Input className="w-25 mr-3" type="text" placeholder={this.props.t('Add Keyword')} value={this.state.keyName} onChange={(e) => this.setState({ keyName: e.target.value },this.getSuggestions)} />
                        {this.state.spinner ? <Spinner type="border" color="primary" className=" d-flex justify-content-center align-items-center" size="sm" /> :
                          <i className="mdi mdi-plus-outline font-size-18 cursor-pointer text-primary d-flex justify-content-center align-items-center" title={this.props.t('Add Keyword')} onClick={this.addKeyword}></i>}
                      </div>
                      {this.state.allSuggestions ? 
                      <ul className='keywords-list w-25'>
                        {this.state.allSuggestions.length > 0 ?
                        this.state.allSuggestions.map((n)=>(
                          <li key={n.id} onClick={()=> this.setState({allSuggestions:[], keyName: n.keyword_name, key_id:n.id })}>{n.keyword_name}</li>
                        ))
                        :
                        ''}
                      </ul>
                      :''}
                      {this.state.keyNameError ? <span className='text-danger'>{this.state.keyNameError}</span> : ''}
                      <div className='keywords d-flex'>
                        {this.state.stanza_keywords ?
                          this.state.stanza_keywords.length > 0 ?
                            this.state.stanza_keywords.map((n) => (
                                <div className='keyword' key={n.id}> 
                                  <span className='mr-1'>{n.keywords.keyword_name}</span>
                                  <i className='mdi mdi-close cursor-pointer' title={this.props.t('Delete')} onClick={(event) => this.setState({ keyword_id: event.currentTarget.id, deleteKeywordModal: true })} id={n.id}></i>
                                </div>
                            ))
                            :
                           ''
                          :
                          ''
                        }
                      </div>
                            </TabPane>
                            <TabPane tabId="6" role="tabpanel" aria-labelledby="v-pills-poem-words">
                            
                        <div className='d-flex justify-content-between'>
                        <h3>{this.props.t('Word/Meaning')}</h3>
                        <Button color="success" className="mb-3 float-right" title={this.props.t('Add Word/Meaning')} onClick={() => this.setState({ addWordModal: true, })}>
                          <i className="mdi mdi-plus-outline font-size-18"></i>{this.props.t('Add Word/Meaning')}
                        </Button>
                      </div>
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <Table id="tech-companies-1" striped bordered responsive>
                            <thead>
                              <tr>
                                <th>{this.props.t('Word')}</th>
                                <th>{this.props.t('Meaning')}</th>
                                <th>{this.props.t('Actions')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.stanza_words ?
                                this.state.stanza_words.length > 0 ?
                                  this.state.stanza_words.map((n) => (
                                      <tr key={n.id}>
                                         <th>{n.word}</th>
                              <td>{n.meaning}</td>
                              <td>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ word_id: event.currentTarget.id,updateWordModal:true },this.getWordDetail)} id={n.id}></i>
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ word_id: event.currentTarget.id,deleteWordModal:true })} id={n.id}></i>
                              </td>
                                      </tr>
                                  ))
                                  :
                                  <tr>
                                    <td colSpan={6}>
                                      <center>
                                        <div>{this.props.t('No Word/Meaning Found')}</div>
                                      </center>
                                    </td>
                                  </tr>
                                :
                                ''

                              }
                            </tbody>
                          </Table>
                        </div>
                      </div>
                            </TabPane>
                            <TabPane tabId="7" role="tabpanel" aria-labelledby="v-pills-poem-audio">
                            <div className='d-flex justify-content-between'>
                        <h3>{this.props.t('Audio')}</h3>
                        <Button color="success" className="mb-3 float-right" title={this.props.t('Add Audio')} onClick={() => this.setState({ addAudioModal: true, audio:'',url:'',audioTag:'',poem_id:'' })}>
                          <i className="mdi mdi-plus-outline font-size-18"></i>{this.props.t('Add Audio')}
                        </Button>
                      </div>
                              {this.state.stanza_audios ?
                              this.state.stanza_audios.length > 0 ?
                              this.state.stanza_audios.map((n=>(
                                <Row key={n.id}>
                                  <Col md={2}>
                                    {n.audio_tag}
                                  </Col>
                                  <Col md={6}>
                                  <audio src={n.audio_path} controls />
                                  </Col>
                                  <Col md={2}>
                                  <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ audio_id: event.currentTarget.id,updateAudioModal:true },this.getAudioDetail)} id={n.id}
                                ></i>
                                  </Col>
                                  <Col md={2}>
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ audio_id: event.currentTarget.id,deleteAudioModal:true })} id={n.id}
                                ></i>
                                  </Col>
                                </Row>
                              )))
                              :
                              this.props.t('No Audio Found')
                            :
                            ''
                            }
                              
                            </TabPane>
                            <TabPane tabId="8" role="tabpanel" aria-labelledby="v-pills-poem-video">
                            <div className='d-flex justify-content-between'>
                        <h3>{this.props.t('Video')}</h3>
                        <Button color="success" className="mb-3 float-right" title={this.props.t('Add Video')} onClick={() => this.setState({ addVideoModal: true, video:'',v_url:'',videoTag:'',poem_id:''})}>
                          <i className="mdi mdi-plus-outline font-size-18"></i>{this.props.t('Add Video')}
                        </Button>
                      </div>
                            {this.state.stanza_videos ?
                              this.state.stanza_videos.length > 0 ?
                              this.state.stanza_videos.map((n=>(
                               
                                <Row key={n.id}>
                                <Col md={2}>
                                  {n.video_tag}
                                </Col>
                                <Col md={6}>
                                <video width="320" height="240" src={n.video_path} controls />
                                </Col>
                                <Col md={2}>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                onClick={(event) => this.setState({ video_id: event.currentTarget.id,updateVideoModal:true },this.getVideoDetail)} id={n.id}
                              ></i>
                                </Col>
                                <Col md={2}>
                              <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                              onClick={(event) => this.setState({ video_id: event.currentTarget.id,deleteVideoModal:true })} id={n.id}
                              ></i>
                                </Col>
                              </Row>
                              )))
                              :
                              this.props.t('No Video Found')
                            :
                            ''
                            }
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                }
              </Col>
            </Row>
            {/*stanza update modal */}
            <Modal isOpen={this.state.updateStanzaModal} centered={true} scrollable={true}>
              <ModalHeader>{this.props.t('Stanza')} {this.props.t('Update')}</ModalHeader>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label for="fullStanza">{this.props.t('Full Stanza')}</Label>
                    <Input id="fullStanza" type="textarea" rows={5} value={this.state.stanza_full} onChange={(e) => this.setState({ stanza_full: e.target.value })} />
                    {this.state.errorFull ? <span className='text-danger'>{this.state.errorFull}</span> : ''}
                  </FormGroup>
                  <FormGroup>
                    <Label for="desc">{this.props.t('Description')}</Label>
                    <Input id="desc" type="textarea" rows={3} value={this.state.stanza_desc} onChange={(e) => this.setState({ stanza_desc: e.target.value })} />
                    {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span> : ''}
                  </FormGroup>
                  <FormGroup>
                    <Label for="translation">{this.props.t('Translation')}</Label>
                    <Input id="translation" type="textarea" rows={5} value={this.state.stanza_translation} onChange={(e) => this.setState({ stanza_translation: e.target.value })} />
                    {this.state.errorTranslation ? <span className='text-danger'>{this.state.errorTranslation}</span> : ''}
                  </FormGroup>
                  <FormGroup>
                    <Label for="language">{this.props.t('Language')}</Label>
                    <select id="language" onChange={(e) => this.setState({ stanza_lang: e.target.value })} className='form-control'>
                      <option value="fa" defaultValue="fa">{this.props.t('Persian')}</option>
                      <option value="ur" defaultValue="ur">{this.props.t('Urdu')}</option>
                    </select>
                    {this.state.errorLang ? <span className='text-danger'>{this.state.errorLang}</span> : ''}
                  </FormGroup>
                </Form>
                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
              </ModalBody>
              <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.updateStanza}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateStanzaModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
            </Modal>
            {/* keyword Delete modal */}
            <Modal isOpen={this.state.deleteKeywordModal} centered={true}>
              <ModalHeader>{this.props.t('Delete')} {this.props.t('Keyword')}</ModalHeader>
              <ModalBody>
                {this.props.t('delete text')}
                {/* Are you sure to delete this? */}
                {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
              </ModalBody>
              <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.deleteKeyword}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteKeywordModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
            </Modal>
            {/* Add Word/Meaning modal */}
<Modal isOpen={this.state.addWordModal} centered={true}>
  <ModalHeader>{this.props.t('Add Word/Meaning')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Word">{this.props.t('Word')}</Label>
          <Input id="Word" type="text" value={this.state.word} onChange={(e)=>this.setState({word:e.target.value})}/>
          {this.state.errorWord ? <span className='text-danger'>{this.state.errorWord}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="meaning">{this.props.t('Meaning')}</Label>
          <Input id="meaning" type="text" value={this.state.meaning} onChange={(e)=>this.setState({meaning:e.target.value})}/>
           {this.state.errorMeaning ? <span className='text-danger'>{this.state.errorMeaning}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.addNewWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Word/Meaning')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addWordModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update Word/Meaning modal */}
<Modal isOpen={this.state.updateWordModal} centered={true}>
<ModalHeader>{this.props.t('Update')} {this.props.t('Word/Meaning')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Word">{this.props.t('Word')}</Label>
          <Input id="Word" type="text" value={this.state.word} onChange={(e)=>this.setState({word:e.target.value})}/>
          {this.state.errorWord ? <span className='text-danger'>{this.state.errorWord}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="meaning">{this.props.t('Meaning')}</Label>
          <Input id="meaning" type="text" value={this.state.meaning} onChange={(e)=>this.setState({meaning:e.target.value})}/>
           {this.state.errorMeaning ? <span className='text-danger'>{this.state.errorMeaning}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.updateWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateWordModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete Word/Meaning modal */}
<Modal isOpen={this.state.deleteWordModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Word/Meaning')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.deleteWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteWordModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Add audio modal */}
<Modal isOpen={this.state.addAudioModal} centered={true}>
  <ModalHeader>{this.props.t('Add Audio')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="audio">{this.props.t('Audio')}</Label>
          <Input id="audio" type="file" onChange={(e)=>this.setState({audio:e.target.files[0]})} accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"/>
           {this.state.audioError ? <span className='text-danger'>{this.state.audioError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="url">{this.props.t('URL')}</Label>
          <Input id="url" type="url" value={this.state.url} onChange={(e) => this.setState({url:e.target.value})}/>
           {this.state.urlError ? <span className='text-danger'>{this.state.urlError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="tag">{this.props.t('Tag')}</Label>
          <Input id="tag" type="text" value={this.state.audioTag} onChange={(e) => this.setState({audioTag:e.target.value})}/>
           {this.state.audioTagError ? <span className='text-danger'>{this.state.audioTagError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="title">{this.props.t('Title')}</Label>
          <Input id="title" type="text" value={this.state.title} onChange={(e) => this.setState({title:e.target.value})}/>
           {this.state.titleError ? <span className='text-danger'>The Audio Title field is required</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="category">{this.props.t('Category')}</Label>
          <Select id="category"	isMulti={true} onChange={this.handleMulti}
						options={this.state.allCategories.map(e => ({ label: e.name, value: e.id}))}
						classNamePrefix="select2-selection"/>
        </FormGroup>
        <FormGroup>
          <Label for="poem">{this.props.t('Poem')}</Label>
          <select id="poem" defaultValue='DEFAULT' onChange={(e)=>this.setState({poem_id:e.target.value})} className='form-control'>
            <option disabled value="DEFAULT">{this.props.t('select a poem')}</option>
          {this.state.allPoems ?
                            this.state.allPoems.length > 0 ?
                            this.state.allPoems.map((n)=>(
                             <option key={n.id} value={n.id}>{n.poem_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Poem Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.poemError ? <span className='text-danger'>{this.state.poemError}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.addAudio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Audio')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addAudioModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update audio modal */}
<Modal isOpen={this.state.updateAudioModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Audio')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="audio">{this.props.t('Audio')}</Label>
          <Input id="audio" type="file" onChange={(e)=>this.setState({newAudio:e.target.files[0]})} accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"/>
          <audio src={this.state.audio} controls/>
           {this.state.audioError ? <span className='text-danger'>{this.state.audioError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="url">{this.props.t('URL')}</Label>
          <Input id="url" type="url" value={this.state.url} onChange={(e) => this.setState({url:e.target.value})}/>
           {this.state.urlError ? <span className='text-danger'>{this.state.urlError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="tag">{this.props.t('Tag')}</Label>
          <Input id="tag" type="text" value={this.state.audioTag} onChange={(e) => this.setState({audioTag:e.target.value})}/>
           {this.state.audioTagError ? <span className='text-danger'>{this.state.audioTagError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="title">{this.props.t('Title')}</Label>
          <Input id="title" type="text" value={this.state.title} onChange={(e) => this.setState({title:e.target.value})}/>
           {this.state.titleError ? <span className='text-danger'>The Audio Title field is required</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="category">{this.props.t('Category')}</Label>
          <Select id="category"	isMulti={true} onChange={this.handleMulti}
						options={this.state.allCategories.map(e => ({ label: e.name, value: e.id}))}
						classNamePrefix="select2-selection"/>
        </FormGroup>
        <FormGroup>
          <Label for="poem">{this.props.t('Poem')}</Label>
          <select id="poem" onChange={(e)=>this.setState({poem_id:e.target.value})} className='form-control'>
          {this.state.allPoems ?
                            this.state.allPoems.length > 0 ?
                            this.state.allPoems.map((n)=>(
                              <option key={n.id} value={n.id} selected={this.state.poem_id == n.id ? true : false}>{n.poem_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Poem Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.poemError ? <span className='text-danger'>{this.state.poemError}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.updateAudio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateAudioModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteAudioModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Audio')}</ModalHeader>
    <ModalBody>
    {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.deleteAudio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteAudioModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Add video modal */}
<Modal isOpen={this.state.addVideoModal} centered={true}>
  <ModalHeader>{this.props.t('Add Video')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="video">{this.props.t('Video')}</Label>
          <Input id="video" type="file" onChange={(e)=>this.setState({video:e.target.files[0]})} accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"/>
           {this.state.videoError ? <span className='text-danger'>{this.state.videoError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="url">{this.props.t('URL')}</Label>
          <Input id="url" type="url" value={this.state.v_url} onChange={(e) => this.setState({v_url:e.target.value})}/>
           {this.state.v_urlError ? <span className='text-danger'>{this.state.v_urlError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="tag">{this.props.t('Tag')}</Label>
          <Input id="tag" type="text" value={this.state.videoTag} onChange={(e) => this.setState({videoTag:e.target.value})}/>
           {this.state.videoTagError ? <span className='text-danger'>{this.state.videoTagError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="poem">{this.props.t('Poem')}</Label>
          <select id="poem" defaultValue='DEFAULT' onChange={(e)=>this.setState({poem_id:e.target.value})} className='form-control'>
            <option disabled value="DEFAULT">{this.props.t('select a poem')}</option>
          {this.state.allPoems ?
                            this.state.allPoems.length > 0 ?
                            this.state.allPoems.map((n)=>(
                             <option key={n.id} value={n.id}>{n.poem_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Poem Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.poemError ? <span className='text-danger'>{this.state.poemError}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
       <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.addVideo}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Video')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addVideoModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update video modal */}
<Modal isOpen={this.state.updateVideoModal} centered={true}>
<ModalHeader>{this.props.t('Update')} {this.props.t('Video')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="video">{this.props.t('Video')}</Label>
          <Input id="video" type="file" onChange={(e)=>this.setState({newVideo:e.target.files[0]})} accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"/>
          <center>
            <video width="220" height="140" src={this.state.newVideo ? this.state.newVideo : this.state.video} controls/>
          </center>
           {this.state.videoError ? <span className='text-danger'>{this.state.videoError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="url">{this.props.t('URL')}</Label>
          <Input id="url" type="url" value={this.state.v_url} onChange={(e) => this.setState({v_url:e.target.value})}/>
           {this.state.v_urlError ? <span className='text-danger'>{this.state.v_urlError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="tag">{this.props.t('Tag')}</Label>
          <Input id="tag" type="text" value={this.state.videoTag} onChange={(e) => this.setState({videoTag:e.target.value})}/>
           {this.state.videoTagError ? <span className='text-danger'>{this.state.videoTagError}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="poem">{this.props.t('Poem')}</Label>
          <select id="poem" onChange={(e)=>this.setState({poem_id:e.target.value})} className='form-control'>
            <option disabled>{this.props.t('select a poem')}</option>
          {this.state.allPoems ?
                            this.state.allPoems.length > 0 ?
                            this.state.allPoems.map((n)=>(
                             <option key={n.id} value={n.id} selected={this.state.poem_id == n.id ? true:false}>{n.poem_name}</option>
                            //  <option key={n.id} value={n.id} defaultValue={n.id}>{n.poem_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Poem Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.poemError ? <span className='text-danger'>{this.state.poemError}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.updateVideo}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateVideoModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete video modal */}
<Modal isOpen={this.state.deleteVideoModal} centered={true}>
<ModalHeader>{this.props.t('Delete')} {this.props.t('Video')}</ModalHeader>
    <ModalBody>
    {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.deleteVideo}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteVideoModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
            {/* success alert */}
            {this.state.successAlert ?
              <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
                {this.state.successMsg}
              </Alert>
              :
              ''
            }
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(StanzaDetail);