import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button,Spinner,Alert, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class QuizDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t('Dashboard'), link: "#" },
        { title: this.props.t('Quiz Management'), link: "#" },
      ],
      updateQuesModal:false,
      addQuesModal:false,
      deleteQuesModal:false, 
      correctModal:false,
      addOptionModal:false,
      updateOptionModal:false,
      deleteOptionModal:false,
      quiz_id: this.props.location.state,
      token:'',
      isloading:false,
      spinner:false,
      allQuiz:[],
      getAllQuizError:false,
      successAlert:false,
      successMsg:'',
      error:false,
      errorMsg:'',
      quiz_name:'',
      quiz_desc:'',
      errorDesc:'',
      errorName:'',
      ques_name:'',
      ques_desc:'',
      errorDesc:'',
      errorName:'',
      quiz_ques:[],
      question_id:'',
      option_id:'',
      opt_name:'',
      opt_desc:'',
      opt_correct:0,
      errorOptDesc:'',
      errorOptName:'',
      errorQuestion:'',
      errorCorrect:'',
      errorQuestionCorrect:'',
      keyName:'',
      keyword_id:'',
      keyNameError:'',
      key_id:'',
      allSuggestions:[],
      deleteKeywordModal:false,


    };
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
      if(this.state.token){
        this.getQuizDetail();
        this.getAllKeyword();
      }else{
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getQuizDetail = async ()=>{
    this.setState({isloading:true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("quiz_id", this.state.quiz_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_quiz", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            quiz_name:result.data.quiz.name,quiz_ques:result.data.quiz.quiz_ques,isloading:false,
            allKeywords:result.data.keywordsquiz
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  addQuestion = async()=>{
    this.setState({spinner:true})
    console.log('name',this.state.ques_name,'desc',this.state.ques_desc, 'quiz',this.state.quiz_id)
    if(this.state.ques_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.ques_desc){
      this.setState({errorOptDesc:''})
    }else{
      this.setState({errorOptDesc:'The Description field is required'})
    }
    if(this.state.quiz_id){
      this.setState({errorQuiz:''})
    }else{
      this.setState({errorQuiz:'The Quiz id field is required'})
    }
    if(this.state.errorName || this.state.errorOptDesc || this.state.errorQuiz){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("q_name", this.state.ques_name);
formdata.append("desc", this.state.ques_desc);
formdata.append("quiz_id", this.state.quiz_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertQuestions", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addQuesModal: false,
          successAlert: true,
          successMsg: this.props.t('Question') + this.props.t('Added Successfully'),
          spinner:false,
          ques_name:'',ques_desc:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getQuizDetail()
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteQues = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("question_id", this.state.question_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deleteQuestions", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteQuesModal: false,
        successAlert: true,
        successMsg: this.props.t('Question') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getQuizDetail()
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  getQuestionDetail = async ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("question_id", this.state.question_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_questions", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            ques_name:result.data.q_name,ques_desc:result.data.desc,quiz_id:result.data.quiz_id
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateQuestion = async () =>{
    this.setState({spinner:true})
    if(this.state.ques_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.ques_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.quiz_id){
      this.setState({errorQuiz:''})
    }else{
      this.setState({errorQuiz:'The Quiz id field is required'})
    }
    if(this.state.errorName || this.state.errorDesc || this.state.errorQuiz){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("q_name", this.state.ques_name);
formdata.append("desc", this.state.ques_desc);
formdata.append("quiz_id", this.state.quiz_id);
formdata.append("question_id", this.state.question_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"updateQuestions", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateQuesModal: false,
          successAlert: true,
          successMsg: this.props.t('Question') + this.props.t('Updated Successfully'),
          spinner:false,
          ques_name:'',ques_desc:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getQuizDetail()
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  markCorrect = async() =>{
    this.setState({spinner:true})
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("correct_ans", 1);
formdata.append("question_id", this.state.question_id);
formdata.append("option_id", this.state.option_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"markcorectanswer", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          correctModal: false,
          successAlert: true,
          successMsg: this.props.t('Marked as Correct Answer successfully'),
          spinner:false,
          option_id:'',question_id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getQuizDetail()
      }
    })
    .catch(error => console.log('error', error));
    }
    setCorrectAnswer = async(e)=>{
      if (e.target.checked) {
        this.setState({opt_correct:1},()=>{console.log(this.state.opt_correct)})
      }else{
        this.setState({opt_correct:0},()=>{console.log(this.state.opt_correct)})
      }
    }
    addOption = async()=>{
      console.log(this.state.opt_name,this.state.opt_desc,this.state.opt_correct,this.state.question_id)
      this.setState({spinner:true})
      if(this.state.opt_name){
        this.setState({errorOptName:''})
      }else{
        this.setState({errorOptName:'The Name field is required'})
      }
      if(this.state.opt_desc){
        this.setState({errorOptDesc:''})
      }else{
        this.setState({errorOptDesc:'The Description field is required'})
      }
      if(this.state.question_id){
        this.setState({errorQuestion:''})
      }else{
        this.setState({errorQuestion:'The Question id field is required'})
      }
      if(this.state.errorName || this.state.errorDesc || this.state.errorQuestion){
        console.log('all field are required')
        this.setState({spinner:false})
      }else{
        var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
  formdata.append("opt_name", this.state.opt_name);
  formdata.append("decs", this.state.opt_desc);
  formdata.append("correct_ans", this.state.opt_correct);
  formdata.append("question_id", this.state.question_id);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"insertOption", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
        } else {
          this.setState({
            addOptionModal: false,
            successAlert: true,
            successMsg: this.props.t('Option') + this.props.t('Added Successfully'),
            spinner:false,
            opt_name:'',opt_desc:''
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false,successMsg:'' });
          }, 2000);
          this.getQuizDetail()
        }
      })
      .catch(error => console.log('error', error));
      }
    }
    getOptionDetail = async() =>{
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);
      
      var formdata = new FormData();
      formdata.append("option_id", this.state.option_id);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(api_url+"detail_option", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong' });
          } else {
            this.setState({
              opt_name:result.data.opt_name,opt_desc:result.data.decs,opt_correct:result.data.correct_ans
            });
          }
        })
        .catch(error => console.log('error', error));
    }
    updateOption = async() =>{
      this.setState({spinner:true})
      if(this.state.opt_name){
        this.setState({errorName:''})
      }else{
        this.setState({errorName:'The Name field is required'})
      }
      if(this.state.opt_desc){
        this.setState({errorDesc:''})
      }else{
        this.setState({errordesc:'The Description field is required'})
      }
      // if(this.state.opt_correct){
      //   this.setState({errorCorrect:''})
      // }else{
      //   this.setState({errorCorrect:'The Correct Answer field is required'})
      // }
      if(this.state.question_id){
        this.setState({errorQuestion:''})
      }else{
        this.setState({errorQuestion:'The Question id field is required'})
      }
      // if(this.state.errorName || this.state.errorDesc || this.state.errorCorrect || this.state.errorQuestion){
      if(this.state.errorName || this.state.errorDesc || this.state.errorQuestion){
        console.log('all field are required')
        this.setState({spinner:false})
      }else{
        var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
  formdata.append("opt_name", this.state.opt_name);
  formdata.append("decs", this.state.opt_desc);
  formdata.append("correct_ans", this.state.opt_correct);
  formdata.append("question_id", this.state.question_id);
  formdata.append("option_id", this.state.option_id);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"updateOption", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
        } else {
          this.setState({
            updateOptionModal: false,
            successAlert: true,
            successMsg: this.props.t('Option') + this.props.t('Updated Successfully'),
            spinner:false,
            opt_name:'',opt_desc:'',opt_correct:'',option_id:'',question_id:''
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false,successMsg:'' });
          }, 2000);
          this.getQuizDetail()
        }
      })
      .catch(error => console.log('error', error));
      }
    }
    deleteOption = async()=>{
      this.setState({spinner:true})
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("option_id", this.state.option_id);
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"deleteOption", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          spinner:false,
          deleteOptionModal: false,
          successAlert: true,
          successMsg: this.props.t('Option') + this.props.t('Deleted Successfully')
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getQuizDetail()
      }
    }
      )
    .catch(error => {
      console.log('error', error)
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    });
    }
      // keyword actions
      getAllKeyword = async()=>{
        
      }
  addKeyword = async () => {
    this.setState({ spinner: true })
    if (this.state.keyName) {
      this.setState({ keyNameError: '' })
    } else {
      this.setState({ keyNameError: 'The Name field is required' })
    }
    if (this.state.keyNameError) {
      console.log('all field are required')
      this.setState({ spinner: false })
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);

      var formdata = new FormData();
      if(this.state.key_id){
        formdata.append("keywords_id", this.state.key_id);
      }else{
        formdata.append("keywords_name", this.state.keyName);
      }
      formdata.append("quiz_id", this.state.quiz_id);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(api_url + "insertKeywordsquiz", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
          } else {
            this.setState({
              successAlert: true,
              successMsg: this.props.t('Keyword') + this.props.t('Added Successfully'),
              spinner: false,
              keyName: ''
            });
            setTimeout(() => {//hide success msg after 2 seconds
              this.setState({ successAlert: false, successMsg: '' });
            }, 2000);
            this.getQuizDetail()
          }
        })
        .catch(error => console.log('error', error));
    }
  }
  deleteKeyword = async () => {
    this.setState({spinner:true})
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);

    var formdata = new FormData();
    formdata.append("keywords_quiz_id", this.state.keyword_id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api_url + "deleteKeywordsquiz", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
        } else {
          this.setState({
            spinner: false,
            deleteKeywordModal: false,
            successAlert: true,
            successMsg: this.props.t('Keyword') + this.props.t('Deleted Successfully')
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false, successMsg: '' });
          }, 2000);
          this.getQuizDetail()
        }
      }
      )
      .catch(error => {
        console.log('error', error)
        this.setState({ error: true, errorMsg: 'Something Wrong', spinner: false });
      });
  }
  getSuggestions = async()=>{
    var formdata = new FormData();
formdata.append("word", this.state.keyName);

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"show-suggestions", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    this.setState({allSuggestions:result.data})
  })
  .catch(error => console.log('error', error));
  }
  // keyword actions end
    render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t('Quiz Management')} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    {this.state.isloading ?
                  <center>
                    <div className="lds-ripple"><div></div><div></div></div>
                  </center>
                  :
                  <>
                     {/* <div className='d-flex justify-content-between'> */}
                        <h1 className='text-center'>{this.state.quiz_name}</h1>
                        <Button color="success" className="float-right detail-edit-btn" onClick={()=>this.setState({addQuesModal:true,ques_name:'',ques_desc:''})}>
                      <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Question')}
                    </Button>
                      {/* </div> */}
                      {this.state.quiz_ques ? 
                      this.state.quiz_ques.length > 0 ? 
                      <>
                <h1 className="title">{this.props.t('Questions')}</h1>
                {this.state.quiz_ques.map((n)=>(
                    <div className="container" key={n.id}>
        <form>
            <div className="test col-md-offset-3">
                <div className="question">
                    <div className='d-flex justify-content-between align-items-center'>
                    <h2 className="title">{n.q_name}</h2>
                    <div className='d-flex'>
                    <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                            onClick={(event) => this.setState({ question_id: event.currentTarget.id, updateQuesModal: true, error: false, errorMsg: '' }, this.getQuestionDetail)} id={n.id}
                                          ></i>
                    <i className="mdi mdi-plus-outline font-size-18 text-warning mr-3 cursor-pointer" title={this.props.t('Add Option')}
                                            onClick={(event) => this.setState({ question_id: event.currentTarget.id, addOptionModal: true, error: false, errorMsg: '' })} id={n.id}
                                          ></i>
                                          <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete'
                                            onClick={(event) => this.setState({ question_id: event.currentTarget.id, deleteQuesModal: true })} id={n.id}
                                          ></i>
                    </div>
                    </div>
                    <div className="choices">
                      {
                      n.ques_options ?
                      n.ques_options.length > 0 ?
                    n.ques_options.map((m,index)=>(
                      <div className='d-flex justify-content-start align-items-center'>
                        <div className="choice mr-3" key={index}>
                          <input type="radio" onClick={(e)=>this.setState({option_id:m.id, question_id:n.id,correctModal:true})} disabled={m.correct_ans== 1 ? true : false} checked={m.correct_ans== 1 ? true : false} value={n.id} name="ans" id={m.opt_name} />
                          <label for={m.opt_name}>{m.correct_ans == 1 ? <span className="glyphicon glyphicon-ok"></span> : ''}{m.opt_name}</label>
                        </div>
                        <i className='mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer' 
                        onClick={(event) => this.setState({ option_id: event.currentTarget.id, question_id:n.id,  updateOptionModal: true, error: false, errorMsg: '' }, this.getOptionDetail)} id={m.id}></i>
                        <i className='mdi mdi-trash-can font-size-18 text-danger cursor-pointer'
                        onClick={(event) => this.setState({ option_id: event.currentTarget.id, deleteOptionModal: true, error: false, errorMsg: '' })} id={m.id}></i>
                      </div>
                    ))
                    :
                    this.props.t('No Option Found')
                    :
                    ''
                    }
                        {/* <div className="choice"><input type="radio" checked value="1" name="ans" id="Apple" /><label for="Apple"><span className="glyphicon glyphicon-ok"></span>علامہ اقبال</label></div>
                        <div className="choice"><input type="radio" value="2" name="ans" id="Banana" /><label for="Banana">مرزا غالب</label></div>
                        <div className="choice"><input type="radio" value="3" name="ans" id="Spider" /><label for="Spider">میر تقی میر</label></div>
                        <div className="choice"><input type="radio" value="4" name="ans" id="Strawberry" /><label for="Strawberry">احمد فراز</label></div> */}
                    </div>
                </div>
            </div>
        </form>
        <hr/>
</div>
                ))}
                      </>
                      :
                      <>
                      {this.props.t('No Question Found')}
                      </>
                    :
                    ''
                    }
                    </>
                    }
                      <h3>{this.props.t('Keyword')}</h3>
                        <div className='d-flex'>
                        <Input className="w-25 mr-3" type="text" placeholder={this.props.t('Add Keyword')} value={this.state.keyName} onChange={(e)=>this.setState({keyName:e.target.value},this.getSuggestions)}/>
                        {this.state.spinner ? <Spinner type="border" color="primary" className=" d-flex justify-content-center align-items-center" size="sm"/> :
                        <i className="mdi mdi-plus-outline font-size-18 cursor-pointer text-primary d-flex justify-content-center align-items-center" title={this.props.t('Add Keyword')} onClick={this.addKeyword}></i>}
                        </div>
                        {this.state.allSuggestions ? 
                      <ul className='keywords-list w-25'>
                        {this.state.allSuggestions.length > 0 ?
                        this.state.allSuggestions.map((n)=>(
                          <li key={n.id} onClick={()=> this.setState({allSuggestions:[], keyName: n.keyword_name, key_id:n.id })}>{n.keyword_name}</li>
                        ))
                        :
                        ''}
                      </ul>
                      :''}
                        {this.state.keyNameError ? <span className='text-danger'>{this.state.keyNameError}</span>:''}
                        <div className='keywords d-flex'>
                            {this.state.allKeywords ? 
                            this.state.allKeywords.length > 0 ?
                            this.state.allKeywords.map((n)=>(
                            <div className='keyword'>
                            <span className='mr-1'>{n.keywords.keyword_name}</span> 
                            <i className='mdi mdi-close cursor-pointer' title={this.props.t('Delete')} onClick={(event) => this.setState({ keyword_id: event.currentTarget.id,deleteKeywordModal:true })} id={n.id}></i>
                            </div>
                            ))
                            :
                            ''
                            :
                            ''
    }
                        </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
{/* Add modal */}
<Modal isOpen={this.state.addQuesModal} centered={true}>
  <ModalHeader>{this.props.t('Add Question')}</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.ques_name} onChange={(e)=>this.setState({ques_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.ques_desc} onChange={(e)=>this.setState({ques_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.addQuestion}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Question')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addQuesModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateQuesModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Question')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.ques_name} onChange={(e)=>this.setState({ques_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.ques_desc} onChange={(e)=>this.setState({ques_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.updateQuestion}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateQuesModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteQuesModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Question')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.deleteQues}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteQuesModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Correct modal */}
<Modal isOpen={this.state.correctModal} centered={true}>
  <ModalHeader>{this.props.t('Correct Answer')}</ModalHeader>
    <ModalBody>
      {this.props.t('Are you sure to mark this as correct answer?')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.markCorrect}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Mark as Correct Answer')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({correctModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Add option modal */}
<Modal isOpen={this.state.addOptionModal} centered={true}>
  <ModalHeader>{this.props.t('Add Option')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.opt_name} onChange={(e)=>this.setState({opt_name:e.target.value})}/>
          {this.state.errorOptName ? <span className='text-danger'>{this.state.errorOptName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.opt_desc} onChange={(e)=>this.setState({opt_desc:e.target.value})}/>
           {this.state.errorOptDesc ? <span className='text-danger'>{this.state.errorOptDesc}</span>:''}
        </FormGroup> 
        <FormGroup check className="d-flex">
          <Label check> {this.props.t('Correct Answer')}</Label>
          <Input type="checkbox" onChange={(e)=>this.setCorrectAnswer(e)} checked={this.state.opt_correct === 1 ? true : false}/>
          {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.addOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Option')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addOptionModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateOptionModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Option')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.opt_name} onChange={(e)=>this.setState({opt_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.opt_desc} onChange={(e)=>this.setState({opt_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
        {/* <FormGroup>
          <Label for="correct">{this.props.t('Correct Answer')}</Label>
          <Input id="correct" type="text" value={this.state.opt_correct} onChange={(e)=>this.setState({opt_correct:e.target.value})}/>
           {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup> */}
        <FormGroup check className="d-flex">
          <Label check> {this.props.t('Correct Answer')}</Label>
          <Input type="checkbox" onChange={(e)=>this.setCorrectAnswer(e)} checked={this.state.opt_correct === 1 ? true : false}/>
          {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.updateOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateOptionModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteOptionModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Option')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.deleteOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteOptionModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete keyword modal */}
<Modal isOpen={this.state.deleteKeywordModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Keyword')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className="w-100" onClick={this.deleteKeyword}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteKeywordModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* success alert */}
{this.state.successAlert ?
            <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
              {this.state.successMsg}
            </Alert>
            :
            ''
}
        </Container>
      </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(QuizDetail);