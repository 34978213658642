import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button,Spinner,Alert, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PaginationControl } from 'react-bootstrap-pagination-control';
class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t('Dashboard'), link: "#" },
        { title: this.props.t('Quiz Management'), link: "#" },
      ],
      updateModal:false,
      addModal:false,
      deleteModal:false, 
      token:'',
      isloading:false,
      spinner:false,
      allQuiz:[],
      getAllQuizError:false,
      quiz_id:'',
      successAlert:false,
      successMsg:'',
      error:false,
      errorMsg:'',
      quiz_name:'',
      quiz_desc:'',
      errorDesc:'',
      errorName:'', 
      currentPage: 1,
      total:0,
      pageSize:0

    };
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
      if(this.state.token){
        this.getAllQuiz(1);
      }else{
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getAllQuiz = async(pageNo)=>{
    this.setState({isloading:true})
    try {
      let allQuiz = await fetch(api_url + "get_list_quiz?page="+pageNo, {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allQuiz.ok) {
        this.setState({ isloading: false,getAllQuizError:true });
        throw Error('Error');
      } else {
        allQuiz = await allQuiz.json();
        this.setState({ allQuiz: allQuiz.data.data, isloading: false,
          total:allQuiz.data.total,pageSize:allQuiz.data.per_page });
        console.warn(this.state.allQuiz);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  addQuiz = async()=>{
    this.setState({spinner:true})
    console.log('name',this.state.quiz_name,'desc',this.state.quiz_desc)
    if(this.state.quiz_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.quiz_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.errorName || this.state.errorDesc){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("name", this.state.quiz_name);
formdata.append("desc", this.state.quiz_desc);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertQuiz", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addModal: false,
          successAlert: true,
          successMsg: this.props.t('Quiz') + this.props.t('Added Successfully'),
          spinner:false,
          quiz_name:'',quiz_desc:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllQuiz();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteQuiz = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("quiz_id", this.state.quiz_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deleteQuiz", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteModal: false,
        successAlert: true,
        successMsg: this.props.t('Quiz') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getAllQuiz();
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  getQuizDetail = async ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("quiz_id", this.state.quiz_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_quiz", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            quiz_name:result.data.name,quiz_desc:result.data.desc
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateQuiz = async () =>{
    this.setState({spinner:true})
    console.log('name',this.state.quiz_name,'desc',this.state.quiz_desc)
    if(this.state.quiz_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.quiz_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.errorName || this.state.errorDesc){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("name", this.state.quiz_name);
formdata.append("desc", this.state.quiz_desc);
formdata.append("quiz_id", this.state.quiz_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"updateQuiz", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateModal: false,
          successAlert: true,
          successMsg: this.props.t('Quiz') + this.props.t('Updated Successfully'),
          spinner:false,
          quiz_name:'',quiz_desc:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllQuiz();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
    render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t('Quiz Management')} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="success" className="mb-3 float-right" onClick={()=>this.setState({addModal:true, quiz_name:'',quiz_desc:''})}>
                      <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Quiz')}
                    </Button>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" striped bordered responsive>
                          <thead>
                            <tr>
                              <th>{this.props.t('Id')}</th>
                              <th>{this.props.t('Name')}</th>
                              <th>{this.props.t('Description')}</th>
                              <th>{this.props.t('Actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.isloading ? 
                            <tr>
                              <td colSpan={4}>
                                <center>
                                  <div className="lds-ripple"><div></div><div></div></div>
                                </center>
                              </td>
                            </tr>
                            :
                            this.state.allQuiz ?
                            this.state.allQuiz.length > 0 ?
                            this.state.allQuiz.map((n)=>(
                              <tr>
                              <th>{n.id}</th>
                              <th><Link to={{pathname: "/quizDetail/"+ n.id, state : n.id}}>{n.name}</Link></th>
                              <td>{n.desc}</td>
                              <td>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ quiz_id: event.currentTarget.id,updateModal:true },this.getQuizDetail)} id={n.id}
                                //  onClick={()=>this.setState({updateModal:true})}
                                ></i>
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ quiz_id: event.currentTarget.id,deleteModal:true })} id={n.id}
                                // onClick={()=>this.setState({deleteModal:true})}
                                ></i>
                              </td>
                            </tr>
                            ))
                            :
                            <tr>
                              <td colSpan={8}>
                                <center>
                                  <div>{this.props.t('No Quiz Found')}</div>
                                </center>
                              </td>
                            </tr>
                            :
                            ''
                           
  }
                          </tbody>
                        </Table>
                        {this.state.total < this.state.pageSize ?
                        ''
                        :
                        <PaginationControl
                          page={this.state.currentPage}
                          between={3}
                          total={this.state.total}
                          limit={this.state.pageSize}
                          changePage={(page) => this.setState({ currentPage: page }, () => {
                            this.getAllQuiz(page)
                          })}
                          ellipsis={1}
                        />
  }
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
{/* Add modal */}
<Modal isOpen={this.state.addModal} centered={true}>
  <ModalHeader>{this.props.t('Add Quiz')}</ModalHeader>
    <ModalBody>
      <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.quiz_name} onChange={(e)=>this.setState({quiz_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.quiz_desc} onChange={(e)=>this.setState({quiz_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.addQuiz}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Quiz')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Quiz')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.quiz_name} onChange={(e)=>this.setState({quiz_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.quiz_desc} onChange={(e)=>this.setState({quiz_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
    <Button color="primary" className='w-100' onClick={this.updateQuiz}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Quiz')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
    <Col>
      <Button color="primary" className='w-100' onClick={this.deleteQuiz}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* success alert */}
{this.state.successAlert ?
            <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
              {this.state.successMsg}
            </Alert>
            :
            ''
}
        </Container>
      </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Quiz);