import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";

class MiniWidgets extends Component {
    render() {
        return (
					<React.Fragment>
						{this.props.reports.map((report, key) => (
							<Col key={key} md={4}>
								<Card>
									<CardBody>
										<Media>
											<Media body className="overflow-hidden" >
												<p className="text-truncate text-center font-size-22 mb-2">
													{report.title}
												</p>
												<h4 className="mb-0 text-center font-size-22">
													{report.value}
												</h4>
											</Media>
											<div className="text-primary">
												<i
													className={report.icon + " font-size-24"}
													style={{ color: "#F2C938" }}
												></i>
											</div>
										</Media>
									</CardBody>
								</Card>
							</Col>
						))}
					</React.Fragment>
				);
    }
}

export default MiniWidgets;