import React, {Component} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    Label,
    Input,
    FormGroup,
    Spinner,
    Alert,
} from "reactstrap";
import "../Tables/datatables.scss";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {api_url} from "../../components/Common/Variables";
import {Link} from "react-router-dom";
import Moment from "moment";
import book from "../../assets/images/book.png";
import "bootstrap/dist/css/bootstrap.min.css";
import {PaginationControl} from "react-bootstrap-pagination-control";
//i18n
import {withNamespaces} from "react-i18next";

class Book extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t("Dashboard"), link: "#"},
                {title: this.props.t("Books managemnet"), link: "#"},
            ],
            updateModal: false,
            addModal: false,
            deleteModal: false,
            token: "",
            isloading: false,
            spinner: false,
            allBooks: [],
            getAllBooksError: false,
            book_id: "",
            successAlert: false,
            successMsg: "",
            error: false,
            errorMsg: "",
            book_name: "",
            book_tagline: "",
            book_desc: "",
            book_publisher: "",
            book_publishDate: "",
            book_image: "",
            new_book_image: "",
            book_lang: "ur",
            search_book: "",
            book_type: "",
            errorType: "",
            errorName: "",
            errorTagline: "",
            errorDesc: "",
            errorPublisher: "",
            errorPublishDate: "",
            errorImage: "",
            errorLang: "",
            currentPage: 1,
            total: 0,
            pageSize: 0,
            search_term: '',
            booksLang: "",
            // added_book_image:''
        };
    }

    componentDidMount = async () => {
        const token = JSON.parse(localStorage.getItem("token"));
        this.setState({token: token}, () => {
            if (this.state.token) {
                this.getAllBooks();
            } else {
                console.log("no token");
                this.props.history.push("/login");
            }
        });
    };

    getAllBooks = async () => {
        this.setState({isloading: true});
        try {
            let allBooks = await fetch(api_url + "get_all_books", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                },
            });
            if (!allBooks.ok) {
                this.setState({isloading: false, getAllBooksError: true});
                throw Error("Error");
            } else {
                allBooks = await allBooks.json();
                this.setState({allBooks: allBooks.data, isloading: false});
                console.warn(this.state.allBooks);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    searchBooksbyLang = async (e) => {
        this.setState(
            {
                search_book: e.target.value,
                booksLang: e.target.value,
            },
            () => {
                console.log(this.state.search_book);
                this.setState({isloading: true});
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${this.state.token}`);

                var formdata = new FormData();
                formdata.append("book_language", this.state.search_book);

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow",
                };

                fetch(api_url + "search_books", requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        console.log(result);
                        this.setState({
                            allBooks: result.data.data,
                            isloading: false,
                            total: result.data.total,
                            pageSize: result.data.per_page,
                        });
                    })
                    .catch((error) => console.log("error", error));
            }
        );
    };

    searchBooksbyType = async (e) => {
        this.setState({search_book: e.target.value}, () => {
            console.log(this.state.search_book);
            this.setState({isloading: true});
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("book_language", this.state.booksLang);
            formdata.append("book_type", this.state.search_book);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "search_books", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        allBooks: result.data.data,
                        isloading: false,
                        total: result.data.total,
                        pageSize: result.data.per_page,
                    });
                })
                .catch((error) => console.log("error", error));
        });
    };

    searchBooksbyLangPage = async (pageNo) => {
        this.setState({isloading: true});
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("book_language", this.state.search_book);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "search_books?page=1" + pageNo, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                this.setState({
                    allBooks: result.data.data,
                    isloading: false,
                    total: result.data.total,
                    pageSize: result.data.per_page,
                });
            })
            .catch((error) => console.log("error", error));
    };

    deleteBook = async () => {
        this.setState({spinner: true});
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("book_id", this.state.book_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "delete_book", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    this.setState({
                        error: true,
                        errorMsg: "Something Wrong",
                        spinner: false,
                    });
                } else {
                    this.setState({cancelBooking: result, spinner: false});
                    console.warn(this.state.cancelBooking);
                    this.setState({
                        deleteModal: false,
                        successAlert: true,
                        successMsg: this.props.t("Deleted Successfully"),
                    });
                    setTimeout(() => {
                        //hide success msg after 2 seconds
                        this.setState({successAlert: false, successMsg: ""});
                    }, 2000);
                    this.getAllBooks();
                }
            })
            .catch((error) => {
                console.log("error", error);
                this.setState({
                    error: true,
                    errorMsg: "Something Wrong",
                    spinner: false,
                });
            });
    };

    addBook = async () => {
        this.setState({spinner: true});
        console.log(
            this.state.book_name,
            this.state.book_tagline,
            this.state.book_desc,
            this.state.book_publisher,
            this.state.book_publishDate,
            this.state.book_image
        );
        if (this.state.book_name) {
            this.setState({errorName: ""});
        } else {
            this.setState({errorName: "The Name field is required"});
        }
        if (this.state.book_desc) {
            this.setState({errorDesc: ""});
        } else {
            this.setState({errordesc: "The Description field is required"});
        }
        if (this.state.book_lang) {
            this.setState({errorLang: ""});
        } else {
            this.setState({errorLang: "The Language field is required"});
        }
        if (this.state.book_type) {
            this.setState({errorType: ""});
        } else {
            this.setState({errorType: "The Book Type field is required"});
        }
        if (this.state.book_image) {
            this.setState({errorImage: ""});
        } else {
            this.setState({errorImage: "The book image field is required"});
        }
        if (
            this.state.errorName ||
            this.state.errorDesc ||
            this.state.errorLang ||
            this.state.errorImage ||
            this.state.errorType
        ) {
            console.log("all field are required");
            this.setState({spinner: false});
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("name", this.state.book_name);
            formdata.append("desc", this.state.book_desc);
            formdata.append("publisher", this.state.book_publisher);
            formdata.append("publication_date", this.state.book_publishDate);
            formdata.append("book_language", this.state.book_lang);
            formdata.append("book_image", this.state.book_image);
            formdata.append("book_type", this.state.book_type);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "insert_books", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        this.setState({
                            error: true,
                            errorMsg: "Something Wrong",
                            spinner: false,
                        });
                    } else {
                        this.setState({
                            addModal: false,
                            successAlert: true,
                            successMsg: this.props.t("Added Successfully"),
                            spinner: false,
                            book_name: "",
                            book_tagline: "",
                            book_desc: "",
                            book_publisher: "",
                            book_publishDate: "",
                            book_image: "",
                            book_lang: "",
                        });
                        setTimeout(() => {
                            //hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ""});
                        }, 2000);
                        this.getAllBooks();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };
    getBookDetail = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("book_id", this.state.book_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "detail_book", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    this.setState({error: true, errorMsg: "Something Wrong"});
                } else {
                    this.setState({
                        book_name: result.data.name,
                        book_tagline: result.data.tag_line,
                        book_desc: result.data.desc,
                        book_publisher: result.data.publisher,
                        book_publishDate: result.data.publication_date,
                        book_image: result.data.book_image,
                        book_lang: result.data.language,
                        book_type: result.data.book_type,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };
    updateBook = async () => {
        console.log(this.state.book_publishDate);
        this.setState({spinner: true});
        if (this.state.book_name) {
            this.setState({errorName: ""});
        } else {
            this.setState({errorName: "The Name field is required"});
        }
        if (this.state.book_desc) {
            this.setState({errorDesc: ""});
        } else {
            this.setState({errordesc: "The Description field is required"});
        }
        if (this.state.book_type) {
            this.setState({errorType: ""});
        } else {
            this.setState({errorType: "The Book Type field is required"});
        }
        if (this.state.book_lang) {
            this.setState({errorLang: ""});
        } else {
            this.setState({errorLang: "The Language field is required"});
        }
        if (
            this.state.errorName ||
            this.state.errorDesc ||
            this.state.errorLang ||
            this.state.errorType
        ) {
            console.log("all field are required");
            this.setState({spinner: false});
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("name", this.state.book_name);
            formdata.append("tag_line", this.state.book_tagline);
            formdata.append("desc", this.state.book_desc);
            formdata.append("publisher", this.state.book_publisher);
            if (this.state.book_publishDate != null) {
                formdata.append("publication_date", this.state.book_publishDate);
            }
            formdata.append("book_image", this.state.new_book_image);
            formdata.append("book_language", this.state.book_lang);
            formdata.append("book_id", this.state.book_id);

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "update_book", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        this.setState({
                            error: true,
                            errorMsg: result.message,
                            spinner: false,
                        });
                    } else {
                        this.setState({
                            updateModal: false,
                            successAlert: true,
                            successMsg: this.props.t("Updated Successfully"),
                            spinner: false,
                            book_name: "",
                            book_tagline: "",
                            book_desc: "",
                            book_publisher: "",
                            book_publishDate: "",
                            book_image: "",
                            book_lang: "",
                            book_type: "",
                        });
                        setTimeout(() => {
                            //hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ""});
                        }, 2000);
                        this.getAllBooks();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };
    loadFile = (event) => {
        this.setState({new_book_image: event.target.files[0]});
        var image = document.getElementById("output");
        image.src = URL.createObjectURL(event.target.files[0]);
    };

    Search = async () => {
        this.setState({isloading: true});
        console.log(this.state.search_term);
		try {
			let allBooks = await fetch(api_url + "get_all_books?search=" + this.state.search_term, {
				method: "get",
				headers: {
					Authorization: `Bearer ${this.state.token}`,
				},
			});
			if (!allBooks.ok) {
				this.setState({isloading: false, getAllBooksError: true});
				throw Error("Error");
			} else {
				allBooks = await allBooks.json();
				this.setState({allBooks: allBooks.data, isloading: false});
				console.warn(this.state.allBooks);
			}
		} catch (err) {
			console.log("Error");
		}
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Input
                            type="text"
                            className="position-absolute"
                            value={this.state.search_term}
                            onChange={(e) => {
                                this.setState({search_term: e.target.value}, () => {
                                    this.Search();
                                });
                            }}
                            placeholder={this.props.t("Search")}
                            style={{width: '20vw', left: '2vw', zIndex: '999999'}}
                        />
                        <Breadcrumbs
                            title={this.props.t("Books managemnet")}
                            breadcrumbItems={this.state.breadcrumbItems}
                        />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div class="lang">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        class="input-radio"
                                                        value="ur"
                                                        name="lang"
                                                        onChange={(e) => this.searchBooksbyLang(e)}
                                                    />{" "}
                                                    <span className="text">{this.props.t("Urdu")}</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        class="input-radio"
                                                        value="fa"
                                                        name="lang"
                                                        onChange={(e) => this.searchBooksbyLang(e)}
                                                    />{" "}
                                                    <span className="text">
														{this.props.t("Persian")}
													</span>
                                                </label>
                                            </div>

                                            <div class="lang" style={{marginRight: "28em"}}>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        class="input-radio"
                                                        value="poetry"
                                                        name="type"
                                                        onChange={(e) => this.searchBooksbyType(e)}
                                                    />{" "}
                                                    <span className="text">{this.props.t("Poetry")}</span>
                                                </label>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        class="input-radio"
                                                        value="prose"
                                                        name="type"
                                                        onChange={(e) => this.searchBooksbyType(e)}
                                                    />{" "}
                                                    <span className="text">{this.props.t("Prose")}</span>
                                                </label>
                                            </div>
                                            <Button
                                                color="success"
                                                className="mb-3 float-right"
                                                onClick={() => this.setState({addModal: true})}
                                            >
                                                <i className="mdi mdi-card-plus-outline mr-3 font-size-18"></i>
                                                {this.props.t("Add Book")}
                                            </Button>
                                        </div>
                                        {/* <MDBDataTable responsive striped bordered data={data} /> */}
                                        <div className="table-rep-plugin">
                                            <div
                                                className="table-responsive mb-0"
                                                data-pattern="priority-columns"
                                            >
                                                <Table
                                                    id="tech-companies-1"
                                                    striped
                                                    bordered
                                                    responsive
                                                >
                                                    <thead>
                                                    <tr>
                                                        <th>{this.props.t("Id")}</th>
                                                        <th>{this.props.t("Name")}</th>
                                                        <th>{this.props.t("Description")}</th>
                                                        <th>{this.props.t("Publisher")}</th>
                                                        <th>{this.props.t("Publish Date")}</th>
                                                        <th>{this.props.t("Language")}</th>
                                                        <th>{this.props.t("Image")}</th>
                                                        <th>{this.props.t("Actions")}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.isloading ? (
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <center>
                                                                    <div className="lds-ripple">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    ) : this.state.allBooks ? (
                                                        this.state.allBooks.length > 0 ? (
                                                            this.state.allBooks.map((n) => (
                                                                <tr>
                                                                    <th>{n.id}</th>
                                                                    <th>
                                                                        <Link
                                                                            to={{
                                                                                pathname: "/bookDetail/" + n.id,
                                                                                state: n.id,
                                                                            }}
                                                                        >
                                                                            {n.name}
                                                                        </Link>
                                                                    </th>
                                                                    <td>
                                                                        {n.desc.length > 25
                                                                            ? n.desc.substr(0, 24) + "..."
                                                                            : n.desc}
                                                                    </td>
                                                                    <td>{n.publisher}</td>
                                                                    <td>
                                                                        {n.publication_date
                                                                            ? Moment(n.publication_date).format(
                                                                                "MMM D, YYYY"
                                                                            )
                                                                            : ""}
                                                                    </td>
                                                                    {/* <td>{n.publication_date}</td> */}
                                                                    <td>
                                                                        {n.language == "ur"
                                                                            ? this.props.t("Urdu")
                                                                            : n.language == "fa"
                                                                                ? this.props.t("Persian")
                                                                                : ""}
                                                                    </td>
                                                                    <td>
                                                                        {n.book_image ? (
                                                                            <img
                                                                                src={n.book_image}
                                                                                className="book-image-in-table"
                                                                                alt="book image"
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={book}
                                                                                className="book-image-in-table"
                                                                                alt="book image"
                                                                            />
                                                                        )}
                                                                    </td>
                                                                    <td>
                                                                        <i
                                                                            className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
                                                                            title="Edit"
                                                                            onClick={(event) =>
                                                                                this.setState(
                                                                                    {
                                                                                        book_id: event.currentTarget.id,
                                                                                        updateModal: true,
                                                                                    },
                                                                                    this.getBookDetail
                                                                                )
                                                                            }
                                                                            id={n.id}
                                                                            //  onClick={()=>this.setState({updateModal:true})}
                                                                        ></i>
                                                                        <i
                                                                            className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
                                                                            title="Delete"
                                                                            onClick={(event) =>
                                                                                this.setState({
                                                                                    book_id: event.currentTarget.id,
                                                                                    deleteModal: true,
                                                                                })
                                                                            }
                                                                            id={n.id}
                                                                            // onClick={()=>this.setState({deleteModal:true})}
                                                                        ></i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={8}>
                                                                    <center>
                                                                        <div>
                                                                            {this.props.t("No Books Found")}
                                                                        </div>
                                                                    </center>
                                                                </td>
                                                            </tr>
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                    </tbody>
                                                </Table>
                                                {this.state.total <= this.state.pageSize ? (
                                                    ""
                                                ) : (
                                                    <PaginationControl
                                                        page={this.state.currentPage}
                                                        between={3}
                                                        total={this.state.total}
                                                        limit={this.state.pageSize}
                                                        changePage={(page) =>
                                                            this.setState({currentPage: page}, () => {
                                                                this.searchBooksbyLangPage(page);
                                                            })
                                                        }
                                                        ellipsis={1}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Add modal */}
                        <Modal
                            isOpen={this.state.addModal}
                            centered={true}
                            scrollable={true}
                        >
                            <ModalHeader>{this.props.t("Add Book")}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="bookname">{this.props.t("Name")}</Label>
                                        <Input
                                            id="bookname"
                                            type="text"
                                            value={this.state.book_name}
                                            onChange={(e) =>
                                                this.setState({book_name: e.target.value})
                                            }
                                        />
                                        {this.state.errorName ? (
                                            <span className="text-danger">
												{this.state.errorName}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t("Description")}</Label>
                                        <Input
                                            id="desc"
                                            type="textarea"
                                            rows={3}
                                            value={this.state.book_desc}
                                            onChange={(e) =>
                                                this.setState({book_desc: e.target.value})
                                            }
                                        />
                                        {this.state.errorDesc ? (
                                            <span className="text-danger">
												{this.state.errorDesc}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="publisher">{this.props.t("Publisher")}</Label>
                                        <Input
                                            id="publisher"
                                            type="text"
                                            value={this.state.book_publisher}
                                            onChange={(e) =>
                                                this.setState({book_publisher: e.target.value})
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="date">{this.props.t("Publish Date")}</Label>
                                        <Input
                                            id="date"
                                            type="date"
                                            value={this.state.book_publishDate}
                                            onChange={(e) =>
                                                this.setState({book_publishDate: e.target.value})
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="type">{this.props.t("Type")}</Label>
                                        <select
                                            id="type"
                                            onChange={(e) =>
                                                this.setState({book_type: e.target.value})
                                            }
                                            className="form-control"
                                        >
                                            <option selected>
                                                {this.props.t("Select any Type")}
                                            </option>
                                            <option value="Poetry">{this.props.t("Poetry")}</option>
                                            <option value="Prose">{this.props.t("Prose")}</option>
                                        </select>
                                        {this.state.errorType ? (
                                            <span className="text-danger">
												{this.state.errorType}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="language">{this.props.t("Language")}</Label>
                                        <select
                                            id="language"
                                            onChange={(e) =>
                                                this.setState({book_lang: e.target.value})
                                            }
                                            className="form-control"
                                        >
                                            <option selected>
                                                {this.props.t("Select a Language")}
                                            </option>
                                            <option value="fa">{this.props.t("Persian")}</option>
                                            <option value="ur">{this.props.t("Urdu")}</option>
                                        </select>
                                        {this.state.errorLang ? (
                                            <span className="text-danger">
												{this.state.errorLang}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="image">{this.props.t("Image")}</Label>
                                        <Input
                                            id="image"
                                            type="file"
                                            onChange={(e) =>
                                                this.setState({book_image: e.target.files[0]})
                                            }
                                        />
                                        {this.state.errorImage ? (
                                            <span className="text-danger">
												{this.state.errorImage}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                </Form>
                                {this.state.error ? (
                                    <span className="text-danger">{this.state.errorMsg}</span>
                                ) : (
                                    ""
                                )}
                            </ModalBody>
                            <ModalFooter className="row d-flex justify-content-between align-items-center">
                                <Col>
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={this.addBook}
                                    >
                                        {this.state.spinner ? (
                                            <Spinner type="border" color="light" size="sm"/>
                                        ) : (
                                            this.props.t("Add Book")
                                        )}
                                    </Button>{" "}
                                </Col>
                                <Col>
                                    <Button
                                        color="secondary"
                                        className="w-100"
                                        onClick={() => this.setState({addModal: false})}
                                    >
                                        {this.props.t("Cancel")}
                                    </Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* update modal */}
                        <Modal
                            isOpen={this.state.updateModal}
                            centered={true}
                            scrollable={true}
                        >
                            <ModalHeader>
                                {this.props.t("Update")} {this.props.t("Book")}
                            </ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="bookname">{this.props.t("Name")}</Label>
                                        <Input
                                            id="bookname"
                                            type="text"
                                            value={this.state.book_name}
                                            onChange={(e) =>
                                                this.setState({book_name: e.target.value})
                                            }
                                        />
                                        {this.state.errorName ? (
                                            <span className="text-danger">
												{this.state.errorName}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t("Description")}</Label>
                                        <Input
                                            id="desc"
                                            type="textarea"
                                            rows={3}
                                            value={this.state.book_desc}
                                            onChange={(e) =>
                                                this.setState({book_desc: e.target.value})
                                            }
                                        />
                                        {this.state.errorDesc ? (
                                            <span className="text-danger">
												{this.state.errorDesc}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="publisher">{this.props.t("Publisher")}</Label>
                                        <Input
                                            id="publisher"
                                            type="text"
                                            value={this.state.book_publisher}
                                            onChange={(e) =>
                                                this.setState({book_publisher: e.target.value})
                                            }
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="date">{this.props.t("Publish Date")}</Label>
                                        <Input
                                            id="date"
                                            type="date"
                                            value={
                                                this.state.book_publishDate
                                                    ? this.state.book_publishDate.split(" ")[0]
                                                    : ""
                                            }
                                            onChange={(e) =>
                                                this.setState({book_publishDate: e.target.value})
                                            }
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="type">{this.props.t("Type")}</Label>
                                        <select
                                            id="type"
                                            onChange={(e) =>
                                                this.setState({book_type: e.target.value})
                                            }
                                            className="form-control"
                                        >
                                            <option disabled>
                                                {this.props.t("Select any Type")}
                                            </option>
                                            <option
                                                value="Poetry"
                                                selected={
                                                    this.state.book_type == "poetry" ? true : false
                                                }
                                            >
                                                {this.props.t("Poetry")}
                                            </option>
                                            <option
                                                value="Prose"
                                                selected={
                                                    this.state.book_type == "prose" ? true : false
                                                }
                                            >
                                                {this.props.t("Prose")}
                                            </option>
                                        </select>
                                        {this.state.errorType ? (
                                            <span className="text-danger">
												{this.state.errorType}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="language">{this.props.t("Language")}</Label>
                                        <select
                                            id="language"
                                            onChange={(e) =>
                                                this.setState({book_lang: e.target.value})
                                            }
                                            className="form-control"
                                        >
                                            <option
                                                value="fa"
                                                selected={this.state.book_lang === "fa" ? true : false}
                                            >
                                                {this.props.t("Persian")}
                                            </option>
                                            <option
                                                value="ur"
                                                selected={this.state.book_lang === "ur" ? true : false}
                                            >
                                                {this.props.t("Urdu")}
                                            </option>
                                        </select>
                                        {this.state.errorLang ? (
                                            <span className="text-danger">
												{this.state.errorLang}
											</span>
                                        ) : (
                                            ""
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="image">{this.props.t("Image")}</Label>
                                        {this.state.book_image ? (
                                            <div className="position-relative">
                                                <div className="profile-pic">
                                                    <label className="-label" for="file">
                                                        <span className="mdi mdi-camera-outline"></span>
                                                        <span>{this.props.t("Change Image")}</span>
                                                    </label>
                                                    <input
                                                        id="file"
                                                        type="file"
                                                        onChange={(e) => this.loadFile(e)}
                                                    />
                                                    <img
                                                        src={this.state.book_image}
                                                        alt="user"
                                                        id="output"
                                                        width="200"
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <Input
                                                id="image"
                                                type="file"
                                                onChange={(e) =>
                                                    this.setState({new_book_image: e.target.files[0]})
                                                }
                                            />
                                        )}
                                        {/* <Input id="image" type="file" onChange={(e)=>this.setState({new_book_image:e.target.files[0]})}/>
          {this.state.book_image ? <img src={this.state.book_image} alt='' className='update-book-image'/> :''} */}
                                        {/* {this.state.errorImage ? <span className='text-danger'>{this.state.errorImage}</span>:''} */}
                                    </FormGroup>
                                </Form>
                                {this.state.error ? (
                                    <span className="text-danger">{this.state.errorMsg}</span>
                                ) : (
                                    ""
                                )}
                            </ModalBody>
                            <ModalFooter className="row d-flex justify-content-between align-items-center">
                                <Col>
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={this.updateBook}
                                    >
                                        {this.state.spinner ? (
                                            <Spinner type="border" color="light" size="sm"/>
                                        ) : (
                                            this.props.t("Update")
                                        )}
                                    </Button>{" "}
                                </Col>
                                <Col>
                                    <Button
                                        color="secondary"
                                        className="w-100"
                                        onClick={() => this.setState({updateModal: false})}
                                    >
                                        {this.props.t("Cancel")}
                                    </Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* Delete modal */}
                        <Modal isOpen={this.state.deleteModal} centered={true}>
                            <ModalHeader>
                                {this.props.t("Delete")} {this.props.t("Book")}
                            </ModalHeader>
                            <ModalBody>
                                {this.props.t("delete text")}
                                {/* Are you sure to delete this? */}
                                {this.state.error ? (
                                    <div className="text-danger">{this.state.errorMsg}</div>
                                ) : (
                                    ""
                                )}
                            </ModalBody>
                            <ModalFooter className="row d-flex justify-content-between align-items-center">
                                <Col>
                                    <Button
                                        color="primary"
                                        className="w-100"
                                        onClick={this.deleteBook}
                                    >
                                        {this.state.spinner ? (
                                            <Spinner type="border" color="light" size="sm"/>
                                        ) : (
                                            this.props.t("Yes")
                                        )}
                                    </Button>{" "}
                                </Col>
                                <Col>
                                    <Button
                                        color="secondary"
                                        className="w-100"
                                        onClick={() => this.setState({deleteModal: false})}
                                    >
                                        {this.props.t("No")}
                                    </Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* success alert */}
                        {this.state.successAlert ? (
                            <Alert
                                color="success"
                                style={{
                                    position: "absolute",
                                    top: "50px",
                                    right: "25px",
                                    zIndex: "10000",
                                }}
                            >
                                {this.state.successMsg}
                            </Alert>
                        ) : (
                            ""
                        )}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Book);
