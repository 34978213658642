import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container,Spinner,Alert, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class Biography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t('Dashboard'), link: "#" },
        { title: this.props.t('Biography Management'), link: "#" },
      ],
      updateModal:false,
      addModal:false,
      deleteModal:false, 
      token:'',
      isloading:false,
      spinner:false,
      allBios:[],
      getAllBiosError:false,
      bio_id:'',
      successAlert:false,
      successMsg:'',
      error:false,
      errorMsg:'',
      bio_name:'',
      bio_desc:'',
      bio_trans:'',
      errorDesc:'',
      errorName:'',
      errorTrans:'',
    };
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
      if(this.state.token){
        this.getAllBiography();
      }else{
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getAllBiography = async()=>{
    this.setState({isloading:true})
    try {
      let allBiography = await fetch(api_url + "get_all_bio", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allBiography.ok) {
        this.setState({ isloading: false,getAllBiosError:true });
        throw Error('Error');
      } else {
        allBiography = await allBiography.json();
        this.setState({ allBios: allBiography.data, isloading: false });
        console.warn(this.state.allBios);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  addBio = async()=>{
    this.setState({spinner:true})
    if(this.state.bio_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.bio_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.bio_trans){
      this.setState({errorTrans:''})
    }else{
      this.setState({errorTrans:'The Translation field is required'})
    }
    if(this.state.errorName || this.state.errorDesc || this.state.errorTrans){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("bio_name", this.state.bio_name);
formdata.append("desc", this.state.bio_desc);
formdata.append("translation", this.state.bio_trans);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insert_bio_info", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addModal: false,
          successAlert: true,
          successMsg: this.props.t('Biography') + this.props.t('Added Successfully'),
          spinner:false,
          bio_name:'',bio_desc:'',bio_trans:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllBiography();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  getBioDetail = async() =>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("bio_id", this.state.bio_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_bio_info", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            bio_name:result.data.bio_name,bio_desc:result.data.desc,bio_trans:result.data.translation
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateBio = async() =>{
    this.setState({spinner:true})
    if(this.state.bio_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.bio_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    if(this.state.bio_trans){
      this.setState({errorTrans:''})
    }else{
      this.setState({errorTrans:'The Translation field is required'})
    }
    if(this.state.errorName || this.state.errorDesc || this.state.errorTrans){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
  formdata.append("bio_name", this.state.bio_name);
  formdata.append("desc", this.state.bio_desc);
  formdata.append("translation", this.state.bio_trans);
  formdata.append("bio_id", this.state.bio_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"update_bio_info", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateModal: false,
          successAlert: true,
          successMsg: this.props.t('Biography') + this.props.t('Updated Successfully'),
          spinner:false,
          bio_name:'',bio_desc:'',bio_trans:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllBiography();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteBio = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("bio_id", this.state.bio_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"delete_bio_info", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteModal: false,
        successAlert: true,
        successMsg: this.props.t('Biography') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getAllBiography();
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t('Biography Management')} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="success" className="mb-3 float-right" onClick={()=>this.setState({addModal:true,bio_name:'',bio_desc:'',bio_trans:''})}>
                      <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Biography')}
                    </Button>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" striped bordered responsive>
                          <thead>
                            <tr>
                              <th>{this.props.t('Id')}</th>
                              <th>{this.props.t('Name')}</th>
                              <th>{this.props.t('Description')}</th>
                              <th>{this.props.t('Translation')}</th>
                              <th>{this.props.t('Actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.isloading ? 
                            <tr>
                              <td colSpan={5}>
                                <center>
                                  <div className="lds-ripple"><div></div><div></div></div>
                                </center>
                              </td>
                            </tr>
                            :
                            this.state.allBios ?
                            this.state.allBios.length > 0 ?
                            this.state.allBios.map((n)=>(
                              <tr>
                              <th>{n.id}</th>
                              <th>{n.bio_name}</th>
                              <td>{n.desc}</td>
                              <td>{n.translation}</td>
                              <td>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ bio_id: event.currentTarget.id,updateModal:true },this.getBioDetail)} id={n.id}
                                ></i>
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ bio_id: event.currentTarget.id,deleteModal:true })} id={n.id}
                                ></i>
                              </td>
                            </tr>
                            ))
                            :
                            <tr>
                              <td colSpan={8}>
                                <center>
                                  <div>{this.props.t('No Biography Found')}</div>
                                </center>
                              </td>
                            </tr>
                            :
                            ''
                           
  }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
{/* Add modal */}
<Modal isOpen={this.state.addModal} centered={true}>
  <ModalHeader>{this.props.t('Add Biography')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.bio_name} onChange={(e)=>this.setState({bio_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.bio_desc} onChange={(e)=>this.setState({bio_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="trans">{this.props.t('Translation')}</Label>
          <Input id="trans" type="textarea" rows={3} value={this.state.bio_trans} onChange={(e)=>this.setState({bio_trans:e.target.value})}/>
           {this.state.errorTrans ? <span className='text-danger'>{this.state.errorTrans}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.addBio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Biography')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Biography')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.bio_name} onChange={(e)=>this.setState({bio_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.bio_desc} onChange={(e)=>this.setState({bio_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="trans">{this.props.t('Translation')}</Label>
          <Input id="trans" type="textarea" rows={3} value={this.state.bio_trans} onChange={(e)=>this.setState({bio_trans:e.target.value})}/>
           {this.state.errorTrans ? <span className='text-danger'>{this.state.errorTrans}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.updateBio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Biography')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.deleteBio}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* success alert */}
{this.state.successAlert ?
            <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
              {this.state.successMsg}
            </Alert>
            :
            ''
}
        </Container>
      </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Biography);