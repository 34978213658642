import React, { Component } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	Container,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Form,
	Label,
	Alert,
	Input,
	FormGroup,
	Spinner,
} from "reactstrap";
import "../Tables/datatables.scss";
import { withNamespaces } from "react-i18next";
import { api_url } from "../../components/Common/Variables";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "bootstrap/dist/css/bootstrap.min.css";
import { PaginationControl } from "react-bootstrap-pagination-control";
import user from "../../assets/images/users/user.png";
class UserManagemnet extends Component {
	constructor(props) {
		super(props);
		this.state = {
			breadcrumbItems: [
				{ title: this.props.t("Dashboard"), link: "#" },
				{ title: this.props.t("User Management"), link: "#" },
			],
			updateModal: false,
			addModal: false,
			deleteModal: false,
			active_deactiveModal: false,
			token: "",
			isloading: false,
			spinner: false,
			allUsers: [],
			getAllUsersError: false,
			user_id: "",
			successAlert: false,
			successMsg: "",
			error: false,
			errorMsg: "",
			user_name: "",
			user_email: "",
			user_phone: "",
			user_image: "",
			new_user_image: "",
			user_type: "",
			user_status: "InActive",
			active: false,
			deactive: false,
			user_password: "",
			user_confirmPass: "",
			errorName: "",
			errorEmail: "",
			errorPhone: "",
			errorPassword: "",
			errorConfirmPass: "",
			errorImage: "",
			errorType: "",
			errorStatus: "",
			currentPage: 1,
			total: 0,
			pageSize: 0,
		};
	}
	componentDidMount = async () => {
		const token = JSON.parse(localStorage.getItem("token"));
		this.setState({ token: token }, () => {
			if (this.state.token) {
				this.getAllUsers(1);
			} else {
				console.log("no token");
				this.props.history.push("/login");
			}
		});
	};
	getAllUsers = async (pageNo) => {
		this.setState({ isloading: true });
		try {
			let allUsers = await fetch(api_url + "get_list_users?page=" + pageNo, {
				method: "get",
				headers: {
					Authorization: `Bearer ${this.state.token}`,
				},
			});
			if (!allUsers.ok) {
				this.setState({ isloading: false, getallUsersError: true });
				throw Error("Error");
			} else {
				allUsers = await allUsers.json();
				this.setState({
					allUsers: allUsers.data.data,
					isloading: false,
					total: allUsers.data.total,
					pageSize: allUsers.data.per_page,
				});
				console.warn(allUsers);
			}
		} catch (err) {
			console.log("Error");
		}
	};
	addUSer = async () => {
		console.log(this.state.user_status);
		this.setState({ spinner: true });
		if (this.state.user_name) {
			this.setState({ errorName: "" });
		} else {
			this.setState({ errorName: "The Name field is required" });
		}
		if (this.state.user_email) {
			this.setState({ errorEmail: "" });
		} else {
			this.setState({ errorEmail: "The Email field is required" });
		}
		if (this.state.user_phone) {
			this.setState({ errorPhone: "" });
		} else {
			this.setState({ errorPhone: "The phone no field is required" });
		}
		if (this.state.user_password) {
			this.setState({ errorPassword: "" });
		} else {
			this.setState({ errorPassword: "The Password field is required" });
		}
		if (this.state.user_confirmPass) {
			this.setState({ errorConfirmPass: "" });
		} else {
			this.setState({
				errorConfirmPass: "The Confirm Password field is required",
			});
		}
		if (this.state.user_image) {
			this.setState({ errorImage: "" });
		} else {
			this.setState({ errorImage: "The user image field is required" });
		}
		if (
			this.state.errorName ||
			this.state.errorEmail ||
			this.state.errorPhone ||
			this.state.errorPassword ||
			this.state.errorConfirmPass ||
			this.state.errorImage
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.user_name);
			formdata.append("email", this.state.user_email);
			formdata.append("password", this.state.user_password);
			formdata.append("password_confirmation", this.state.user_confirmPass);
			formdata.append("phone_number", this.state.user_phone);
			formdata.append("user_image", this.state.user_image);
			formdata.append("user_status", this.state.user_status);

			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "registeruser", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: result.message,
							spinner: false,
						});
					} else {
						this.setState({
							addModal: false,
							successAlert: true,
							successMsg:
								this.props.t("User") + " " + this.props.t("Added Successfully"),
							spinner: false,
							user_name: "",
							user_email: "",
							user_phone: "",
							user_password: "",
							user_confirmPass: "",
							user_image: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getAllUsers();
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	getUserDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("user_id", this.state.user_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_user", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({ error: true, errorMsg: result.message });
				} else {
					this.setState({
						user_name: result.data[0].name,
						user_email: result.data[0].email,
						user_phone: result.data[0].phone_number,
						user_image: result.data[0].user_image,
						user_status:
							result.data[0].user_status == 1 ? "Active" : "InActive",
						user_type: result.data[0].user_type,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};
	updateUSer = async () => {
		this.setState({ spinner: true });
		if (this.state.user_name) {
			this.setState({ errorName: "" });
		} else {
			this.setState({ errorName: "The Name field is required" });
		}
		if (this.state.user_email) {
			this.setState({ errorEmail: "" });
		} else {
			this.setState({ errorEmail: "The Email field is required" });
		}
		if (this.state.user_phone) {
			this.setState({ errorPhone: "" });
		} else {
			this.setState({ errorPhone: "The phone no field is required" });
		}
		if (
			this.state.errorName ||
			this.state.errorEmail ||
			this.state.errorPhone
		) {
			console.log("all field are required");
			this.setState({ spinner: false });
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${this.state.token}`);

			var formdata = new FormData();
			formdata.append("name", this.state.user_name);
			formdata.append("email", this.state.user_email);
			formdata.append("phone_number", this.state.user_phone);
			formdata.append("user_image", this.state.new_user_image);
			formdata.append("user_id", this.state.user_id);
			formdata.append("user_status", this.state.user_status);
			formdata.append("password", "");
			formdata.append("password_confirmation", "");
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "updateuser", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						this.setState({
							error: true,
							errorMsg: result.message,
							spinner: false,
						});
					} else {
						this.setState({
							updateModal: false,
							successAlert: true,
							successMsg:
								this.props.t("User") + this.props.t("Updated Successfully"),
							spinner: false,
							user_name: "",
							user_email: "",
							user_phone: "",
							user_password: "",
							user_confirmPass: "",
							new_user_image: "",
						});
						setTimeout(() => {
							//hide success msg after 2 seconds
							this.setState({ successAlert: false, successMsg: "" });
						}, 2000);
						this.getAllUsers();
					}
				})
				.catch((error) => console.log("error", error));
		}
	};
	changeStatus = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);
		var formdata = new FormData();
		formdata.append("user_id", this.state.user_id);
		if (this.state.active) {
			formdata.append("user_status", "1");
		} else {
			formdata.append("user_status", "0");
		}

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "updatestatus", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: result.message,
						spinner: false,
					});
				} else {
					this.setState({
						active_deactiveModal: false,
						successAlert: true,
						successMsg:
							this.props.t("User") + this.props.t("Updated Successfully"),
						spinner: false,
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getAllUsers();
				}
			})
			.catch((error) => console.log("error", error));
	};
	deleteUSer = async () => {
		this.setState({ spinner: true });
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${this.state.token}`);

		var formdata = new FormData();
		formdata.append("user_id", this.state.user_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "deleteuser", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					this.setState({
						error: true,
						errorMsg: result.message,
						spinner: false,
					});
				} else {
					this.setState({
						spinner: false,
						deleteModal: false,
						successAlert: true,
						successMsg:
							this.props.t("User") + this.props.t("Deleted Successfully"),
					});
					setTimeout(() => {
						//hide success msg after 2 seconds
						this.setState({ successAlert: false, successMsg: "" });
					}, 2000);
					this.getAllUsers();
				}
			})
			.catch((error) => {
				console.log("error", error);
				this.setState({
					error: true,
					errorMsg: "Something Wrong",
					spinner: false,
				});
			});
	};
	setStatus = (e) => {
		if (e.target.checked) {
			this.setState({ user_status: "Active" }, () => {
				console.log(this.state.user_status);
			});
		} else {
			this.setState({ user_status: "InActive" }, () => {
				console.log(this.state.user_status);
			});
		}
	};
	loadFile = (event) => {
		this.setState({ new_user_image: event.target.files[0] });
		var image = document.getElementById("output");
		image.src = URL.createObjectURL(event.target.files[0]);
	};
	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							title={this.props.t("User Management")}
							breadcrumbItems={this.state.breadcrumbItems}
						/>

						<Row>
							<Col xs={12}>
								<Card>
									<CardBody>
										<Button
											color="success"
											className="mb-3 float-right"
											onClick={() => this.setState({ addModal: true })}
										>
											<i className="mdi mdi-account-plus-outline mr-3 font-size-18"></i>
											{this.props.t("Add User")}
										</Button>
										<div className="table-rep-plugin">
											<div
												className="table-responsive mb-0"
												data-pattern="priority-columns"
											>
												<Table
													id="tech-companies-1"
													striped
													bordered
													responsive
												>
													<thead>
														<tr>
															<th>{this.props.t("Id")}</th>
															<th>{this.props.t("Name")}</th>
															<th>{this.props.t("Email")}</th>
															<th>{this.props.t("Phone No")}</th>
															<th>{this.props.t("Image")}</th>
															<th>{this.props.t("User Type")}</th>
															<th>{this.props.t("Actions")}</th>
														</tr>
													</thead>
													<tbody>
														{this.state.isloading ? (
															<tr>
																<td colSpan={8}>
																	<center>
																		<div className="lds-ripple">
																			<div></div>
																			<div></div>
																		</div>
																	</center>
																</td>
															</tr>
														) : this.state.allUsers ? (
															this.state.allUsers.length > 0 ? (
																this.state.allUsers.map((n) => (
																	<tr key={n.id}>
																		<td>{n.id}</td>
																		<td>{n.name}</td>
																		<td>
																			{n.email.length > 25
																				? n.email.substr(0, 24) + "..."
																				: n.email}
																		</td>
																		<td>{n.phone_number}</td>
																		<td>
																			{n.user_image ? (
																				<img
																					src={n.user_image}
																					className="book-image-in-table"
																					alt="user image"
																				/>
																			) : (
																				<img
																					src={user}
																					className="book-image-in-table"
																					alt="user image"
																				/>
																			)}
																		</td>
																		<td>
																			{n.user_type == "user"
																				? this.props.t("Normal User")
																				: this.props.t("Admin")}
																		</td>
																		<td className="d-flex">
																			<i
																				className="mdi mdi-pencil font-size-18 text-primary cursor-pointer"
																				title="Edit"
																				onClick={(event) =>
																					this.setState(
																						{
																							user_id: event.currentTarget.id,
																							updateModal: true,
																						},
																						this.getUserDetail
																					)
																				}
																				id={n.id}
																			></i>
																			{n.user_status == 0 ? (
																				<i
																					className="mdi mdi-toggle-switch font-size-18 text-warning cursor-pointer"
																					title="Active"
																					onClick={(event) =>
																						this.setState({
																							user_id: event.currentTarget.id,
																							active_deactiveModal: true,
																							active: true,
																							deactive: false,
																						})
																					}
																					id={n.id}
																				></i>
																			) : (
																				<i
																					className="mdi mdi-toggle-switch-off font-size-18 text-warning cursor-pointer"
																					title="Deactive"
																					onClick={(event) =>
																						this.setState({
																							user_id: event.currentTarget.id,
																							active_deactiveModal: true,
																							active: false,
																							deactive: true,
																						})
																					}
																					id={n.id}
																				></i>
																			)}
																			<i
																				className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
																				title="Delete"
																				onClick={(event) =>
																					this.setState({
																						user_id: event.currentTarget.id,
																						deleteModal: true,
																					})
																				}
																				id={n.id}
																			></i>
																		</td>
																	</tr>
																))
															) : (
																<tr>
																	<td colSpan={8}>
																		<center>
																			<div>{this.props.t("No User Found")}</div>
																		</center>
																	</td>
																</tr>
															)
														) : (
															""
														)}
													</tbody>
												</Table>
												{this.state.total <= this.state.pageSize ? (
													""
												) : (
													<PaginationControl
														page={this.state.currentPage}
														between={3}
														total={this.state.total}
														limit={this.state.pageSize}
														changePage={(page) =>
															this.setState({ currentPage: page }, () => {
																this.getAllUsers(page);
															})
														}
														ellipsis={1}
													/>
												)}
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
						{/* Add modal */}
						<Modal
							isOpen={this.state.addModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>{this.props.t("Add User")}</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="name">{this.props.t("Name")}</Label>
										<Input
											id="name"
											type="text"
											value={this.state.user_name}
											onChange={(e) =>
												this.setState({ user_name: e.target.value })
											}
										/>
										{this.state.errorName ? (
											<span className="text-danger">
												{this.state.errorName}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="email">{this.props.t("Email")}</Label>
										<Input
											id="email"
											type="email"
											value={this.state.user_email}
											onChange={(e) =>
												this.setState({ user_email: e.target.value })
											}
										/>
										{this.state.errorEmail ? (
											<span className="text-danger">
												{this.state.errorEmail}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="pass">{this.props.t("Password")}</Label>
										<Input
											id="pass"
											type="password"
											value={this.state.user_password}
											onChange={(e) =>
												this.setState({ user_password: e.target.value })
											}
										/>
										{this.state.errorPassword ? (
											<span className="text-danger">
												{this.state.errorPassword}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="confirm">
											{this.props.t("Confirm Password")}
										</Label>
										<Input
											id="confirm"
											type="password"
											value={this.state.user_confirmPass}
											onChange={(e) =>
												this.setState({ user_confirmPass: e.target.value })
											}
										/>
										{this.state.errorConfirmPass ? (
											<span className="text-danger">
												{this.state.errorConfirmPass}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="phone">{this.props.t("Phone No")}</Label>
										<Input
											id="phone"
											type="text"
											value={this.state.user_phone}
											onChange={(e) =>
												this.setState({ user_phone: e.target.value })
											}
										/>
										{this.state.errorPhone ? (
											<span className="text-danger">
												{this.state.errorPhone}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup check inline>
										<Label check>{this.props.t("Status")}</Label>
										<Input
											type="checkbox"
											onChange={(e) => this.setStatus(e)}
										/>
									</FormGroup>
									<FormGroup>
										<Label for="image">{this.props.t("Image")}</Label>
										<Input
											id="image"
											type="file"
											onChange={(e) =>
												this.setState({ user_image: e.target.files[0] })
											}
										/>
										{this.state.errorImage ? (
											<span className="text-danger">
												{this.state.errorImage}
											</span>
										) : (
											""
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.addUSer}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Add User")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ addModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* update modal */}
						<Modal
							isOpen={this.state.updateModal}
							centered={true}
							scrollable={true}
						>
							<ModalHeader>
								{this.props.t("Update")} {this.props.t("User")}
							</ModalHeader>
							<ModalBody>
								<Form>
									<FormGroup>
										<Label for="name">{this.props.t("Name")}</Label>
										<Input
											id="name"
											type="text"
											value={this.state.user_name}
											onChange={(e) =>
												this.setState({ user_name: e.target.value })
											}
										/>
										{this.state.errorName ? (
											<span className="text-danger">
												{this.state.errorName}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="email">{this.props.t("Email")}</Label>
										<Input
											id="email"
											type="email"
											value={this.state.user_email}
											onChange={(e) =>
												this.setState({ user_email: e.target.value })
											}
										/>
										{this.state.errorEmail ? (
											<span className="text-danger">
												{this.state.errorEmail}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup>
										<Label for="phone">{this.props.t("Phone No")}</Label>
										<Input
											id="phone"
											type="text"
											value={this.state.user_phone}
											onChange={(e) =>
												this.setState({ user_phone: e.target.value })
											}
										/>
										{this.state.errorPhone ? (
											<span className="text-danger">
												{this.state.errorPhone}
											</span>
										) : (
											""
										)}
									</FormGroup>
									<FormGroup check inline>
										<Label check>{this.props.t("Status")}</Label>
										<Input
											type="checkbox"
											checked={
												this.state.user_status == "Active" ? true : false
											}
											onChange={(e) => this.setStatus(e)}
										/>
									</FormGroup>
									{/* <div className='d-flex justify-content-between'>
        <FormGroup>
          <Label for="image">{this.props.t('Image')}</Label>
          <Input id="image" type="file" onChange={(e)=>this.setState({new_user_image:e.target.files[0]})}/>
           {this.state.errorImage ? <span className='text-danger'>{this.state.errorImage}</span>:''}
        </FormGroup>
          {this.state.user_image ? <img src={this.state.user_image} alt='' className='update-book-image'/> :''}
        </div> */}
									<FormGroup>
										<Label for="file">{this.props.t("Image")}</Label>
										{this.state.user_image ? (
											<div className="position-relative">
												<div className="profile-pic">
													<label className="-label" for="file">
														<span className="mdi mdi-camera-outline"></span>
														<span>{this.props.t("Change Image")}</span>
													</label>
													<input
														id="file"
														type="file"
														onChange={(e) => this.loadFile(e)}
													/>
													<img
														src={this.state.user_image}
														alt="user"
														id="output"
														width="200"
													/>
												</div>
											</div>
										) : (
											<Input
												id="image"
												type="file"
												onChange={(e) =>
													this.setState({ new_user_image: e.target.files[0] })
												}
											/>
										)}
									</FormGroup>
								</Form>
								{this.state.error ? (
									<span className="text-danger">{this.state.errorMsg}</span>
								) : (
									""
								)}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.updateUSer}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Update")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ updateModal: false })}
									>
										{this.props.t("Cancel")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Delete modal */}
						<Modal isOpen={this.state.deleteModal} centered={true}>
							<ModalHeader>
								{this.props.t("Delete")} {this.props.t("User")}
							</ModalHeader>
							<ModalBody>{this.props.t("delete text")}</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.deleteUSer}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() => this.setState({ deleteModal: false })}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* Active / Deactive modal */}
						<Modal isOpen={this.state.active_deactiveModal} centered={true}>
							<ModalHeader>
								{this.state.active
									? this.props.t("Activate")
									: this.props.t("Deactivate")}{" "}
								{this.props.t("User")}
							</ModalHeader>
							<ModalBody>
								{this.state.active
									? this.props.t("Are you sure to activate this user?")
									: this.props.t("Are you sure to deactivate this user?")}
							</ModalBody>
							<ModalFooter className="row d-flex justify-content-between align-items-center">
								<Col>
									<Button
										color="primary"
										className="w-100"
										onClick={this.changeStatus}
									>
										{this.state.spinner ? (
											<Spinner type="border" color="light" size="sm" />
										) : (
											this.props.t("Yes")
										)}
									</Button>{" "}
								</Col>
								<Col>
									<Button
										color="secondary"
										className="w-100"
										onClick={() =>
											this.setState({ active_deactiveModal: false })
										}
									>
										{this.props.t("No")}
									</Button>
								</Col>
							</ModalFooter>
						</Modal>
						{/* success alert */}
						{this.state.successAlert ? (
							<Alert
								color="success"
								style={{
									position: "absolute",
									top: "50px",
									right: "25px",
									zIndex: "10000",
								}}
							>
								{this.state.successMsg}
							</Alert>
						) : (
							""
						)}
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default withNamespaces()(UserManagemnet);
