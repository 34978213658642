import React, {useState, useEffect} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert,
    Button,
    Form,
    Label,
    Input,
    FormGroup,
} from "reactstrap";
import "../Tables/datatables.scss";
import {withNamespaces} from "react-i18next";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {api_url} from "../../components/Common/Variables";
import Select from "react-select";

function Audio(props) {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {title: props.t("Dashboard"), link: "#"},
        {title: props.t("Audios Management"), link: "#"},
    ]);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [token, setToken] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [getallAudiosError, setGetAllAudiosError] = useState(false);
    const [allAudios, setAllAudios] = useState([]);
    const [audio_id, setAudioId] = useState("");
    const [allPoems, setAllPoems] = useState([]);
    const [getAllPoemsError, setGetAllPoemsError] = useState(false);
    const [poem_id, setPoemId] = useState("");
    const [poemError, setPoemError] = useState(false);
    const [allStanzas, setAllStanzas] = useState([]);
    const [stanza_id, setStanzaId] = useState("");
    const [stanzaError, setStanzaError] = useState("");
    const [getAllStanzasError, setGetAllStanzasError] = useState(false);
    const [audio, setAudio] = useState("");
    const [newAudio, setNewAudio] = useState("");
    const [title, setTitle] = useState("");
    const [audioError, setAudioError] = useState(false);
    const [audioTag, setAudioTag] = useState("");
    const [audioTagError, setAudioTagError] = useState(false);
    const [url, setUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [titleError, setTitleError] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [category_id, setCategoryId] = useState([]);
    const [cateError, setCateError] = useState("");
    const [selectedMulti, setSelectedMulti] = useState(null);
    const [getAllCateError, setGetAllCateError] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [poems, setPoems] = useState("");
    const [bookName, setBookName] = useState("");
    const [selectedBook, setSelectedBook] = useState("");
    const [selectedPoem, setSelectedPoem] = useState("");
    const [total , setTotal] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        setToken(token);

        if (token) {
            getAllAudios();
            getAllPoems();
            getAllStanzas();
            getAllCategories();
            getAllBooks();
        } else {
            console.log("no token");
            props.history.push("/login");
        }
    }, [token]);

    // Get Audio for the Selected Book
    useEffect(() => {
        getBookAudio();
    }, [selectedBook])

    // Get Audio for the Selected Poem
    useEffect(() => {
        getPoemAudio();
    }, [selectedPoem])

    // Get Audio for the Selected Category
    useEffect(() => {
        searchAllAudio();
    }, [category_id]);

    const handleMulti = (selectedMulti) => {
        setSelectedMulti(selectedMulti, () => {
            console.log(selectedMulti);
            selectedMulti.map((n) => {
                setCategoryId(
                    (prevCategoryIds) => [...prevCategoryIds, n.value],
                    () => {
                        console.log("id", category_id);
                    }
                );
            });
        });
    };

    const getAllAudios = async () => {
        try {
            let allAudios = await fetch(api_url + "get_list_audio", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allAudios.ok) {
                setIsLoading(false);
                setGetAllAudiosError(true);
                throw Error("Error");
            } else {
                allAudios = await allAudios.json();
                setAllAudios(allAudios.data);
                setIsLoading(false);
                console.warn("All Audios", allAudios);
                if(allAudios.data === null){
                    setTotal(0)
                }else{
                    setTotal(allAudios.data.length)
                }
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const searchAllAudio = async () => {
        try {
            let allAudios = await fetch(
                api_url + `get_list_audio?category_id=${category_id}`,
                {
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!allAudios.ok) {
                setIsLoading(false);
                setGetAllAudiosError(true);
                throw Error("Error");
            } else {
                allAudios = await allAudios.json();
                setAllAudios(allAudios.data);
                setIsLoading(false);
                console.log("Categories", allAudios)
                if(allAudios.data === null){
                    setTotal(0)
                }else{
                    setTotal(allAudios.data.length)
                }
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const getAllCategories = async () => {
        setIsLoading(true);
        try {
            let allCate = await fetch(api_url + "get_list_category", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allCate.ok) {
                setGetAllCateError(true);
                throw Error("Error");
            } else {
                allCate = await allCate.json();
                setAllCategories(allCate.data);
                console.warn("all categories", allCategories);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const getAllPoems = async () => {
        setIsLoading(true);
        try {
            let allPoems = await fetch(api_url + "get_all_poems?is_paginated=false", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allPoems.ok) {
                setIsLoading(false);
                setGetAllPoemsError(true);
                throw Error("Error");
            } else {
                allPoems = await allPoems.json();
                setAllPoems(allPoems.data);
                setIsLoading(false);
                console.warn(allPoems);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const getAllStanzas = async () => {
        try {
            let allStanzas = await fetch(
                api_url + "get_all_stanza?is_paginated=false",
                {
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!allStanzas.ok) {
                setIsLoading(false);
                setGetAllStanzasError(true);
                throw Error("Error");
            } else {
                allStanzas = await allStanzas.json();
                setIsLoading(false);
                setAllStanzas(allStanzas.data);
                console.warn(allStanzas);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const deleteAudio = async () => {
        setSpinner(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("audio_id", audio_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "deleteaudio", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    setError(true);
                    setErrorMsg("Something Wrong");
                    setSpinner(false);
                } else {
                    setSpinner(false);
                    setDeleteModal(false);
                    setSuccessAlert(true);
                    setSuccessMsg(props.t("Audio") + props.t("Deleted Successfully"));

                    setTimeout(() => {
                        setSuccessAlert(false);
                        setSuccessMsg("");
                    }, 2000);
                    getAllAudios();
                }
            })
            .catch((error) => {
                console.log("error", error);
                setError(true);
                setErrorMsg("Something Wrong");
                setSpinner(false);
            });
    };

    const addAudio = async () => {
        console.log(audio, url, poem_id, stanza_id);
        setSpinner(true);
        if (audio || url) {
            setAudioError(false);
        } else {
            setAudioError(true);
            setSpinner(false);
        }
        if (title) {
            setTitleError("");
        } else {
            setTitleError("The Audio Title field is required");
            setSpinner(false);
        }
        if (audioTag) {
            setAudioTagError(false);
        } else {
            setAudioTagError(true);
            setSpinner(false);
        }
        if (poem_id || stanza_id) {
            setPoemError(false);
        } else {
            setPoemError(true);

            setSpinner(false);
        }

        if (audioError || titleError || poemError || audioTagError) {
            console.log("all field are required");
            setSpinner(false);
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var formdata = new FormData();
            formdata.append("name", audio);
            formdata.append("youtubeurl", url);
            formdata.append("stanza_id", stanza_id);
            formdata.append("poem_id", poem_id);
            formdata.append("audio_tag", audioTag);
            formdata.append("audio_title", title);
            {
                category_id.map((n) => {
                    formdata.append("category_id[]", n);
                });
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "insertaudio", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        if (result.message === "The youtubeurl format is invalid.") {
                            setUrlError("The youtubeurl format is invalid.");
                            setSpinner(false);
                        } else {
                            setError(true);
                            setErrorMsg(result.message);
                            setSpinner(false);
                        }
                    } else {
                        setAddModal(false);
                        setSuccessAlert(true);
                        setSuccessMsg(props.t("Audio") + props.t("Added Successfully"));
                        setSpinner(false);
                        setAudio("");
                        setUrl("");
                        setPoemId("");
                        setStanzaId("");
                        setTitle("");

                        setTimeout(() => {
                            setSuccessAlert(false);
                            setSuccessMsg("");
                        }, 2000);
                        getAllAudios();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const getAudioDetail = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("audio_id", audio_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "detail_audio", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    setError(true);
                    setErrorMsg("Something Wrong");
                } else {
                    result.data.audio_cat.map((n) => {
                        setCategoryId([...category_id, n.category_id]);
                        setSelectedMulti([
                            ...selectedMulti,
                            {label: n.categories.name, value: n.categories.id},
                        ]);

                        console.log("category id", category_id);

                        setAudio(result.data.audio[0].name);
                        setUrl(result.data.audio[0].youtubeurl);
                        setPoemId(result.data.audio[0].poem_id);
                        setStanzaId(result.data.audio[0].stanza_id);
                        setAudioTag(result.data.audio[0].audio_tag);
                        setTitle(result.data.audio[0].audio_title);
                    });
                }
            })
            .catch((error) => console.log("error", error));
    };

    const updateAudio = async () => {
        console.log(newAudio, url, poem_id, stanza_id);
        setSpinner(true);
        if (url || newAudio) {
            setUrlError(false);
        } else {
            setSpinner(false);

            setUrlError(true);
        }
        if (poem_id || stanza_id) {
            setPoemError(false);
        } else {
            setSpinner(false);

            setPoemError(true);
        }
        if (audioTag) {
            setAudioTagError(false);
        } else {
            setAudioTagError(true);

            setSpinner(false);
        }
        if (title) {
            setTitleError("");
        } else {
            setTitleError("Audio Title field is required");
            setSpinner(false);
        }

        if (urlError || poemError || audioTagError || titleError) {
            console.log("all field are required");
            setSpinner(false);
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var formdata = new FormData();
            formdata.append("name", newAudio);
            formdata.append("youtubeurl", url);
            formdata.append("stanza_id", stanza_id);
            formdata.append("poem_id", poem_id);
            formdata.append("audio_id", audio_id);
            formdata.append("audio_tag", audioTag);
            formdata.append("audio_title", title);
            {
                category_id.map((n) => {
                    formdata.append("category_id[]", n);
                });
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "updateaudio", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        if (result.message === "The youtubeurl format is invalid.") {
                            setSpinner(false);
                            setUrlError("The youtubeurl format is invalid.");
                        } else {
                            setError(true);
                            setErrorMsg(result.message);
                            setSpinner(false);
                        }
                    } else {
                        setUpdateModal(false);
                        setSuccessAlert(true);
                        setSuccessMsg(props.t("Audio") + props.t("Updated Successfully"));
                        setSpinner(false);
                        setAudio("");
                        setUrl("");
                        setPoemId("");
                        setStanzaId("");
                        setTitle("");
                        setCategoryId([]);
                        setSelectedMulti([]);

                        setTimeout(() => {
                            //hide success msg after 2 seconds
                            setSuccessAlert(false);
                            setSuccessMsg("");
                        }, 2000);
                        getAllAudios();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const getAllBooks = async () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url + "get_all_books", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Books", result);
                setBookName(result.data);
            })
            .catch((error) => console.error(error));
    }

    const getBookAudio = async () => {
        console.log("s", selectedBook);
        setIsLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url + "get_list_audio?book=" + selectedBook, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Books Audio", result);
                setAllAudios(result.data);
                getBookPoems();
                setIsLoading(false);
                if(result.data === null){
                    setTotal(0)
                }else{
                    setTotal(result.data.length)
                }
            })
            .catch((error) => console.error(error));

    }

    const getPoemAudio = async () => {
        console.log("s", selectedBook)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url + `get_list_audio?book=${selectedBook}&poem=${selectedPoem}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Poem Audio", result);
                setAllAudios(result.data);
                if(result.data === null){
                    setTotal(0)
                }else{
                    setTotal(result.data.length)
                }
            })
            .catch((error) => console.error(error));

    }

    const getBookPoems = () => {
        setLoading(true);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("book_id", selectedBook);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(api_url + "search_books_poems", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Book Poems", result);
                setPoems(result.data);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }

    const handleBook = (selectedOption) => {
        setSelectedBook(selectedOption.value);
        setSelectedPoem("");
        console.log('Selected Book:', selectedOption.value);
    };

    const handlePoem = (selectedOption) => {
        setSelectedPoem(selectedOption.value);
        // Your logic here based on the selected option
        console.log('Selected Poem:', selectedOption.value);
    };

    const handleCategory = (selectedOption) => {
        setCategoryId(selectedOption.value);
        // Your logic here based on the selected option
        console.log('Selected Category:', selectedOption.value);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-between mx-1">
                        <Breadcrumbs
                            title={props.t("Audios Management")}
                            breadcrumbItems={breadcrumbItems}
                        />
                        <span style={{marginLeft: "0.2vw"}}>
                           {total === 0 && `${props.t("No Audio Found")}`}
                            {total === 1 && `${total}  ${props.t("Audio")}`}
                            {total > 1 && `${total}  ${props.t("Audios")}`}
                        </span>
                    </Row>
                    <Row>
                        <Col xs={12}>
                        <Card>
                                <CardBody>
                                    <Row>
                                        <Button
                                            color="success"
                                            className="mb-3 float-right"
                                            onClick={() => {
                                                setAddModal(true);
                                            }}
                                        >
                                            <i className="mdi mdi-music-note-plus mr-3 font-size-18"></i>
                                            {props.t("Add Audio")}
                                        </Button>
                                    </Row>
                                    <Row className="justify-content-between mx-1">
                                        <Label for="book">
                                            <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                {props.t("Book")}
                                                {"  "}
                                            </span>
                                            <Select
                                                id="book"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={selectedBook}
                                                onChange={handleBook}
                                                name="color"
                                                placeholder={props.t("All")}
                                                options={
                                                    [
                                                        {label: props.t("All"), value: ""},
                                                        ...(bookName && bookName.length > 0 ?
                                                            bookName.map((e) => ({label: e.name, value: e.id})) :
                                                            [])
                                                    ]
                                                }
                                                style={{width: "18vw"}}
                                            />
                                        </Label>
                                        <Label for="category">
                                            <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                 {props.t("Category")}
                                                {"  "}
                                             </span>
                                            <Select
                                                id="category"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={category_id}
                                                onChange={handleCategory}
                                                name="color"
                                                placeholder={props.t("All")}
                                                options={
                                                    [
                                                        ...(allCategories && allCategories.length > 0 ? allCategories.map((e) => ({
                                                                label: e.name,
                                                                value: e.id
                                                            })) :
                                                            [])
                                                    ]
                                                }
                                                style={{width: "18vw"}}
                                            />
                                        </Label>
                                    </Row>
                                    <Row className="justify-content-between mx-1">
                                        <Label for="poem">
                                             <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                 {props.t("Poem")}
                                                 {"  "}
                                             </span>
                                            {loading ? (
                                                selectedBook ?
                                                    <p>{props.t("Loading...")} </p> : <p>{props.t("Select Book")} </p>
                                            ) : (
                                                <Select
                                                    id="poem"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={selectedPoem}
                                                    onChange={handlePoem}
                                                    name="color"
                                                    placeholder={props.t("All")}
                                                    options={[
                                                        { label: props.t("All"), value: "" },
                                                        ...(poems.map((e) => ({
                                                            label: e.poem_name,
                                                            value: e.id,
                                                        })))
                                                    ]}
                                                    style={{ width: "18vw" }}
                                                />
                                            )}
                                        </Label>
                                    </Row>

                                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table id="tech-companies-1" striped bordered responsive>
                                                <thead>
                                                <tr>
                                                    <th>{props.t("Id")}</th>
                                                    <th>{props.t("Audio")}</th>
                                                    <th>{props.t("Tag")}</th>
                                                    <th>{props.t("URL")}</th>
                                                    <th>{props.t("Actions")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {isloading ? (
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <center>
                                                                <div className="lds-ripple">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                            </center>
                                                        </td>
                                                    </tr>
                                                ) : allAudios ? (
                                                    allAudios.length > 0 ? (
                                                        allAudios.map((n) => (
                                                            <tr>
                                                                <th>{n.id}</th>
                                                                <th>
                                                                    <audio src={n.youtubeurl} controls/>
                                                                </th>
                                                                <th>{n.audio_tag}</th>
                                                                <td>
                                                                    <a href={n.youtubeurl} target="_blank">
                                                                        {props.t("Open URL")}
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <i
                                                                        className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
                                                                        title="Edit"
                                                                        onClick={(event) => {
                                                                            setAudioId(event.currentTarget.id);
                                                                            setUpdateModal(true);
                                                                            getAudioDetail();
                                                                        }}
                                                                        id={n.id}
                                                                    ></i>
                                                                    <i
                                                                        className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
                                                                        title="Delete"
                                                                        onClick={(event) => {
                                                                            console.log(
                                                                                "Clicked:",
                                                                                event.currentTarget.id
                                                                            );
                                                                            setAudioId(event.currentTarget.id);
                                                                            setDeleteModal(true);
                                                                        }}
                                                                        id={n.id}
                                                                    ></i>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4}>
                                                                <center>
                                                                    <div>{props.t("No Audio Found")}</div>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                    )
                                                ) : (
                                                    ""
                                                )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {/* Addmodal */}
                    <Modal isOpen={addModal} centered={true} scrollable>
                        <ModalHeader>{props.t("Add Audio")}</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label for="audio">{props.t("Audio")}</Label>
                                    <Input
                                        id="audio"
                                        type="file"
                                        onChange={(e) => setAudio(e.target.files[0])}
                                        accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"
                                    />
                                    {audioError ? (
                                        <span className="text-danger">
											Either audio or url is Required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="url">{props.t("URL")}</Label>
                                    <Input
                                        id="url"
                                        type="url"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                    {/* {urlError ? <span className='text-danger'>{urlError}</span>:''} */}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tag">{props.t("Tag")}</Label>
                                    <Input
                                        id="tag"
                                        type="text"
                                        value={audioTag}
                                        onChange={(e) => setAudioTag(e.target.value)}
                                    />
                                    {audioTagError ? (
                                        <span className="text-danger">
											The Audio Tag field is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="title">{props.t("Title")}</Label>
                                    <Input
                                        id="title"
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    {titleError ? (
                                        <span className="text-danger">
											The Audio Title field is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="category">{props.t("Category")}</Label>
                                    <Select
                                        id="category"
                                        isMulti={true}
                                        onChange={handleMulti}
                                        options={allCategories.map((e) => ({
                                            label: e.name,
                                            value: e.id,
                                        }))}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="poem">{props.t("Poem")}</Label>
                                    <select
                                        id="poem"
                                        onChange={(e) => setPoemId(e.target.value)}
                                        className="form-control"
                                    >
                                        <option selected disabled>
                                            {props.t("Select a Poem")}
                                        </option>
                                        {allPoems ? (
                                            allPoems.length > 0 ? (
                                                allPoems.map((n) => (
                                                    <option value={n.id}>{n.poem_name}</option>
                                                ))
                                            ) : (
                                                <option>{props.t("No Poem Found")}</option>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </select>
                                    {poemError ? (
                                        <span className="text-danger">
											Either Poem id or Stanza id is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                {poem_id ? (
                                    <FormGroup>
                                        <Label for="stanza">{props.t("Stanza")}</Label>
                                        <select
                                            id="stanza"
                                            onChange={(e) => setStanzaId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option selected disabled>
                                                {props.t("select a stanza")}
                                            </option>
                                            {allStanzas ? (
                                                allStanzas.length > 0 ? (
                                                    allStanzas.map((n) =>
                                                        poem_id == n.poem_id ? (
                                                            <option value={n.id}>{n.full_stanza}</option>
                                                        ) : (
                                                            ""
                                                        )
                                                    )
                                                ) : (
                                                    <option>{props.t("No Stanza Found")}</option>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </select>
                                        {/* {stanzaError ? <span className='text-danger'>{stanzaError}</span>:''} */}
                                    </FormGroup>
                                ) : (
                                    ""
                                )}
                            </Form>
                            {error ? <span className="text-danger">{errorMsg}</span> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={addAudio}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Add Audio")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setAddModal(false)}
                                >
                                    {props.t("Cancel")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    {/* update modal */}
                    <Modal isOpen={updateModal} centered={true} scrollable>
                        <ModalHeader>
                            {props.t("Update")} {props.t("Audio")}
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label for="audio">{props.t("Audio")}</Label>
                                    <Input
                                        id="audio"
                                        type="file"
                                        onChange={(e) => setNewAudio(e.target.files[0])}
                                        accept=".mp3,.audio/mpeg,.mpga,.wav,.aac"
                                    />
                                    <audio src={audio} controls/>
                                    {/* {audioError ? <span className='text-danger'>Either audio or url is Required</span>:''} */}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="url">{props.t("URL")}</Label>
                                    <Input
                                        id="url"
                                        type="url"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                    {urlError ? (
                                        <span className="text-danger">
											Either audio or url is Required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tag">{props.t("Tag")}</Label>
                                    <Input
                                        id="tag"
                                        type="text"
                                        value={audioTag}
                                        onChange={(e) => setAudioTag(e.target.value)}
                                    />
                                    {audioTagError ? (
                                        <span className="text-danger">
											The audio tag field is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="title">{props.t("Title")}</Label>
                                    <Input
                                        id="title"
                                        type="text"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                    {titleError ? (
                                        <span className="text-danger">
											The Audio Title field is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="category">{props.t("Category")}</Label>
                                    <Select
                                        id="category"
                                        isMulti={true}
                                        onChange={handleMulti}
                                        value={selectedMulti}
                                        options={allCategories.map((e) => ({
                                            label: e.name,
                                            value: e.id,
                                        }))}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="poem">{props.t("Poem")}</Label>
                                    <select
                                        id="poem"
                                        onChange={(e) => setPoemId(e.target.value)}
                                        className="form-control"
                                    >
                                        <option selected disabled>
                                            {props.t("Select a Poem")}
                                        </option>
                                        {allPoems ? (
                                            allPoems.length > 0 ? (
                                                allPoems.map((n) => (
                                                    <option
                                                        value={n.id}
                                                        selected={poem_id === n.id ? true : false}
                                                    >
                                                        {n.poem_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>{props.t("No Poem Found")}</option>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </select>
                                    {poemError ? (
                                        <span className="text-danger">
											Either Poem id or Stanza id is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                {poem_id ? (
                                    <FormGroup>
                                        <Label for="stanza">{props.t("Stanza")}</Label>
                                        <select
                                            id="stanza"
                                            onChange={(e) => setStanzaId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option disabled>{props.t("select a stanza")}</option>
                                            {allStanzas ? (
                                                allStanzas.length > 0 ? (
                                                    allStanzas.map((n) =>
                                                        poem_id == n.poem_id ? (
                                                            <option
                                                                value={n.id}
                                                                selected={stanza_id === n.id ? true : false}
                                                            >
                                                                {n.full_stanza}
                                                            </option>
                                                        ) : (
                                                            ""
                                                        )
                                                    )
                                                ) : (
                                                    <option>{props.t("No Stanza Found")}</option>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </select>
                                        {/* {stanzaError ? <span className='text-danger'>{stanzaError}</span>:''} */}
                                    </FormGroup>
                                ) : (
                                    ""
                                )}
                            </Form>
                            {error ? <span className="text-danger">{errorMsg}</span> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={updateAudio}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Update")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setUpdateModal(false)}
                                >
                                    {props.t("Cancel")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    {/* Delete modal */}
                    <Modal isOpen={deleteModal} centered={true}>
                        <ModalHeader>
                            {props.t("Delete")} {props.t("Audio")}
                        </ModalHeader>
                        <ModalBody>
                            {props.t("delete text")}
                            {error ? <div className="text-danger">{errorMsg}</div> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={deleteAudio}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Yes")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setDeleteModal(false)}
                                >
                                    {props.t("No")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    {/* success alert */}
                    {successAlert ? (
                        <Alert
                            color="success"
                            style={{
                                position: "absolute",
                                top: "50px",
                                right: "25px",
                                zIndex: "10000",
                            }}
                        >
                            {successMsg}
                        </Alert>
                    ) : (
                        ""
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(Audio);
