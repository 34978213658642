import React, {Component} from 'react';
import {withNamespaces} from 'react-i18next';
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Spinner,
    Alert
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {api_url} from '../../components/Common/Variables';
import {Link} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PaginationControl} from 'react-bootstrap-pagination-control';

class BookDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t('Dashboard'), link: "#"},
                {title: this.props.t('Book Management'), link: "#"},
            ],
            token: '',
            isloading: false,
            book_id: this.props.location.state,
            open: '1',
            book_name: '',
            book_desc: '',
            book_poems: [],
            poem_name: '',
            poem_tagline: '',
            poem_desc: '',
            poem_full: '',
            poem_translation: '',
            poem_lang: 'ur',
            errorName: '',
            errorFull: '',
            errorLang: '',
            errorBook: '',
            successAlert: false,
            successMsg: '',
            error: false,
            errorMsg: '',
            currentPage: 1,
            total: 0,
            pageSize: 0
        }
    }

    componentDidMount = async () => {
        const token = JSON.parse(localStorage.getItem('token'))
        this.setState({token: token}, () => {
            if (this.state.token) {
                this.getBookDetail();
                this.getBookPoems(1);
            } else {
                console.log('no token')
                this.props.history.push('/login')
            }
        });
    }
    getBookDetail = async () => {
        this.setState({isloading: true})
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("book_id", this.state.book_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "detail_book", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === false) {
                    this.setState({error: true, errorMsg: 'Something Wrong', isloading: false,});
                } else {
                    this.setState({
                        book_name: result.data.name, book_desc: result.data.desc,
                        isloading: false,
                        // book_tagline:result.data.tag_line,
                        // book_publisher:result.data.publisher,book_publishDate:result.data.publication_date,
                        // book_image:result.data.book_image,book_lang:result.data.language
                    });
                }
            })
            .catch(error => console.log('error', error));
    }
    getBookPoems = async (pageNo) => {
        this.setState({isloading: true})
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("book_id", this.state.book_id);
        formdata.append("is_defult_pagination", 'yes');

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "search_books_poems?page=" + pageNo, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                this.setState({
                    book_poems: result.data.data, isloading: false,
                    total: result.data.total, pageSize: result.data.per_page
                });
            })
            .catch(error => console.log('error', error));
    }
    addPoem = async () => {
        this.setState({spinner: true})
        console.log('name', this.state.poem_name, 'tagline', this.state.poem_tagline, 'desc', this.state.poem_desc, 'full', this.state.poem_full, 'trans', this.state.poem_translation, 'lang', this.state.poem_lang)
        if (this.state.poem_name) {
            this.setState({errorName: ''})
        } else {
            this.setState({errorName: 'The Name field is required'})
        }
        if (this.state.poem_full) {
            this.setState({errorFull: ''})
        } else {
            this.setState({errorFull: 'The Full Poem field is required'})
        }
        if (this.state.poem_lang) {
            this.setState({errorLang: ''})
        } else {
            this.setState({errorLang: 'The Poem Language field is required'})
        }
        if (this.state.errorName || this.state.errorFull || this.state.errorLang) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("poem_name", this.state.poem_name);
            formdata.append("poem_tagline", this.state.poem_tagline);
            formdata.append("full_poem", this.state.poem_full);
            formdata.append("desc", this.state.poem_desc);
            formdata.append("translation", this.state.poem_translation);
            formdata.append("language", this.state.poem_lang);
            formdata.append("book_id", this.state.book_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "insert_poem", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            addModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Poem') + this.props.t('Added Successfully'),
                            spinner: false,
                            poem_name: '',
                            poem_tagline: '',
                            poem_desc: '',
                            poem_full: '',
                            poem_translation: '',
                            poem_lang: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.componentDidMount();
                    }
                })
                .catch(error => console.log('error', error));
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="page-content">

                    <Container fluid>

                        <Breadcrumbs title={this.props.t('Book Management')}
                                     breadcrumbItems={this.state.breadcrumbItems}/>

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.isloading ?
                                            <center>
                                                <div className="lds-ripple">
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </center>
                                            :
                                            <>
                                                <h1 className="title text-center">{this.state.book_name}</h1>
                                                <h6 className='text-center'>{this.state.book_desc}</h6>
                                                <br/>
                                                {this.state.book_poems ?
                                                    <>
                                                        <div className='d-flex justify-content-between'>
                                                            <h3>{this.props.t('Poems')}</h3>
                                                            <div>
                                                                <Button color="success" onClick={() => this.setState({
                                                                    addModal: true,
                                                                    poem_name: '',
                                                                    poem_desc: '',
                                                                    poem_tagline: '',
                                                                    poem_full: '',
                                                                    poem_translation: ''
                                                                })}>
                                                                    <i className="mdi mdi-pen-plus mr-3 font-size-18"></i>{this.props.t('Add Poem')}
                                                                </Button></div>

                                                        </div>
                                                        <ul className='book-poems-list'>
                                                            {this.state.book_poems.length > 0 ?
                                                                <Row>
                                                                    {this.state.book_poems.map((n) => (
                                                                        <Col md={6} lg={6}>
                                                                            <li key={n.id}><i
                                                                                className={n.marked_as_favourite_poem ? 'mdi mdi-heart mr-3 font-size-18' : 'mdi mdi-heart-outline mr-3 font-size-18'}></i><Link
                                                                                to={{
                                                                                    pathname: "/poemDetail/" + n.id,
                                                                                    state: n.id
                                                                                }}>{n.poem_name}</Link></li>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                                :
                                                                <li>
                                                                    {this.props.t('No Poem Found')}
                                                                </li>
                                                            }
                                                        </ul>
                                                        {this.state.total <= this.state.pageSize ?
                                                            ''
                                                            :
                                                            <PaginationControl
                                                                page={this.state.currentPage}
                                                                between={3}
                                                                total={this.state.total}
                                                                limit={this.state.pageSize}
                                                                changePage={(page) => this.setState({currentPage: page}, () => {
                                                                    this.getBookPoems(page)
                                                                })}
                                                                ellipsis={1}
                                                            />
                                                        }
                                                    </>
                                                    : ''}
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Add modal */}
                        <Modal isOpen={this.state.addModal} centered={true} scrollable={true}>
                            <ModalHeader>{this.props.t('Add Poem')}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="poemName">{this.props.t('Name')}</Label>
                                                <Input id="poemName" type="text" value={this.state.poem_name}
                                                       onChange={(e) => this.setState({poem_name: e.target.value})}/>
                                                {this.state.errorName ?
                                                    <span className='text-danger'>{this.state.errorName}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="language">{this.props.t('Language')}</Label>
                                                <select id="language"
                                                        onChange={(e) => this.setState({poem_lang: e.target.value})}
                                                        className='form-control'>
                                                    <option value="fa"
                                                            selected={this.state.poem_lang === 'fa' ? true : false}>{this.props.t('Persian')}</option>
                                                    <option value="ur"
                                                            selected={this.state.poem_lang === 'ur' ? true : false}>{this.props.t('Urdu')}</option>
                                                </select>
                                                {this.state.errorLang ?
                                                    <span className='text-danger'>{this.state.errorLang}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t('Description')}</Label>
                                        <Input id="desc" type="textarea" rows={3} value={this.state.poem_desc}
                                               onChange={(e) => this.setState({poem_desc: e.target.value})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="fullPoem">{this.props.t('Full Poem')}</Label>
                                        <Input id="fullPoem" type="textarea" rows={5} value={this.state.poem_full}
                                               onChange={(e) => this.setState({poem_full: e.target.value})}/>
                                        {this.state.errorFull ?
                                            <span className='text-danger'>{this.state.errorFull}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="translation">{this.props.t('Translation')}</Label>
                                        <Input id="translation" type="textarea" rows={5}
                                               value={this.state.poem_translation}
                                               onChange={(e) => this.setState({poem_translation: e.target.value})}/>
                                    </FormGroup>
                                </Form>
                                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className='w-100'
                                            onClick={this.addPoem}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Add Poem')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className='w-100'
                                            onClick={() => this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* success alert */}
                        {this.state.successAlert ?
                            <Alert color="success"
                                   style={{position: 'absolute', top: '50px', right: '25px', zIndex: '10000'}}>
                                {this.state.successMsg}
                            </Alert>
                            :
                            ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(BookDetail);