import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button,Spinner,Alert, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
class Words extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t("Dashboard"), link: "#" },
        { title: this.props.t("Words/Meaning Management"), link: "#" },
      ],
      updateModal:false,
      addModal:false,
      deleteModal:false, 
      token:'',
      isloading:false,
      spinner:false,
      allWords:[],
      getAllWordsError:false,
      word_id:'',
      successAlert:false,
      successMsg:'',
      error:false,
      errorMsg:'',
      word:'',
      meaning:'',
      type:'',
      typeError:'',
      id:'',
      errorID:'',
      errorWord:'',
      errorMeaning:'',
      allStanzas:[],
      allPoems:[]
    };
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
      if(this.state.token){
        this.getAllWords();
        this.getAllPoems();
        this.getAllStanzas();
      }else{
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getAllWords = async()=>{
    this.setState({isloading:true})
    try {
      let allWords = await fetch(api_url + "get_words_meanings_list", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allWords.ok) {
        this.setState({ isloading: false,getAllWordsError:true });
        throw Error('Error');
      } else {
        allWords = await allWords.json();
        this.setState({ allWords: allWords.data, isloading: false });
        console.warn(this.state.allWords);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  addNewWord = async()=>{
    this.setState({spinner:true})
    if(this.state.word){
      this.setState({errorWord:''})
    }else{
      this.setState({errorWord:'The Word field is required'})
    }
    if(this.state.meaning){
      this.setState({errorMeaning:''})
    }else{
      this.setState({errorMeaning:'The Meaning field is required'})
    }
    if(this.state.type){
      this.setState({typeError:''})
    }else{
      this.setState({typeError:'The Type field is required'})
    }
    if(this.state.id){
      this.setState({errorID:''})
    }else{
      this.setState({errorID:'This field is required'})
    }
    if(this.state.errorWord || this.state.errorMeaning || this.state.typeError || this.state.errorID){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("word", this.state.word);
formdata.append("meaning", this.state.meaning);
formdata.append("type", this.state.type);
formdata.append("id", this.state.id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insert_word_meaning", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addModal: false,
          successAlert: true,
          successMsg: this.props.t('Word/Meaning') + this.props.t('Added Successfully'),
          spinner:false,
          word:'',meaning:'',type:'',id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllWords();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteWord = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("wm_id", this.state.word_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"delete_word_meaning", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteModal: false,
        successAlert: true,
        successMsg: this.props.t('Word/Meaning') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getAllWords();
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  getWordDetail = async ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("wm_id", this.state.word_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_word_meaning", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            word:result.data.word,meaning:result.data.meaning, type:result.data.poem_id ? 'Poem' : result.data.stanza_id ? 'Stanza' : '',
            id:result.data.poem_id ? result.data.poem_id : result.data.stanza_id ? result.data.stanza_id : ''
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateWord = async () =>{
    this.setState({spinner:true})
    if(this.state.word){
      this.setState({errorWord:''})
    }else{
      this.setState({errorWord:'The Word field is required'})
    }
    if(this.state.meaning){
      this.setState({errorMeaning:''})
    }else{
      this.setState({errorMeaning:'The Meaning field is required'})
    }
    if(this.state.type){
      this.setState({typeError:''})
    }else{
      this.setState({typeError:'The Type field is required'})
    }
    if(this.state.id){
      this.setState({errorID:''})
    }else{
      this.setState({errorID:'This field is required'})
    }
    if(this.state.errorWord || this.state.errorMeaning || this.state.typeError || this.state.errorID){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("word", this.state.word);
formdata.append("meaning", this.state.meaning);
formdata.append("wm_id", this.state.word_id);
formdata.append("type", this.state.type);
formdata.append("id", this.state.id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"update_word_meaning", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateModal: false,
          successAlert: true,
          successMsg: this.props.t('Word/Meaning') + this.props.t('Updated Successfully'),
          spinner:false,
          word:'',meaning:'',type:'',id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllWords();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  getAllPoems = async () => {
    this.setState({ isloading: true })
    try {
      let allPoems = await fetch(api_url + "get_all_poems?is_paginated=false", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allPoems.ok) {
        this.setState({ isloading: false, getAllPoemsError: true });
        throw Error('Error');
      } else {
        allPoems = await allPoems.json();
        this.setState({ allPoems: allPoems.data, isloading: false });
        console.warn(this.state.allPoems);
      }
    } catch (err) {
      console.log('Error')
    }
  }
getAllStanzas = async () => {
  try {
    let allStanzas = await fetch(api_url + "get_all_stanza?is_paginated=false", {
      method: 'get',
      headers: {
        "Authorization": `Bearer ${this.state.token}`
      },
    });
    if (!allStanzas.ok) {
      this.setState({ isloading: false,getAllStanzasError:true });
      throw Error('Error');
    } else {
      allStanzas = await allStanzas.json();
      this.setState({ allStanzas: allStanzas.data, isloading: false });
      console.warn(this.state.allStanzas);
    }
  } catch (err) {
    console.log('Error')
  }
}
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t("Words/Meaning Management")} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="success" className="mb-3 float-right" onClick={()=>this.setState({addModal:true, word:'',meaning:'',type:'',id:''})}>
                      <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Word/Meaning')}
                    </Button>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" striped bordered responsive>
                          <thead>
                            <tr>
                              <th>{this.props.t('Id')}</th>
                              <th>{this.props.t('Word')}</th>
                              <th>{this.props.t('Meaning')}</th>
                              <th>{this.props.t('Actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.isloading ? 
                            <tr>
                              <td colSpan={4}>
                                <center>
                                  <div className="lds-ripple"><div></div><div></div></div>
                                </center>
                              </td>
                            </tr>
                            :
                            this.state.allWords ?
                            this.state.allWords.length > 0 ?
                            this.state.allWords.map((n)=>(
                              <tr>
                              <th>{n.id}</th>
                              <th>{n.word}</th>
                              <td>{n.meaning}</td>
                              <td>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ word_id: event.currentTarget.id,updateModal:true },this.getWordDetail)} id={n.id}
                                //  onClick={()=>this.setState({updateModal:true})}
                                ></i>
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ word_id: event.currentTarget.id,deleteModal:true })} id={n.id}
                                // onClick={()=>this.setState({deleteModal:true})}
                                ></i>
                              </td>
                            </tr>
                            ))
                            :
                            <tr>
                              <td colSpan={8}>
                                <center>
                                  <div>{this.props.t('No Word/Meaning Found')}</div>
                                </center>
                              </td>
                            </tr>
                            :
                            ''
                           
  }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
{/* Add modal */}
<Modal isOpen={this.state.addModal} centered={true}>
  <ModalHeader>{this.props.t('Add Word/Meaning')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Word">{this.props.t('Word')}</Label>
          <Input id="Word" type="text" value={this.state.word} onChange={(e)=>this.setState({word:e.target.value})}/>
          {this.state.errorWord ? <span className='text-danger'>{this.state.errorWord}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="meaning">{this.props.t('Meaning')}</Label>
          <Input id="meaning" type="text" value={this.state.meaning} onChange={(e)=>this.setState({meaning:e.target.value})}/>
           {this.state.errorMeaning ? <span className='text-danger'>{this.state.errorMeaning}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="type">{this.props.t('Type')}</Label>
        <select id="type" onChange={(e) => this.setState({ type: e.target.value })} className='form-control'>
          <option disabled selected>{this.props.t('Select')}</option>
                      <option value="Poem">{this.props.t('Poem')}</option>
                      <option value="Stanza">{this.props.t('Stanza')}</option>
                    </select>
           {this.state.typeError ? <span className='text-danger'>{this.state.typeError}</span>:''}
        </FormGroup>
        {this.state.type == 'Poem' ? 
         <FormGroup>
         <Label for="poem">{this.props.t('Poem')}</Label>
         <select id="poem" onChange={(e)=>this.setState({id:e.target.value})} className='form-control'>
           <option selected disabled>{this.props.t('Select a Poem')}</option>
         {this.state.allPoems ?
                           this.state.allPoems.length > 0 ?
                           this.state.allPoems.map((n)=>(
                            <option value={n.id}>{n.poem_name}</option>
                           ))
                           :
                           <option>{this.props.t('No Poem Found')}</option>
                           :
                           ''
                          
 }
         </select>
          {this.state.errorID ? <span className='text-danger'>{this.state.errorID}</span>:''}
       </FormGroup>
       :
       this.state.type == 'Stanza' ?
       <FormGroup>
          <Label for="stanza">{this.props.t('Stanza')}</Label>
          <select id="stanza" onChange={(e)=>this.setState({id:e.target.value})} className='form-control'>
            <option selected disabled>{this.props.t('select a stanza')}</option>
          {this.state.allStanzas ?
                            this.state.allStanzas.length > 0 ?
                            this.state.allStanzas.map((n)=>(
                             <option value={n.id}>{n.full_stanza}</option>
                            ))
                            :
                            <option>{this.props.t('No Stanza Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.errorID ? <span className='text-danger'>{this.state.errorID}</span>:''}
        </FormGroup>
        :''
       }
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.addNewWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Word/Meaning')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateModal} centered={true}>
<ModalHeader>{this.props.t('Update')} {this.props.t('Word/Meaning')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Word">{this.props.t('Word')}</Label>
          <Input id="Word" type="text" value={this.state.word} onChange={(e)=>this.setState({word:e.target.value})}/>
          {this.state.errorWord ? <span className='text-danger'>{this.state.errorWord}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="meaning">{this.props.t('Meaning')}</Label>
          <Input id="meaning" type="text" value={this.state.meaning} onChange={(e)=>this.setState({meaning:e.target.value})}/>
           {this.state.errorMeaning ? <span className='text-danger'>{this.state.errorMeaning}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="type">{this.props.t('Type')}</Label>
        <select id="type" onChange={(e) => this.setState({ type: e.target.value })} className='form-control'>
          <option disabled>{this.props.t('Select')}</option>
                      <option value="Poem" selected={this.state.type == 'Poem' ? true : false}>{this.props.t('Poem')}</option>
                      <option value="Stanza" selected={this.state.type == 'Stanza' ? true : false}>{this.props.t('Stanza')}</option>
                    </select>
           {this.state.typeError ? <span className='text-danger'>{this.state.typeError}</span>:''}
        </FormGroup>
        {this.state.type == 'Poem' ? 
         <FormGroup>
         <Label for="poem">{this.props.t('Poem')}</Label>
         <select id="poem" onChange={(e)=>this.setState({id:e.target.value})} className='form-control'>
           <option disabled>{this.props.t('Select a Poem')}</option>
         {this.state.allPoems ?
                           this.state.allPoems.length > 0 ?
                           this.state.allPoems.map((n)=>(
                            <option value={n.id} selected={this.state.id == n.id ? true : false}>{n.poem_name}</option>
                           ))
                           :
                           <option>{this.props.t('No Poem Found')}</option>
                           :
                           ''
                          
 }
         </select>
          {this.state.errorID ? <span className='text-danger'>{this.state.errorID}</span>:''}
       </FormGroup>
       :
       this.state.type == 'Stanza' ?
       <FormGroup>
          <Label for="stanza">{this.props.t('Stanza')}</Label>
          <select id="stanza" onChange={(e)=>this.setState({id:e.target.value})} className='form-control'>
            <option disabled>{this.props.t('select a stanza')}</option>
          {this.state.allStanzas ?
                            this.state.allStanzas.length > 0 ?
                            this.state.allStanzas.map((n)=>(
                             <option value={n.id} selected={this.state.id == n.id ? true : false}>{n.full_stanza}</option>
                            ))
                            :
                            <option>{this.props.t('No Stanza Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.errorID ? <span className='text-danger'>{this.state.errorID}</span>:''}
        </FormGroup>
        :''
       }
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.updateWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Word/Meaning')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className="w-100" onClick={this.deleteWord}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className="w-100" onClick={()=>this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* success alert */}
{this.state.successAlert ?
            <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
              {this.state.successMsg}
            </Alert>
            :
            ''
}
        </Container>
      </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Words);