import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class RevenueAnalytics extends Component {
	state = {
		series: [
			{
				name: "2020",
				type: "column",
				data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
			},
			{
				name: "2019",
				type: "line",
				data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16],
			},
		],
		options: {
			chart: {
				toolbar: {
					show: false,
				},
			},
			stroke: {
				width: [0, 3],
				curve: "smooth",
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "20%",
				},
			},
			dataLabels: {
				enabled: false,
			},

			legend: {
				show: false,
			},
			colors: ["#F2C938", "#1A5D1A"],
			labels: [
				"Jan",
				"Feb",
				"Mar",
				"Apr",
				"May",
				"Jun",
				"Jul",
				"Aug",
				"Sep",
				"Oct",
				"Nov",
				"Dec",
			],
		},
	};
	render() {
		return (
			<React.Fragment>
				<Card>
					<CardBody>
						<h4 className="card-title  text-center font-size-22 mb-4">
							استعمال کے عشاریے
						</h4>
						<div>
							<div id="line-column-chart" className="apex-charts" dir="ltr">
								<ReactApexChart
									options={this.state.options}
									series={this.state.series}
									type="line"
									height="280"
								/>
							</div>
						</div>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default RevenueAnalytics;