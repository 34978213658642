import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button,Spinner,Alert, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
          breadcrumbItems: [
            { title: this.props.t('Dashboard'), link: "#" },
            { title: this.props.t("Category Management"), link: "#" },
          ],
          updateModal:false,
          addModal:false,
          deleteModal:false, 
          token:'',
          isloading:false,
          spinner:false,
          allCategories:[],
          getAllCateError:false,
          category_id:'',
          successAlert:false,
          successMsg:'',
          error:false,
          errorMsg:'',
          name:'',
          errorName:'',
        };
      }
      componentDidMount = async()=>{
        const token = JSON.parse(localStorage.getItem('token'))
        this.setState({token:token},()=>{
          if(this.state.token){
            this.getAllCategories()
          }else{
            console.log('no token')
            this.props.history.push('/login')
          }
        });
      }
      getAllCategories = async()=>{
        this.setState({isloading:true})
        try {
          let allCate = await fetch(api_url + "get_list_category", {
            method: 'get',
            headers: {
              "Authorization": `Bearer ${this.state.token}`
            },
          });
          if (!allCate.ok) {
            this.setState({ isloading: false,getAllCateError:true });
            throw Error('Error');
          } else {
            allCate = await allCate.json();
            this.setState({ allCategories: allCate.data, isloading: false });
            console.warn(this.state.allCategories);
          }
        } catch (err) {
          console.log('Error')
        }
      }
      addCategory = async()=>{
        this.setState({spinner:true})
        if(this.state.name){
          this.setState({errorName:''})
        }else{
          this.setState({errorName:'The Name field is required'})
        }
       if(this.state.errorName){
          console.log('all field are required')
          this.setState({spinner:false})
        }else{
          var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);
      
      var formdata = new FormData();
    formdata.append("name", this.state.name);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(api_url+"insertcategory", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
          } else {
            this.setState({
              addModal: false,
              successAlert: true,
              successMsg: this.props.t('Category') + this.props.t('Added Successfully'),
              spinner:false,
              name:''
            });
            setTimeout(() => {//hide success msg after 2 seconds
              this.setState({ successAlert: false,successMsg:'' });
            }, 2000);
            this.getAllCategories();
          }
        })
        .catch(error => console.log('error', error));
        }
      }
      getCategoryDetail = async() =>{
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);
        
        var formdata = new FormData();
        formdata.append("cat_id", this.state.category_id);
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(api_url+"detail_category", requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            if (result.status === false) {
              this.setState({ error: true, errorMsg: 'Something Wrong' });
            } else {
              this.setState({
                name:result.data.name
              });
            }
          })
          .catch(error => console.log('error', error));
      }
      updateCategory = async() =>{
        this.setState({spinner:true})
        if(this.state.name){
          this.setState({errorName:''})
        }else{
          this.setState({errorName:'The Name field is required'})
        }
        if(this.state.errorName){
          console.log('all field are required')
          this.setState({spinner:false})
        }else{
          var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${this.state.token}`);
      
      var formdata = new FormData();
    formdata.append("name", this.state.name);
    formdata.append("cat_id", this.state.category_id);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(api_url+"updatecategory", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result.status === false) {
            this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
          } else {
            this.setState({
              updateModal: false,
              successAlert: true,
              successMsg: this.props.t('Category') + this.props.t('Updated Successfully'),
              spinner:false,
              name:'',category_id:''
            });
            setTimeout(() => {//hide success msg after 2 seconds
              this.setState({ successAlert: false,successMsg:'' });
            }, 2000);
            this.getAllCategories()
          }
        })
        .catch(error => console.log('error', error));
        }
      }
      deleteCategory = async()=>{
        this.setState({spinner:true})
        var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("cat_id", this.state.category_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"deletecategory", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
        } else {
          this.setState({
            spinner:false,
            deleteModal: false,
            successAlert: true,
            successMsg: this.props.t('Category') + this.props.t('Deleted Successfully')
          });
          setTimeout(() => {//hide success msg after 2 seconds
            this.setState({ successAlert: false,successMsg:'' });
          }, 2000);
          this.getAllCategories();
        }
      }
        )
      .catch(error => {
        console.log('error', error)
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      });
      }
      render() {
        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid>
    
                <Breadcrumbs title={this.props.t("Category Management")} breadcrumbItems={this.state.breadcrumbItems} />
    
                <Row>
                  <Col xs={12}>
                    <Card>
                      <CardBody>
                        <Button color="success" className="mb-3 float-right" onClick={()=>this.setState({addModal:true,name:''})}>
                          <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Category')}
                        </Button>
                        {/* <MDBDataTable responsive striped bordered data={data} /> */}
                        <div className="table-rep-plugin">
                          <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" striped bordered responsive>
                              <thead>
                                <tr>
                                  <th>{this.props.t('Id')}</th>
                                  <th>{this.props.t('Name')}</th>
                                  <th>{this.props.t('Actions')}</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.isloading ? 
                                <tr>
                                  <td colSpan={3}>
                                    <center>
                                      <div className="lds-ripple"><div></div><div></div></div>
                                    </center>
                                  </td>
                                </tr>
                                :
                                this.state.allCategories ?
                                this.state.allCategories.length > 0 ?
                                this.state.allCategories.map((n)=>(
                                  <tr>
                                  <th>{n.id}</th>
                                  <th>{n.name}</th>  
                                  <td>
                                    <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                      onClick={(event) => this.setState({ category_id: event.currentTarget.id,updateModal:true },this.getCategoryDetail)} id={n.id}
                                    ></i>
                                    <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                    onClick={(event) => this.setState({ category_id: event.currentTarget.id,deleteModal:true })} id={n.id}
                                    ></i>
                                  </td>
                                </tr>
                                ))
                                :
                                <tr>
                                  <td colSpan={8}>
                                    <center>
                                      <div>{this.props.t('No Category Found')}</div>
                                    </center>
                                  </td>
                                </tr>
                                :
                                ''
                               
      }
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
    {/* Add modal */}
    <Modal isOpen={this.state.addModal} centered={true}>
      <ModalHeader>{this.props.t('Add Category')}</ModalHeader>
        <ModalBody>
        <Form>
            <FormGroup>
              <Label for="Name">{this.props.t('Name')}</Label>
              <Input id="Name" type="text" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})}/>
              {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
            </FormGroup>
          </Form>
          {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
        </ModalBody>
        <ModalFooter className='row d-flex justify-content-between align-items-center'>
          <Col>
          <Button color="primary" className='w-100' onClick={this.addCategory}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Category')}</Button>{' '}
          </Col>
          <Col>
          <Button color="secondary" className='w-100' onClick={()=>this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
          </Col>
        </ModalFooter>
    </Modal>
    {/* update modal */}
    <Modal isOpen={this.state.updateModal} centered={true}>
      <ModalHeader>{this.props.t('Update')} {this.props.t('Category')}</ModalHeader>
        <ModalBody>
        <Form>
            <FormGroup>
              <Label for="Name">{this.props.t('Name')}</Label>
              <Input id="Name" type="text" value={this.state.name} onChange={(e)=>this.setState({name:e.target.value})}/>
              {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
            </FormGroup>
          </Form>
          {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
        </ModalBody>
        <ModalFooter className='row d-flex justify-content-between align-items-center'>
          <Col>
          <Button color="primary" className='w-100' onClick={this.updateCategory}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
          </Col>
          <Col>
          <Button color="secondary" className='w-100' onClick={()=>this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
          </Col>
        </ModalFooter>
    </Modal>
    {/* Delete modal */}
    <Modal isOpen={this.state.deleteModal} centered={true}>
      <ModalHeader>{this.props.t('Delete')} {this.props.t('Category')}</ModalHeader>
        <ModalBody>
          {this.props.t('delete text')}
          {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
        </ModalBody>
        <ModalFooter className='row d-flex justify-content-between align-items-center'>
          <Col>
          <Button color="primary" className='w-100' onClick={this.deleteCategory}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
          </Col>
          <Col>
          <Button color="secondary" className='w-100' onClick={()=>this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
          </Col>
        </ModalFooter>
    </Modal>
    {/* success alert */}
    {this.state.successAlert ?
                <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
                  {this.state.successMsg}
                </Alert>
                :
                ''
    }
            </Container>
          </div>
          </React.Fragment>
        );
      }
}

export default withNamespaces()(Category);