import React, {useState, useEffect} from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Spinner,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Form,
    Label,
    Input,
    FormGroup,
} from "reactstrap";
import "../Tables/datatables.scss";
import {withNamespaces} from "react-i18next";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {api_url} from "../../components/Common/Variables";
import Select from "react-select";

function Video(props) {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {title: props.t("Dashboard"), link: "#"},
        {title: props.t("Audios Management"), link: "#"},
    ]);
    const [updateModal, setUpdateModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [token, setToken] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [allPoems, setAllPoems] = useState([]);
    const [getAllPoemsError, setGetAllPoemsError] = useState(false);
    const [poem_id, setPoemId] = useState("");
    const [poemError, setPoemError] = useState(false);
    const [allStanzas, setAllStanzas] = useState([]);
    const [stanza_id, setStanzaId] = useState("");
    const [stanzaError, setStanzaError] = useState("");
    const [getAllStanzasError, setGetAllStanzasError] = useState(false);
    const [url, setUrl] = useState("");
    const [urlError, setUrlError] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [category_id, setCategoryId] = useState([]);
    const [cateError, setCateError] = useState("");
    const [selectedMulti, setSelectedMulti] = useState(null);
    const [getallVideosError, setGetallVideosError] = useState(false);
    const [allVideos, setAllVideos] = useState([]);
    const [video_id, setVideoId] = useState("");
    const [video, setVideo] = useState("");
    const [newVideo, setNewVideo] = useState("");
    const [videoError, setVideoError] = useState(false);
    const [videoTag, setVideoTag] = useState("");
    const [videoTagError, setVideoTagError] = useState(false);
    const [getAllCateError, setGetAllCateError] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [types, setTypes] = useState("");
    const [poems, setPoems] = useState("");
    const [bookName, setBookName] = useState("");
    const [selectedBook, setSelectedBook] = useState("");
    const [selectedPoem, setSelectedPoem] = useState("");
    const [total , setTotal] = useState("");
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const token = JSON.parse(localStorage.getItem("token"));
        setToken(token);
        if (token) {
            getallVideos();
            getAllPoems();
            getAllStanzas();
            getAllCategories();
            getAllBooks();
        } else {
            console.log("no token");
            props.history.push("/login");
        }
    }, [token]);

    useEffect(()=>{
        getBookVideo();
    },[selectedBook])

    // Get Audio for the Selected Poem
    useEffect(()=>{
        getPoemVideo();
    },[selectedPoem])

    useEffect(() => {
        searchAllVideos();
    }, [category_id]);
    const handleMulti = (selectedMulti) => {
        setSelectedMulti(selectedMulti, () => {
            console.log(selectedMulti);
            selectedMulti.map((n) => {
                setCategoryId(
                    (prevCategoryIds) => [...prevCategoryIds, n.value],
                    () => {
                        console.log("id", category_id);
                    }
                );
            });
        });
    };

    const getallVideos = async () => {
        try {
            let allVideos = await fetch(api_url + "get_list_video", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allVideos.ok) {
                setIsLoading(false);
                setGetallVideosError(true);
                throw Error("Error");
            } else {
                allVideos = await allVideos.json();
                setAllVideos(allVideos.data);
                setIsLoading(false);

                if (allVideos && Array.isArray(allVideos)) {
                    allVideos.forEach((n) => {
                        console.log(
                            n.name != null ? n.name : n.youtubeurl != null ? n.youtubeurl : ""
                        );
                    });
                } else {
                    console.error("Invalid or empty response from the API");
                }
                if(allVideos.data === null){
                    setTotal(0)
                }else{
                    setTotal(allVideos.data.length)
                }
                console.warn("All Video", allVideos);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const searchAllVideos = async () => {
        try {
            let allVideos = await fetch(
                api_url + `get_list_video?category_id=${category_id}`,
                {
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!allVideos.ok) {
                setIsLoading(false);
                setGetallVideosError(true);
                throw Error("Error");
            } else {
                allVideos = await allVideos.json();
                setAllVideos(allVideos.data);
                setIsLoading(false);
                if (allVideos && Array.isArray(allVideos)) {
                    allVideos.forEach((n) => {
                        console.log(
                            n.name != null ? n.name : n.youtubeurl != null ? n.youtubeurl : ""
                        );
                    });
                } else {
                    console.error("Invalid or empty response from the API");
                }
                if(allVideos.data === null){
                    setTotal(0)
                }else{
                    setTotal(allVideos.data.length)
                }
                console.warn(allVideos);
            }
        } catch (err) {
            console.log("Error", err);
        }
    };

    const getAllCategories = async () => {
        setIsLoading(true);
        try {
            let allCate = await fetch(api_url + "get_list_category", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allCate.ok) {
                setGetAllCateError(true);
                throw Error("Error");
            } else {
                allCate = await allCate.json();
                setAllCategories(allCate.data);
                console.warn("all categories", allCategories);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const getAllPoems = async () => {
        try {
            let allPoems = await fetch(api_url + "get_all_poems?is_paginated=false", {
                method: "get",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (!allPoems.ok) {
                setIsLoading(false);
                setGetAllPoemsError(true);
                throw Error("Error");
            } else {
                allPoems = await allPoems.json();
                setAllPoems(allPoems.data);
                setIsLoading(false);
                console.warn(allPoems);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const getAllStanzas = async () => {
        try {
            let allStanzas = await fetch(
                api_url + "get_all_stanza?is_paginated=false",
                {
                    method: "get",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!allStanzas.ok) {
                setIsLoading(false);
                setGetAllStanzasError(true);
                throw Error("Error");
            } else {
                allStanzas = await allStanzas.json();
                setAllStanzas(allStanzas.data);
                setIsLoading(false);
                console.warn(allStanzas);
            }
        } catch (err) {
            console.log("Error");
        }
    };

    const deleteVideo = async () => {
        setSpinner(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("video_id", video_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "deletevideo", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    setError(true);
                    setErrorMsg("Something Wrong");
                    setSpinner(false);
                } else {
                    setSpinner(false);
                    setDeleteModal(false);
                    setSuccessAlert(true);
                    setSuccessMsg(props.t("Video") + props.t("Deleted Successfully"));
                    setTimeout(() => {
                        setSuccessAlert(false);
                        setSuccessMsg("");
                    }, 2000);
                    getallVideos();
                }
            })
            .catch((error) => {
                console.log("error", error);
                setError(true);
                setErrorMsg("Something Wrong");
                setSpinner(false);
            });
    };

    const addVideo = async () => {
        console.log(video, url, poem_id, stanza_id);
        setSpinner(true);
        if (video || url) {
            setVideoError(false);
        } else {
            setVideoError(true);
            setSpinner(false);
        }
        if (videoTag) {
            setVideoTagError(false);
        } else {
            setVideoTagError(true);
            setSpinner(false);
        }
        if (poem_id || stanza_id) {
            setPoemError(false);
        } else {
            setPoemError(true);
            setSpinner(false);
        }
        if (videoError === true || poemError === true || videoTagError === true) {
            console.log("api not called");
            setSpinner(false);
        } else {
            console.log("api called");
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var formdata = new FormData();
            formdata.append("name", video);
            formdata.append("youtubeurl", url);
            formdata.append("stanza_id", stanza_id);
            formdata.append("poem_id", poem_id);
            formdata.append("video_tag", videoTag);
            {
                category_id.map((n) => {
                    formdata.append("category_id[]", n);
                });
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "insertvideo", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        if (result.message === "The youtubeurl format is invalid.") {
                            setUrlError("The youtubeurl format is invalid.");
                            setSpinner(false);
                        } else {
                            setSpinner(false);
                            setError(true);
                            setErrorMsg(result.message);
                        }
                    } else {
                        setSpinner(false);
                        setAddModal(false);
                        setSuccessAlert(true);
                        setSuccessMsg(props.t("Video") + props.t("Added Successfully"));
                        setVideo("");
                        setUrl("");
                        setPoemId("");
                        setStanzaId("");

                        setTimeout(() => {
                            setSuccessAlert(false);
                            setSuccessMsg("");
                        }, 2000);
                        getallVideos();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const getVideoDetail = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var formdata = new FormData();
        formdata.append("video_id", video_id);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };

        fetch(api_url + "detail_video", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                if (result.status === false) {
                    setError(true);
                    setErrorMsg("Something Wrong");
                } else {
                    setUrl(result.data[0].youtubeurl);
                    setPoemId(result.data[0].poem_id);
                    setStanzaId(result.data[0].stanza_id);
                    setVideoTag(result.data[0].video_tag);
                    setVideo(result.data[0].name);
                }
            })
            .catch((error) => console.log("error", error));
    };

    const updateVideo = async () => {
        console.log(newVideo, url, poem_id, stanza_id);
        setSpinner(true);
        if (video || url) {
            setVideoError(false);
        } else {
            setVideoError(true);
            setSpinner(false);
        }
        if (videoTag) {
            setVideoTagError(false);
        } else {
            setVideoTagError(true);
            setSpinner(false);
        }
        if (poem_id || stanza_id) {
            setPoemError(false);
        } else {
            setPoemError(true);
            setSpinner(false);
        }
        if (videoError === true || poemError === true || videoTagError === true) {
            console.log("all field are required");
            setSpinner(false);
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var formdata = new FormData();
            formdata.append("name", newVideo);
            formdata.append("youtubeurl", url);
            formdata.append("stanza_id", stanza_id);
            formdata.append("poem_id", poem_id);
            formdata.append("video_id", video_id);
            formdata.append("video_tag", videoTag);
            {
                category_id.map((n) => {
                    formdata.append("category_id[]", n);
                });
            }
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
            };

            fetch(api_url + "updatevideo", requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    if (result.status === false) {
                        if (result.message === "The youtubeurl format is invalid.") {
                            setSpinner(false);
                            setUrlError("The youtubeurl format is invalid.");
                        } else {
                            setError(true);
                            setErrorMsg(result.message);
                            setSpinner(false);
                        }
                    } else {
                        setSpinner(false);
                        setUpdateModal(false);
                        setSuccessAlert(true);
                        setSuccessMsg(props.t("Video") + props.t("Updated Successfully"));
                        setVideo("");
                        setUrl("");
                        setPoemId("");
                        setStanzaId("");
                        setNewVideo("");

                        setTimeout(() => {
                            setSuccessAlert(false);
                            setSuccessMsg("");
                        }, 2000);
                        getallVideos();
                    }
                })
                .catch((error) => console.log("error", error));
        }
    };

    const optionGroup = [
        {
            label: "Picnic",
            options: [
                {label: "Mustard", value: "Mustard"},
                {label: "Ketchup", value: "Ketchup"},
                {label: "Relish", value: "Relish"},
            ],
        },
        {
            label: "Camping",
            options: [
                {label: "Tent", value: "Tent"},
                {label: "Flashlight", value: "Flashlight"},
                {label: "Toilet Paper", value: "Toilet Paper"},
            ],
        },
    ];

    const convertToEmbedUrl = (watchUrl) => {
        const videoIdMatch = watchUrl.match(
            /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/
        );

        if (videoIdMatch && videoIdMatch[1]) {
            const videoId = videoIdMatch[1];
            return `https://www.youtube.com/embed/${videoId}`;
        } else {
            return null;
        }
    };

    const getAllBooks = async () =>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url  + "get_all_books", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Books", result);
                setBookName(result.data);
            })
            .catch((error) => console.error(error));
    }

    const getBookVideo = async () =>{
        console.log("s", selectedBook);
        setIsLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url + "get_list_video?book=" + selectedBook, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Books Videos", result);
                setAllVideos(result.data);
                getBookPoems();
                setIsLoading(false);
                if(result.data === null){
                    setTotal(0)
                }else{
                    setTotal(result.data.length)
                }
            })
            .catch((error) => console.error(error));

    }

    const getPoemVideo = async () =>{
        console.log("s", selectedBook)
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(api_url + `get_list_video?book=${selectedBook}&poem=${selectedPoem}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("Poem Videos", result);
                setAllVideos(result.data);
                if(result.data === null){
                    setTotal(0)
                }else{
                    setTotal(result.data.length)
                }
            })
            .catch((error) => console.error(error));

    }

    const getBookPoems = () =>{
        setLoading(true);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("book_id", selectedBook);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch(api_url + "search_books_poems", requestOptions)
            .then((response) =>response.json())
            .then((result) => {
                console.log("Book Poems",result);
                setPoems(result.data);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }
    const handleBook = (selectedOption) => {
        setSelectedBook(selectedOption.value);
        setSelectedPoem("");
        console.log('Selected Book:', selectedOption.value);
    };

    const handlePoem = (selectedOption) => {
        setSelectedPoem(selectedOption.value);
        // Your logic here based on the selected option
        console.log('Selected Poem:', selectedOption.value);
    };

    const handleCategory = (selectedOption) => {
        setCategoryId(selectedOption.value);
        // Your logic here based on the selected option
        console.log('Selected Category:', selectedOption.value);
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-between mx-1">
                        <Breadcrumbs
                            title={props.t("Videos Management")}
                            breadcrumbItems={breadcrumbItems}
                        />
                        <span style={{marginLeft: "0.2vw"}}>
                           {total === 0 && `${props.t("No Video Found")}`}
                            {total === 1 && `${total}  ${props.t("Audio")}`}
                            {total > 1 && `${total}  ${props.t("Audios")}`}
                        </span>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Button
                                            color="success"
                                            className="mb-3 float-right"
                                            onClick={() => setAddModal(true)}
                                        >
                                            <i className="mdi mdi-video-plus-outline mr-3 font-size-18"></i>
                                            {props.t("Add Video")}
                                        </Button>
                                    </Row>
                                    <Row className="justify-content-between mx-1">
                                        <Label for="book">
                                           <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                {props.t("Book")}
                                               {"  "}
                                            </span>
                                            <Select
                                                id="book"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={selectedBook}
                                                onChange={handleBook}
                                                name="color"
                                                placeholder={props.t("All")}
                                                options={
                                                    [
                                                        {label: props.t("All"), value: ""},
                                                        ...(bookName && bookName.length > 0 ?
                                                            bookName.map((e) => ({label: e.name, value: e.id})) :
                                                            [])
                                                    ]
                                                }
                                                style={{width: "18vw"}}
                                            />
                                        </Label>
                                        <Label for="category">
                                            <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                 {props.t("Category")}
                                                {"  "}
                                             </span>
                                            <Select
                                                id="category"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={category_id}
                                                onChange={handleCategory}
                                                name="color"
                                                placeholder={props.t("All")}
                                                options={
                                                    [
                                                        ...(allCategories && allCategories.length > 0 ? allCategories.map((e) => ({
                                                                label: e.name,
                                                                value: e.id
                                                            })) :
                                                            [])
                                                    ]
                                                }
                                                style={{width: "18vw"}}
                                            />
                                        </Label>
                                    </Row>
                                    <Row className="justify-content-between mx-1">
                                        <Label for="poem">
                                             <span style={{width: "3vw", paddingTop: "0.3vw"}}>
                                                 {props.t("Poem")}
                                                 {"  "}
                                             </span>
                                            {loading ? (
                                                selectedBook ?
                                                    <p>{props.t("Loading...")} </p> : <p>{props.t("Select Book")} </p>
                                            ) : (
                                                <Select
                                                    id="poem"
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    defaultValue={selectedPoem}
                                                    onChange={handlePoem}
                                                    name="color"
                                                    placeholder={props.t("All")}
                                                    options={[
                                                        { label: props.t("All"), value: "" },
                                                        ...(poems.map((e) => ({
                                                            label: e.poem_name,
                                                            value: e.id,
                                                        })))
                                                    ]}
                                                    style={{ width: "18vw" }}
                                                />
                                            )}
                                        </Label>
                                    </Row>

                                    {isloading ? (
                                        <center>
                                            <div className="lds-ripple">
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </center>
                                    ) : (
                                        <>
                                            {allVideos ? (
                                                allVideos.length > 0 ? (
                                                    <Row>
                                                        {allVideos.map((n) =>
                                                            n.youtubeurl != null ? (
                                                                <Col md={4} lg={4}>
                                                                    <div class="video-container">
                                                                        <iframe
                                                                            width="560"
                                                                            height="315"
                                                                            src={convertToEmbedUrl(n.youtubeurl)}
                                                                            title="YouTube video player"
                                                                            frameBorder="0"
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                                            referrerPolicy="strict-origin-when-cross-origin"
                                                                            loading="lazy"
                                                                            allowFullScreen
                                                                        ></iframe>
                                                                        <div class="overlay">
                                                                            <div class="tag-text">{n.video_tag}</div>
                                                                            <div className="edit-icons">
                                                                                <div className="edit">
                                                                                    <i
                                                                                        className="mdi mdi-pencil font-size-18 cursor-pointer"
                                                                                        title="Edit"
                                                                                        onClick={(event) => {
                                                                                            setVideoId(
                                                                                                event.currentTarget.id
                                                                                            );
                                                                                            setUpdateModal(true);
                                                                                            getVideoDetail();
                                                                                        }}
                                                                                        id={n.id}
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="delete">
                                                                                    <i
                                                                                        className="mdi mdi-trash-can font-size-18 cursor-pointer"
                                                                                        title="Delete"
                                                                                        onClick={(event) => {
                                                                                            setVideoId(
                                                                                                event.currentTarget.id
                                                                                            );
                                                                                            setDeleteModal(true);
                                                                                        }}
                                                                                        id={n.id}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            ) : (
                                                                <Col md={4} lg={4}>
                                                                    <div class="video-container">
                                                                        <iframe
                                                                            src={n.youtubeurl.split("/").pop()}
                                                                            frameborder="0"
                                                                            allowfullscreen
                                                                        ></iframe>
                                                                        <div class="overlay">
                                                                            <div class="tag-text">{n.video_tag}</div>
                                                                            <div className="edit-icons">
                                                                                <div className="edit">
                                                                                    <i
                                                                                        className="mdi mdi-pencil font-size-18 cursor-pointer"
                                                                                        title="Edit"
                                                                                        onClick={(event) => {
                                                                                            setVideoId(
                                                                                                event.currentTarget.id
                                                                                            );
                                                                                            setUpdateModal(true);
                                                                                            getVideoDetail();
                                                                                        }}
                                                                                        id={n.id}
                                                                                    ></i>
                                                                                </div>
                                                                                <div className="delete">
                                                                                    <i
                                                                                        className="mdi mdi-trash-can font-size-18 cursor-pointer"
                                                                                        title="Delete"
                                                                                        onClick={(event) => {
                                                                                            setVideoId(
                                                                                                event.currentTarget.id
                                                                                            );
                                                                                            setDeleteModal(true);
                                                                                        }}
                                                                                        id={n.id}
                                                                                    ></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )
                                                        )}
                                                    </Row>
                                                ) : (
                                                    <div>{props.t("No Video Found")}</div>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={addModal} centered={true} scrollable={true}>
                        <ModalHeader>{props.t("Add Video")}</ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label for="video">{props.t("Video")}</Label>
                                    <Input
                                        id="video"
                                        type="file"
                                        onChange={(e) => setVideo(e.target.files[0])}
                                        accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"
                                    />
                                    {videoError ? (
                                        <span className="text-danger">
											Either video or url is Required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="url">{props.t("URL")}</Label>
                                    <Input
                                        id="url"
                                        type="url"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tag">{props.t("Tag")}</Label>
                                    <Input
                                        id="tag"
                                        type="text"
                                        value={videoTag}
                                        onChange={(e) => setVideoTag(e.target.value)}
                                    />
                                    {videoTagError ? (
                                        <span className="text-danger">
											The Video Tag field is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="category">{props.t("Category")}</Label>
                                    <Select
                                        id="category"
                                        isMulti={true}
                                        onChange={handleMulti}
                                        options={allCategories.map((e) => ({
                                            label: e.name,
                                            value: e.id,
                                        }))}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="poem">{props.t("Poem")}</Label>
                                    <select
                                        id="poem"
                                        onChange={(e) => setPoemId(e.target.value)}
                                        className="form-control"
                                    >
                                        <option selected disabled>
                                            {props.t("Select a Poem")}
                                        </option>
                                        {allPoems ? (
                                            allPoems.length > 0 ? (
                                                allPoems.map((n) => (
                                                    <option value={n.id}>{n.poem_name}</option>
                                                ))
                                            ) : (
                                                <option>{props.t("No Poem Found")}</option>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </select>
                                    {poemError ? (
                                        <span className="text-danger">
											Either Poem id or Stanza id is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                {poem_id ? (
                                    <FormGroup>
                                        <Label for="stanza">{props.t("Stanza")}</Label>
                                        <select
                                            id="stanza"
                                            onChange={(e) => setStanzaId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option selected disabled>
                                                {props.t("select a stanza")}
                                            </option>
                                            {allStanzas ? (
                                                allStanzas.length > 0 ? (
                                                    allStanzas.map((n) =>
                                                        poem_id == n.poem_id ? (
                                                            <option value={n.id}>{n.full_stanza}</option>
                                                        ) : (
                                                            ""
                                                        )
                                                    )
                                                ) : (
                                                    <option>{props.t("No Stanza Found")}</option>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </select>
                                    </FormGroup>
                                ) : (
                                    ""
                                )}
                            </Form>
                            {error ? <span className="text-danger">{errorMsg}</span> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={addVideo}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Add Video")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setAddModal(false)}
                                >
                                    {props.t("Cancel")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={updateModal} centered={true} scrollable={true}>
                        <ModalHeader>
                            {props.t("Update")} {props.t("Video")}
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup>
                                    <Label for="video">{props.t("Video")}</Label>
                                    <Input
                                        id="video"
                                        type="file"
                                        onChange={(e) => setNewVideo(e.target.files[0])}
                                        accept=".mp4,.video/avi,.video/mpeg,.video/mp4,.video/quicktime"
                                    />
                                    <center>
                                        <video
                                            width="220"
                                            height="140"
                                            src={newVideo ? newVideo : video}
                                            controls
                                        />
                                    </center>
                                    {videoError ? (
                                        <span className="text-danger">
											Either video or url is Required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="url">{props.t("URL")}</Label>
                                    <Input
                                        id="url"
                                        type="url"
                                        value={url}
                                        onChange={(e) => setUrl(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tag">{props.t("Tag")}</Label>
                                    <Input
                                        id="tag"
                                        type="text"
                                        value={videoTag}
                                        onChange={(e) => setVideoTag(e.target.value)}
                                    />
                                    {videoTagError ? (
                                        <span className="text-danger">{videoTagError}</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="category">{props.t("Category")}</Label>
                                    <Select
                                        id="category"
                                        isMulti={true}
                                        onChange={handleMulti}
                                        options={allCategories.map((e) => ({
                                            label: e.name,
                                            value: e.id,
                                        }))}
                                        classNamePrefix="select2-selection"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="poem">{props.t("Poem")}</Label>
                                    <select
                                        id="poem"
                                        onChange={(e) => setPoemId(e.target.value)}
                                        className="form-control"
                                    >
                                        <option selected disabled>
                                            {props.t("Select a Poem")}
                                        </option>
                                        {allPoems ? (
                                            allPoems.length > 0 ? (
                                                allPoems.map((n) => (
                                                    <option
                                                        value={n.id}
                                                        selected={poem_id === n.id ? true : false}
                                                    >
                                                        {n.poem_name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option>{props.t("No Poem Found")}</option>
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </select>
                                    {poemError ? (
                                        <span className="text-danger">
											Either Poem id or Stanza id is required
										</span>
                                    ) : (
                                        ""
                                    )}
                                </FormGroup>
                                {poem_id ? (
                                    <FormGroup>
                                        <Label for="stanza">{props.t("Stanza")}</Label>
                                        <select
                                            id="stanza"
                                            onChange={(e) => setStanzaId(e.target.value)}
                                            className="form-control"
                                        >
                                            <option selected disabled>
                                                {props.t("select a stanza")}
                                            </option>
                                            {allStanzas ? (
                                                allStanzas.length > 0 ? (
                                                    allStanzas.map((n) =>
                                                        poem_id == n.poem_id ? (
                                                            <option
                                                                value={n.id}
                                                                selected={stanza_id === n.id ? true : false}
                                                            >
                                                                {n.full_stanza}
                                                            </option>
                                                        ) : (
                                                            ""
                                                        )
                                                    )
                                                ) : (
                                                    <option>{props.t("No Stanza Found")}</option>
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </select>
                                    </FormGroup>
                                ) : (
                                    ""
                                )}
                            </Form>
                            {error ? <span className="text-danger">{errorMsg}</span> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={updateVideo}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Update")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setUpdateModal(false)}
                                >
                                    {props.t("Cancel")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    {/* Delete modal */}
                    <Modal isOpen={deleteModal} centered={true}>
                        <ModalHeader>
                            {props.t("Delete")} {props.t("Video")}
                        </ModalHeader>
                        <ModalBody>
                            {props.t("delete text")}
                            {error ? <div className="text-danger">{errorMsg}</div> : ""}
                        </ModalBody>
                        <ModalFooter className="row d-flex justify-content-between align-items-center">
                            <Col>
                                <Button color="primary" className="w-100" onClick={deleteVideo}>
                                    {spinner ? (
                                        <Spinner type="border" color="light" size="sm"/>
                                    ) : (
                                        props.t("Yes")
                                    )}
                                </Button>{" "}
                            </Col>
                            <Col>
                                <Button
                                    color="secondary"
                                    className="w-100"
                                    onClick={() => setDeleteModal(false)}
                                >
                                    {props.t("No")}
                                </Button>
                            </Col>
                        </ModalFooter>
                    </Modal>
                    {successAlert ? (
                        <Alert
                            color="success"
                            style={{
                                position: "absolute",
                                top: "50px",
                                right: "25px",
                                zIndex: "10000",
                            }}
                        >
                            {successMsg}
                        </Alert>
                    ) : (
                        ""
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(Video);
