import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

// falgs
import usFlag from "../../../assets/images/flags/us.jpg";
import pak from "../../../assets/images/flags/pak.webp";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      lng : localStorage.getItem("lang"),
      flag : pak
    };
    this.toggle = this.toggle.bind(this);
    this.changeLanguageAction.bind(this);
  }
  componentDidMount = ()=>{
    console.log('on component load lang is ',this.state.lng)
      const lang = localStorage.getItem("lang");
      console.log('lang is ', lang)
      if(lang == "eng"){
        this.changeLanguageAction('eng')
        this.setState({lng: "English"})
      }else{
        this.changeLanguageAction('ur')
        this.setState({ lng: "اردو" });
      }
  }
  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  changeLanguageAction = (lng) => {
    
  //set the selected language to i18n
  i18n.changeLanguage(lng);
  document.body.dir = i18n.dir();
  window.localStorage.setItem("lang", lng);
  if(lng === "eng")
       this.setState({lng : "English", flag : usFlag });
  else if(lng === "ur")
       this.setState({lng : "اردو", flag : pak });
 }

  render() {

    return (
      <React.Fragment>
                        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-none d-sm-inline-block">
                            <DropdownToggle tag="button" className="btn header-item waves-effect">
                                <img className="" src={this.state.flag} alt="Header Language" height="16"/>{'  '}
                                <span className="align-middle">{this.state.lng}</span>
                            </DropdownToggle>

                            {/* <DropdownMenu right>

                                <DropdownItem active={this.state.lng === "English" ? true : false } href="" onClick={() => this.changeLanguageAction('eng')} className="notify-item">
                                    <img src={usFlag} alt="user" className="mr-1" height="12"/> <span className="align-middle">English</span>
                                </DropdownItem>

                                <DropdownItem href="" active={this.state.lng === "Urdu" ? true : false } onClick={() => this.changeLanguageAction('ur')} className=" notify-item">
                                    <img src={pak} alt="user" className="mr-1" height="12"/> <span className="align-middle">اردو</span>
                                </DropdownItem>
                            </DropdownMenu> */}
                        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(LanguageDropdown);
