import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter ,Button,Spinner,Alert, Form,Label, Input, FormGroup} from "reactstrap";
import "../Tables/datatables.scss";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import { api_url } from '../../components/Common/Variables';
class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: this.props.t('Dashboard'), link: "#" },
        { title: this.props.t("Options Management"), link: "#" },
      ],
      updateModal:false,
      addModal:false,
      deleteModal:false, 
      correctModal:false,
      token:'',
      isloading:false,
      spinner:false,
      allQuestions:[],
      getAllQuestionsError:false,
      question_id:'',
      correct_question_id:'',
      allOption:[],
      getAllOptionError:false,
      option_id:'',
      successAlert:false,
      successMsg:'',
      error:false,
      errorMsg:'',
      opt_name:'',
      opt_desc:'',
      opt_correct:0,
      errorDesc:'',
      errorName:'',
      errorQuestion:'',
      errorCorrect:'',
      errorQuestionCorrect:''
    };
  }
  componentDidMount = async()=>{
    const token = JSON.parse(localStorage.getItem('token'))
    this.setState({token:token},()=>{
      if(this.state.token){
        this.getAllQuestion();
        this.getAllOptions()
      }else{
        console.log('no token')
        this.props.history.push('/login')
      }
    });
  }
  getAllOptions = async()=>{
    this.setState({isloading:true})
    try {
      let allOption = await fetch(api_url + "get_list_options", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allOption.ok) {
        this.setState({ isloading: false,getAllOptionError:true });
        throw Error('Error');
      } else {
        allOption = await allOption.json();
        this.setState({ allOption: allOption.data, isloading: false });
        console.warn(this.state.allOption);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  getAllQuestion = async()=>{
    this.setState({isloading:true})
    try {
      let allQuestion = await fetch(api_url + "get_list_questions", {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${this.state.token}`
        },
      });
      if (!allQuestion.ok) {
        this.setState({ isloading: false,getAllQuestionsError:true });
        throw Error('Error');
      } else {
        allQuestion = await allQuestion.json();
        this.setState({ allQuestions: allQuestion.data, isloading: false });
        console.warn(this.state.allQuestions);
      }
    } catch (err) {
      console.log('Error')
    }
  }
  addOption = async()=>{
    this.setState({spinner:true})
    if(this.state.opt_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.opt_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    // if(this.state.opt_correct){
    //   this.setState({errorCorrect:''})
    // }else{
    //   this.setState({errorCorrect:'The Correct option field is required'})
    // }
    if(this.state.question_id){
      this.setState({errorQuestion:''})
    }else{
      this.setState({errorQuestion:'The Question id field is required'})
    }
    // if(this.state.errorName || this.state.errorDesc || this.state.errorCorrect || this.state.errorQuestion){
    if(this.state.errorName || this.state.errorDesc || this.state.errorQuestion){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("opt_name", this.state.opt_name);
formdata.append("decs", this.state.opt_desc);
formdata.append("correct_ans", this.state.opt_correct);
formdata.append("question_id", this.state.question_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"insertOption", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          addModal: false,
          successAlert: true,
          successMsg: this.props.t('Option') + this.props.t('Added Successfully'),
          spinner:false,
          opt_name:'',opt_desc:'',opt_correct:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllOptions();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  getOptionDetail = async() =>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${this.state.token}`);
    
    var formdata = new FormData();
    formdata.append("option_id", this.state.option_id);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(api_url+"detail_option", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.status === false) {
          this.setState({ error: true, errorMsg: 'Something Wrong' });
        } else {
          this.setState({
            opt_name:result.data.opt_name,opt_desc:result.data.decs,question_id:result.data.question_id,opt_correct:result.data.correct_ans
          });
        }
      })
      .catch(error => console.log('error', error));
  }
  updateOption = async() =>{
    this.setState({spinner:true})
    if(this.state.opt_name){
      this.setState({errorName:''})
    }else{
      this.setState({errorName:'The Name field is required'})
    }
    if(this.state.opt_desc){
      this.setState({errorDesc:''})
    }else{
      this.setState({errordesc:'The Description field is required'})
    }
    // if(this.state.opt_correct){
    //   this.setState({errorCorrect:''})
    // }else{
    //   this.setState({errorCorrect:'The Correct Answer field is required'})
    // }
    if(this.state.question_id){
      this.setState({errorQuestion:''})
    }else{
      this.setState({errorQuestion:'The Question id field is required'})
    }
    // if(this.state.errorName || this.state.errorDesc || this.state.errorCorrect || this.state.errorQuestion){
    if(this.state.errorName || this.state.errorDesc || this.state.errorQuestion){
      console.log('all field are required')
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("opt_name", this.state.opt_name);
formdata.append("decs", this.state.opt_desc);
formdata.append("correct_ans", this.state.opt_correct);
formdata.append("question_id", this.state.question_id);
formdata.append("option_id", this.state.option_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"updateOption", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          updateModal: false,
          successAlert: true,
          successMsg: this.props.t('Option') + this.props.t('Updated Successfully'),
          spinner:false,
          opt_name:'',opt_desc:'',opt_correct:'',option_id:'',question_id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllOptions();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  deleteOption = async()=>{
    this.setState({spinner:true})
    var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${this.state.token}`);

var formdata = new FormData();
formdata.append("option_id", this.state.option_id);

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

fetch(api_url+"deleteOption", requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result)
    if (result.status === false) {
      this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
    } else {
      this.setState({
        spinner:false,
        deleteModal: false,
        successAlert: true,
        successMsg: this.props.t('Option') + this.props.t('Deleted Successfully')
      });
      setTimeout(() => {//hide success msg after 2 seconds
        this.setState({ successAlert: false,successMsg:'' });
      }, 2000);
      this.getAllOptions();
    }
  }
    )
  .catch(error => {
    console.log('error', error)
    this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
  });
  }
  setCorrectAnswer = async(e)=>{
    if (e.target.checked) {
      this.setState({opt_correct:1},()=>{console.log(this.state.opt_correct)})
    }else{
      this.setState({opt_correct:0},()=>{console.log(this.state.opt_correct)})
    }
  }
  markCorrect = async() =>{
    console.log(this.state.option_id, this.state.correct_question_id)
    this.setState({spinner:true})
    if(this.state.correct_question_id){
      this.setState({errorQuestionCorrect:''})
    }else{
      this.setState({errorQuestionCorrect:'The Question field is required'})
    }
    if(this.state.errorQuestionCorrect){
      this.setState({spinner:false})
    }else{
      var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${this.state.token}`);
  
  var formdata = new FormData();
formdata.append("correct_ans", 1);
formdata.append("question_id", this.state.correct_question_id);
formdata.append("option_id", this.state.option_id);
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(api_url+"markcorectanswer", requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result.status === false) {
        this.setState({ error: true, errorMsg: 'Something Wrong',spinner:false });
      } else {
        this.setState({
          correctModal: false,
          successAlert: true,
          successMsg: this.props.t('Marked as Correct Answer successfully'),
          spinner:false,
          opt_correct:'',option_id:'',correct_question_id:''
        });
        setTimeout(() => {//hide success msg after 2 seconds
          this.setState({ successAlert: false,successMsg:'' });
        }, 2000);
        this.getAllOptions();
      }
    })
    .catch(error => console.log('error', error));
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>

            <Breadcrumbs title={this.props.t("Options Management")} breadcrumbItems={this.state.breadcrumbItems} />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Button color="success" className="mb-3 float-right" onClick={()=>this.setState({addModal:true,opt_name:'',opt_desc:'',opt_correct:'',option_id:'',question_id:''})}>
                      <i className="mdi mdi-plus-outline mr-3 font-size-18"></i>{this.props.t('Add Option')}
                    </Button>
                    {/* <MDBDataTable responsive striped bordered data={data} /> */}
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" striped bordered responsive>
                          <thead>
                            <tr>
                              <th>{this.props.t('Id')}</th>
                              <th>{this.props.t('Name')}</th>
                              <th>{this.props.t('Description')}</th>
                              <th>{this.props.t('Correct Answer')}</th>
                              <th>{this.props.t('Actions')}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.state.isloading ? 
                            <tr>
                              <td colSpan={5}>
                                <center>
                                  <div className="lds-ripple"><div></div><div></div></div>
                                </center>
                              </td>
                            </tr>
                            :
                            this.state.allOption ?
                            this.state.allOption.length > 0 ?
                            this.state.allOption.map((n)=>(
                              <tr>
                              <th>{n.id}</th>
                              <th>{n.opt_name}</th>
                              <td>{n.decs}</td>
                              <td>
                                {n.correct_ans === 1 ? <i className='mdi mdi-check-box-outline font-size-18 text-success'></i> : n.correct_ans === 0 ? <i className='mdi mdi-close-box-outline font-size-18 text-danger'></i> : ''}
                              </td>   
                              <td>
                                <i className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer" title='Edit'
                                  onClick={(event) => this.setState({ option_id: event.currentTarget.id,updateModal:true },this.getOptionDetail)} id={n.id}
                                //  onClick={()=>this.setState({updateModal:true})}
                                ></i>
                                {n.correct_ans === 0 ?
                                <i className="mdi mdi-check-box-outline mr-3 font-size-18 text-warning cursor-pointer" title='Mark as Complete' value={n.question_id}
                                onClick={(event) => this.setState({ option_id: event.currentTarget.id,correctModal:true,correct_question_id:event.target.value })} id={n.id}
                                // onClick={()=>this.setState({deleteModal:true})}
                                ></i>
                                :''}
                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer" title='Delete' 
                                onClick={(event) => this.setState({ option_id: event.currentTarget.id,deleteModal:true })} id={n.id}
                                // onClick={()=>this.setState({deleteModal:true})}
                                ></i>
                              </td>
                            </tr>
                            ))
                            :
                            <tr>
                              <td colSpan={8}>
                                <center>
                                  <div>{this.props.t('No Option Found')}</div>
                                </center>
                              </td>
                            </tr>
                            :
                            ''
                           
  }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
{/* Add modal */}
<Modal isOpen={this.state.addModal} centered={true}>
  <ModalHeader>{this.props.t('Add Option')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.ques_name} onChange={(e)=>this.setState({ques_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.ques_desc} onChange={(e)=>this.setState({ques_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup> 
        <FormGroup check className="d-flex">
          <Label check> {this.props.t('Correct Answer')}</Label>
          <Input type="checkbox" onChange={(e)=>this.setCorrectAnswer(e)} checked={this.state.opt_correct === 1 ? true : false}/>
          {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="question">{this.props.t('Question')}</Label>
          <select id="question" onChange={(e)=>this.setState({question_id:e.target.value})} className='form-control'>
            <option selected disabled>Select a Question</option>
          {this.state.allQuestions ?
                            this.state.allQuestions.length > 0 ?
                            this.state.allQuestions.map((n)=>(
                             <option value={n.id}>{n.q_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Question Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.errorQuiz ? <span className='text-danger'>{this.state.errorQuiz}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.addOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Add Option')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* update modal */}
<Modal isOpen={this.state.updateModal} centered={true}>
  <ModalHeader>{this.props.t('Update')} {this.props.t('Option')}</ModalHeader>
    <ModalBody>
    <Form>
        <FormGroup>
          <Label for="Name">{this.props.t('Name')}</Label>
          <Input id="Name" type="text" value={this.state.opt_name} onChange={(e)=>this.setState({opt_name:e.target.value})}/>
          {this.state.errorName ? <span className='text-danger'>{this.state.errorName}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="desc">{this.props.t('Description')}</Label>
          <Input id="desc" type="textarea" rows={3} value={this.state.opt_desc} onChange={(e)=>this.setState({opt_desc:e.target.value})}/>
           {this.state.errorDesc ? <span className='text-danger'>{this.state.errorDesc}</span>:''}
        </FormGroup>
        {/* <FormGroup>
          <Label for="correct">{this.props.t('Correct Answer')}</Label>
          <Input id="correct" type="text" value={this.state.opt_correct} onChange={(e)=>this.setState({opt_correct:e.target.value})}/>
           {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup> */}
        <FormGroup check className="d-flex">
          <Label check> {this.props.t('Correct Answer')}</Label>
          <Input type="checkbox" onChange={(e)=>this.setCorrectAnswer(e)} checked={this.state.opt_correct === 1 ? true : false}/>
          {this.state.errorCorrect ? <span className='text-danger'>{this.state.errorCorrect}</span>:''}
        </FormGroup>
        <FormGroup>
          <Label for="question">{this.props.t('Question')}</Label>
          <select id="question" onChange={(e)=>this.setState({question_id:e.target.value})} className='form-control'>
            {/* <option selected disabled>Select a Question</option> */}
          {this.state.allQuestions ?
                            this.state.allQuestions.length > 0 ?
                            this.state.allQuestions.map((n)=>(
                             <option value={n.id} selected={n.id === this.state.question_id}>{n.q_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Question Found')}</option>
                            :
                            ''
                           
  }
          </select>
           {this.state.errorQuiz ? <span className='text-danger'>{this.state.errorQuiz}</span>:''}
        </FormGroup>
      </Form>
      {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.updateOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Update')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Delete modal */}
<Modal isOpen={this.state.deleteModal} centered={true}>
  <ModalHeader>{this.props.t('Delete')} {this.props.t('Option')}</ModalHeader>
    <ModalBody>
      {this.props.t('delete text')}
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.deleteOption}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Yes')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* Correct modal */}
<Modal isOpen={this.state.correctModal} centered={true}>
  <ModalHeader>{this.props.t('Correct Answer')}</ModalHeader>
    <ModalBody>
    {this.props.t('Mark as Correct Answer')}
      <FormGroup>
          <Label for="question">{this.props.t('Question')}</Label>
          <select id="question" onChange={(e)=>this.setState({correct_question_id:e.target.value})} className='form-control'>
            <option selected disabled>{this.props.t('Select a Question')}</option>
          {this.state.allQuestions ?
                            this.state.allQuestions.length > 0 ?
                            this.state.allQuestions.map((n)=>(
                             <option value={n.id}>{n.q_name}</option>
                            ))
                            :
                            <option>{this.props.t('No Question Found')}</option>
                            :
                            ''
                           
  }
          </select>
          {this.state.errorQuestionCorrect ? <span className='text-danger'>{this.state.errorQuestionCorrect}</span>:''}
        </FormGroup>
      {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
    </ModalBody>
    <ModalFooter className='row d-flex justify-content-between align-items-center'>
      <Col>
      <Button color="primary" className='w-100' onClick={this.markCorrect}>{this.state.spinner ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Mark as Correct Answer')}</Button>{' '}
      </Col>
      <Col>
      <Button color="secondary" className='w-100' onClick={()=>this.setState({correctModal: false})}>{this.props.t('No')}</Button>
      </Col>
    </ModalFooter>
</Modal>
{/* success alert */}
{this.state.successAlert ?
            <Alert color="success" style={{ position: 'absolute', top: '50px', right: '25px', zIndex: '10000' }}>
              {this.state.successMsg}
            </Alert>
            :
            ''
}
        </Container>
      </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Option);