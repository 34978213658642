import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Container,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Alert,
    Button,
    Form,
    Label,
    Input,
    FormGroup
} from "reactstrap";
import "../Tables/datatables.scss";
import {Link} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {api_url} from '../../components/Common/Variables';
import 'bootstrap/dist/css/bootstrap.min.css';
import {PaginationControl} from 'react-bootstrap-pagination-control';

class Poem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                {title: this.props.t('Dashboard'), link: "#"},
                {title: this.props.t('Poems Management'), link: "#"},
            ],
            updateModal: false,
            addModal: false,
            deleteModal: false,
            token: '',
            isloading: false,
            spinner: false,
            allBooks: [],
            getAllBooksError: false,
            book_id: '',
            allPoems: [],
            getAllPoemsError: false,
            poem_id: '',
            successAlert: false,
            successMsg: '',
            error: false,
            errorMsg: '',
            poem_name: '',
            poem_tagline: '',
            poem_desc: '',
            poem_full: '',
            poem_translation: '',
            poem_lang: 'ur',
            errorName: '',
            errorTagline: '',
            errorDesc: '',
            errorFull: '',
            errorTranslation: '',
            errorLang: '',
            errorBook: '',
            showFullPoem: false,
            showFullTrans: false,
            showFullDesc: false,
            currentPage: 1,
            total: 0,
            pageSize: 0,
            search_term: '',
        };
    }

    componentDidMount = async () => {
        const token = JSON.parse(localStorage.getItem('token'))
        this.setState({token: token}, () => {
            if (this.state.token) {
                this.getAllBooks()
                this.getAllPoems(1);
            } else {
                console.log('no token')
                this.props.history.push('/login')
            }
        });
    }
    getAllBooks = async () => {
        try {
            let allBooks = await fetch(api_url + "get_all_books", {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allBooks.ok) {
                this.setState({getAllBooksError: true});
                throw Error('Error');
            } else {
                allBooks = await allBooks.json();
                this.setState({allBooks: allBooks.data,});
                console.warn(this.state.allBooks);
            }
        } catch (err) {
            console.log('Error')
        }
    }
    getAllPoems = async (pageNo) => {
        this.setState({isloading: true})
        try {
            let allPoems = await fetch(api_url + "get_all_poems?page=" + pageNo + '&is_paginated=true', {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allPoems.ok) {
                this.setState({isloading: false, getAllPoemsError: true});
                throw Error('Error');
            } else {
                allPoems = await allPoems.json();
                this.setState({
                    allPoems: allPoems.data.data, isloading: false,
                    total: allPoems.data.total, pageSize: allPoems.data.per_page
                });
                console.warn('all poems', this.state.allPoems);
            }
        } catch (err) {
            console.log('Error')
        }
    }
    deletePoem = async () => {
        this.setState({spinner: true})
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("poem_id", this.state.poem_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "delete_poem", requestOptions)
            .then(response => response.json())
            .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({cancelBooking: result, spinner: false});
                        console.warn(this.state.cancelBooking)
                        this.setState({
                            deleteModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Poem') + this.props.t('Deleted Successfully')
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllPoems(this.state.currentPage);
                    }
                }
            )
            .catch(error => {
                console.log('error', error)
                this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
            });
    }
    addPoem = async () => {
        this.setState({spinner: true})
        console.log('name', this.state.poem_name, 'tagline', this.state.poem_tagline, 'desc', this.state.poem_desc, 'full', this.state.poem_full, 'trans', this.state.poem_translation, 'lang', this.state.poem_lang)
        if (this.state.poem_name) {
            this.setState({errorName: ''})
        } else {
            this.setState({errorName: 'The Name field is required'})
        }
        if (this.state.poem_full) {
            this.setState({errorFull: ''})
        } else {
            this.setState({errorFull: 'The Full Poem field is required'})
        }
        if (this.state.poem_lang) {
            this.setState({errorLang: ''})
        } else {
            this.setState({errorLang: 'The Poem Language field is required'})
        }
        if (this.state.book_id) {
            this.setState({errorBook: ''})
        } else {
            this.setState({errorBook: 'The Book ID field is required'})
        }
        if (this.state.errorName || this.state.errorFull || this.state.errorLang || this.state.errorBook) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("poem_name", this.state.poem_name);
            formdata.append("poem_tagline", this.state.poem_tagline);
            formdata.append("full_poem", this.state.poem_full);
            formdata.append("desc", this.state.poem_desc);
            formdata.append("translation", this.state.poem_translation);
            formdata.append("language", this.state.poem_lang);
            formdata.append("book_id", this.state.book_id);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "insert_poem", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            addModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Poem') + this.props.t('Added Successfully'),
                            spinner: false,
                            poem_name: '',
                            poem_tagline: '',
                            poem_desc: '',
                            poem_full: '',
                            poem_translation: '',
                            poem_lang: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllPoems(this.state.currentPage);
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    getPoemDetail = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${this.state.token}`);

        var formdata = new FormData();
        formdata.append("poem_id", this.state.poem_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(api_url + "detail_poem", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                if (result.status === false) {
                    this.setState({error: true, errorMsg: 'Something Wrong'});
                } else {
                    this.setState({
                        poem_name: result.data.poems.poem_name,
                        poem_tagline: result.data.poems.poem_tagline,
                        poem_desc: result.data.poems.desc,
                        poem_full: result.data.poems.full_poem,
                        poem_translation: result.data.poems.translation,
                        poem_lang: result.data.poems.language,
                        book_id: result.data.poems.book_id
                        // poem_name:result.data[0].name,poem_tagline:result.data[0].tag_line,poem_desc:result.data[0].desc,poem_publisher:result.data[0].publisher,poem_publishDate:result.data[0].publication_date,poem_image:result.data[0].poem_image
                    });
                }
            })
            .catch(error => console.log('error', error));
    }
    updatePoem = async () => {
        this.setState({spinner: true})
        if (this.state.poem_name) {
            this.setState({errorName: ''})
        } else {
            this.setState({errorName: 'The Name field is required'})
        }
        if (this.state.poem_full) {
            this.setState({errorFull: ''})
        } else {
            this.setState({errorFull: 'The full poem field is required'})
        }
        if (this.state.poem_lang) {
            this.setState({errorLang: ''})
        } else {
            this.setState({errorLang: 'The Poem Language field is required'})
        }
        if (this.state.errorName || this.state.errorFull || this.state.errorLang) {
            console.log('all field are required')
            this.setState({spinner: false})
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${this.state.token}`);

            var formdata = new FormData();
            formdata.append("poem_name", this.state.poem_name);
            formdata.append("full_poem", this.state.poem_full);
            if (this.state.poem_tagline != null) {
                formdata.append("poem_tagline", this.state.poem_tagline);
            }
            if (this.state.poem_desc != null) {
                formdata.append("desc", this.state.poem_desc);
            }
            if (this.state.poem_translation != null) {
                formdata.append("translation", this.state.poem_translation);
            }
            formdata.append("language", this.state.poem_lang);
            formdata.append("book_id", this.state.book_id);
            formdata.append("poem_id", this.state.poem_id);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(api_url + "update_poem", requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result.status === false) {
                        this.setState({error: true, errorMsg: 'Something Wrong', spinner: false});
                    } else {
                        this.setState({
                            updateModal: false,
                            successAlert: true,
                            successMsg: this.props.t('Poem') + this.props.t('Updated Successfully'),
                            spinner: false,
                            poem_name: '',
                            poem_tagline: '',
                            poem_desc: '',
                            poem_publisher: '',
                            poem_publishDate: '',
                            poem_image: ''
                        });
                        setTimeout(() => {//hide success msg after 2 seconds
                            this.setState({successAlert: false, successMsg: ''});
                        }, 2000);
                        this.getAllPoems(this.state.currentPage);
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    Search = async () => {
        this.setState({isloading: true });
        try {
            let allPoems = await fetch(api_url + "get_all_poems?search=" + this.state.search_term, {
                method: 'get',
                headers: {
                    "Authorization": `Bearer ${this.state.token}`
                },
            });
            if (!allPoems.ok) {
                this.setState({isloading: false, getAllPoemsError: true});
                throw Error('Error');
            } else {
                allPoems = await allPoems.json();
                this.setState({
                    allPoems: allPoems.data, isloading: false,
                    total: 0, pageSize: 1
                });
                console.warn('all poems', this.state.allPoems);
            }
        } catch (err) {
            console.log('Error')
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={this.props.t('Poems Management')} breadcrumbItems={this.state.breadcrumbItems}/>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Input
                                            type="text"
                                            className="mb-3 float-left"
                                            value={this.state.search_term}
                                            onChange={(e) => {
                                                this.setState({ search_term: e.target.value }, () => {
                                                    this.Search();
                                                });
                                            }}
                                            placeholder={this.props.t("Search")}
                                            style={{ width: '20vw' }}
                                        />
                                        <Button color="success" className="mb-3 float-right"
                                                onClick={() => this.setState({
                                                    addModal: true,
                                                    poem_name: '',
                                                    poem_desc: '',
                                                    poem_tagline: '',
                                                    poem_full: '',
                                                    poem_translation: ''
                                                })}>
                                            <i className="mdi mdi-pen-plus mr-3 font-size-18"></i>{this.props.t('Add Poem')}
                                        </Button>

                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" striped bordered responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>{this.props.t('Id')}</th>
                                                        <th>{this.props.t('Name')}</th>
                                                        <th>{this.props.t('Language')}</th>
                                                        <th>{this.props.t('Actions')}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.isloading ?
                                                        <tr>
                                                            <td colSpan={8}>
                                                                <center>
                                                                    <div className="lds-ripple">
                                                                        <div></div>
                                                                        <div></div>
                                                                    </div>
                                                                </center>
                                                            </td>
                                                        </tr>
                                                        :
                                                        <>
                                                            {this.state.allPoems ?
                                                                this.state.allPoems.length > 0 ?
                                                                    this.state.allPoems.map((n) => (
                                                                        <tr>
                                                                            <th>{n.id}</th>
                                                                            <th><Link to={{
                                                                                pathname: "/poemDetail/" + n.id,
                                                                                state: n.id
                                                                            }}>{n.poem_name}</Link></th>
                                                                            <td>{n.language == 'ur' ? this.props.t('Urdu') : n.language == 'fa' ? this.props.t('Persian') : ''}</td>
                                                                            <td className='d-flex'>
                                                                                <i className="mdi mdi-pencil font-size-18 text-primary cursor-pointer"
                                                                                   title='Edit'
                                                                                   onClick={(event) => this.setState({
                                                                                       poem_id: event.currentTarget.id,
                                                                                       updateModal: true
                                                                                   }, this.getPoemDetail)} id={n.id}
                                                                                ></i>
                                                                                <i className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
                                                                                   title='Delete'
                                                                                   onClick={(event) => this.setState({
                                                                                       poem_id: event.currentTarget.id,
                                                                                       deleteModal: true
                                                                                   })} id={n.id}
                                                                                ></i>
                                                                                <Link to={{
                                                                                    pathname: "/poemDetail/" + n.id,
                                                                                    state: n.id
                                                                                }}>
                                                                                    <i className="mdi mdi-eye font-size-18 text-warning cursor-pointer"
                                                                                       title='View'></i>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    :
                                                                    <tr>
                                                                        <td colSpan={8}>
                                                                            <center>
                                                                                <div>{this.props.t('No Poem Found')}</div>
                                                                            </center>
                                                                        </td>
                                                                    </tr>
                                                                :
                                                                ''
                                                            }
                                                        </>

                                                    }
                                                    </tbody>
                                                </Table>
                                                {this.state.total < this.state.pageSize ?
                                                    ''
                                                    :
                                                    <PaginationControl
                                                        page={this.state.currentPage}
                                                        between={3}
                                                        total={this.state.total}
                                                        limit={this.state.pageSize}
                                                        changePage={(page) => this.setState({currentPage: page}, () => {
                                                            this.getAllPoems(page)
                                                        })}
                                                        ellipsis={1}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* Add modal */}
                        <Modal isOpen={this.state.addModal} centered={true} scrollable={true}>
                            <ModalHeader>{this.props.t('Add Poem')}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="poemName">{this.props.t('Name')}</Label>
                                        <Input id="poemName" type="text" value={this.state.poem_name}
                                               onChange={(e) => this.setState({poem_name: e.target.value})}/>
                                        {this.state.errorName ?
                                            <span className='text-danger'>{this.state.errorName}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t('Description')}</Label>
                                        <Input id="desc" type="textarea" rows={3} value={this.state.poem_desc}
                                               onChange={(e) => this.setState({poem_desc: e.target.value})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="fullPoem">{this.props.t('Full Poem')}</Label>
                                        <Input id="fullPoem" type="textarea" rows={5} value={this.state.poem_full}
                                               onChange={(e) => this.setState({poem_full: e.target.value})}/>
                                        {this.state.errorFull ?
                                            <span className='text-danger'>{this.state.errorFull}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="translation">{this.props.t('Translation')}</Label>
                                        <Input id="translation" type="textarea" rows={5}
                                               value={this.state.poem_translation}
                                               onChange={(e) => this.setState({poem_translation: e.target.value})}/>
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="language">{this.props.t('Language')}</Label>
                                                <select id="language"
                                                        onChange={(e) => this.setState({poem_lang: e.target.value})}
                                                        className='form-control'>
                                                    <option value="fa"
                                                            selected={this.state.poem_lang === 'fa' ? true : false}>{this.props.t('Persian')}</option>
                                                    <option value="ur"
                                                            selected={this.state.poem_lang === 'ur' ? true : false}>{this.props.t('Urdu')}</option>
                                                </select>
                                                {this.state.errorLang ?
                                                    <span className='text-danger'>{this.state.errorLang}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="book">{this.props.t('Book')}</Label>
                                                <select id="book"
                                                        onChange={(e) => this.setState({book_id: e.target.value})}
                                                        className='form-control'>
                                                    <option selected disabled>{this.props.t('Select a Book')}</option>
                                                    {this.state.allBooks ?
                                                        this.state.allBooks.length > 0 ?
                                                            this.state.allBooks.map((n) => (
                                                                <option value={n.id}>{n.name}</option>
                                                            ))
                                                            :
                                                            <option>{this.props.t('No Book Found')}</option>
                                                        :
                                                        ''

                                                    }
                                                </select>
                                                {this.state.errorBook ?
                                                    <span className='text-danger'>{this.state.errorBook}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className='w-100'
                                            onClick={this.addPoem}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Add Poem')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className='w-100'
                                            onClick={() => this.setState({addModal: false})}>{this.props.t('Cancel')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* update modal */}
                        <Modal isOpen={this.state.updateModal} centered={true} scrollable={true}>
                            <ModalHeader>{this.props.t('Update')} {this.props.t('Poem')}</ModalHeader>
                            <ModalBody>
                                <Form>
                                    <FormGroup>
                                        <Label for="poemName">{this.props.t('Name')}</Label>
                                        <Input id="poemName" type="text" value={this.state.poem_name}
                                               onChange={(e) => this.setState({poem_name: e.target.value})}/>
                                        {this.state.errorName ?
                                            <span className='text-danger'>{this.state.errorName}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="desc">{this.props.t('Description')}</Label>
                                        <Input id="desc" type="textarea" rows={3} value={this.state.poem_desc}
                                               onChange={(e) => this.setState({poem_desc: e.target.value})}/>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="fullPoem">{this.props.t('Full Poem')}</Label>
                                        <Input id="fullPoem" type="textarea" rows={5} value={this.state.poem_full}
                                               onChange={(e) => this.setState({poem_full: e.target.value})}/>
                                        {this.state.errorFull ?
                                            <span className='text-danger'>{this.state.errorFull}</span> : ''}
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="translation">{this.props.t('Translation')}</Label>
                                        <Input id="translation" type="textarea" rows={5}
                                               value={this.state.poem_translation}
                                               onChange={(e) => this.setState({poem_translation: e.target.value})}/>
                                    </FormGroup>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="language">{this.props.t('Language')}</Label>
                                                <select id="language"
                                                        onChange={(e) => this.setState({poem_lang: e.target.value})}
                                                        className='form-control'>
                                                    <option value="fa"
                                                            selected={this.state.poem_lang === 'fa' ? true : false}>{this.props.t('Persian')}</option>
                                                    <option value="ur"
                                                            selected={this.state.poem_lang === 'ur' ? true : false}>{this.props.t('Urdu')}</option>
                                                </select>
                                                {this.state.errorLang ?
                                                    <span className='text-danger'>{this.state.errorLang}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="book">{this.props.t('Book')}</Label>
                                                <select id="book"
                                                        onChange={(e) => this.setState({book_id: e.target.value})}
                                                        className='form-control'>
                                                    <option selected disabled>{this.props.t('Select a Book')}</option>
                                                    {this.state.allBooks ?
                                                        this.state.allBooks.length > 0 ?
                                                            this.state.allBooks.map((n) => (
                                                                <option value={n.id}
                                                                        selected={n.id === this.state.book_id}>{n.name}</option>
                                                            ))
                                                            :
                                                            <option>{this.props.t('No Book Found')}</option>
                                                        :
                                                        ''

                                                    }
                                                </select>
                                                {this.state.errorBook ?
                                                    <span className='text-danger'>{this.state.errorBook}</span> : ''}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                {this.state.error ? <span className='text-danger'>{this.state.errorMsg}</span> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className='w-100'
                                            onClick={this.updatePoem}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Update')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className='w-100'
                                            onClick={() => this.setState({updateModal: false})}>{this.props.t('Cancel')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* Delete modal */}
                        <Modal isOpen={this.state.deleteModal} centered={true}>
                            <ModalHeader>{this.props.t('Delete')} {this.props.t('Poem')}</ModalHeader>
                            <ModalBody>
                                {this.props.t('delete text')}
                                {/* Are you sure to delete this? */}
                                {this.state.error ? <div className='text-danger'>{this.state.errorMsg}</div> : ''}
                            </ModalBody>
                            <ModalFooter className='row d-flex justify-content-between align-items-center'>
                                <Col>
                                    <Button color="primary" className='w-100'
                                            onClick={this.deletePoem}>{this.state.spinner ?
                                        <Spinner type="border" color="light"
                                                 size="sm"/> : this.props.t('Yes')}</Button>{' '}
                                </Col>
                                <Col>
                                    <Button color="secondary" className='w-100'
                                            onClick={() => this.setState({deleteModal: false})}>{this.props.t('No')}</Button>
                                </Col>
                            </ModalFooter>
                        </Modal>
                        {/* success alert */}
                        {this.state.successAlert ?
                            <Alert color="success"
                                   style={{position: 'absolute', top: '50px', right: '25px', zIndex: '10000'}}>
                                {this.state.successMsg}
                            </Alert>
                            :
                            ''
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Poem);