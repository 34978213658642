import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup,Spinner } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
//i18n
import i18n from '../../i18n';
// actions
import { checkLogin, apiError } from '../../store/actions';

import {api_url} from '../../components/Common/Variables'
import { withNamespaces } from 'react-i18next';

import logo from '../../assets/images/logo new-01.png'
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {  
            username : "admin@iqbalapp.com", 
            password : "1234" ,
            isloading:false,
            loginError:false,
            loginErrorMsg:'',
            lng : localStorage.getItem("lang"),
        }
        // this.state = {  username : "admin@themesdesign.in", password : "123456" ,isloading:false}
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeLanguageAction.bind(this);
    }

    handleSubmit(event, values) {
        this.props.checkLogin(values, this.props.history);
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        const lang = localStorage.getItem("lang");
        console.log('lang is ', lang)
        if(lang == "eng"){
          this.changeLanguageAction('eng')
          this.setState({lng: "English"})
        }else{
          this.changeLanguageAction('ur')
          this.setState({lng:"Urdu"})
        }
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }

    changeLanguageAction = (lng) => {
    
        //set the selected language to i18n
        i18n.changeLanguage(lng);
        document.body.dir = i18n.dir();
    }

    loginAdmin = async()=>{
        console.log('login button clicked');
        console.log(this.state.username,this.state.password)
        this.setState({isloading:true})
        try{
            var formdata = new FormData();
            formdata.append("email", this.state.username);
            formdata.append("password", this.state.password);
            
            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };
            
            fetch(api_url+"adminlogin", requestOptions)
              .then(response => response.json())
              .then(result => {
                this.setState({isloading:false})
                console.log(result)
                if(result.status == true){
                    localStorage.setItem("authUser", JSON.stringify(result.data.user_data));
                    localStorage.setItem("token", JSON.stringify(result.data.token));
                    this.props.history.push('/dashboard')
                }else{
                    this.setState({loginError:true, loginErrorMsg:result.message},console.log(this.state.loginErrorMsg));
                    setTimeout(() => {
                        this.setState({loginError:false,loginErrorMsg:''})
                    }, 3000);
                }
            })
              .catch(error => {
                console.log('error', error)
              }
                );
        }catch(error){
            this.setState({loginError:true})
        }
    }
    render() {

        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>
                
                <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <Link to="/"><img className="login-logo" src={logo} alt="logo"/></Link>
                                                </div>
                                                <center>
                                                <h1 className="font-size-18 mt-4">{this.props.t('Welcome Back !')}</h1>
                                                </center>
                                                {/* <p className="text-muted">Sign in to continue to Nazox.</p> */}
                                            </div>

                                           
                                            {this.state.loginError ? <Alert color="danger">{this.state.loginErrorMsg ? this.state.loginErrorMsg : 'Something Wrong!'}</Alert> : null }

                                            <div className="p-2 mt-5">
                                                <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >
                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon text-green"></i>
                                                        <Label htmlFor="username">{this.props.t('Username')}</Label>
                                                        <AvField name="username" value={this.state.username} type="text" className="form-control" id="username" validate={{email: true, required: true}} placeholder="Enter username" onChange={(e)=>this.setState({username:e.target.value})}/>
                                                    </FormGroup>
                            
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon text-green"></i>
                                                        <Label htmlFor="userpassword">{this.props.t('Password')}</Label>
                                                        <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password" onChange={(e)=>this.setState({password:e.target.value})}/>
                                                    </FormGroup>
                            
                                                    {/* <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div> */}

                                                    <div className="mt-4 text-center">
                                                        {/* <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button> */}
                                                        <Button color="success" className="w-md waves-effect waves-light" onClick={this.loginAdmin}>
                                                            {this.state.isloading ? <Spinner type="border" color="light" size="sm"/> : this.props.t('Login')}
                                                        </Button>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div> */}
                                                </AvForm>
                                            </div>

                                            {/* <div className="mt-5 text-center">
                                                <p>Don't have an account ? <Link to="/register" className="font-weight-medium text-primary"> Register </Link> </p>
                                                <p>© 2020 Nazox. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                            </div> */}
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg">
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withNamespaces() (withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login)));