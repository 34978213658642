import React, { useEffect, useState } from "react";
import {
	Row,
	Col,
	Card,
	CardBody,
	Container,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	Spinner,
	Alert,
	Form,
	Label,
	Input,
	FormGroup,
} from "reactstrap";
import "../Tables/datatables.scss";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { api_url } from "../../components/Common/Variables";
function Banner(props) {
	const [breadcrumbItems, setBreadcrumbItems] = useState([
		{ title: props.t("Dashboard"), link: "#" },
		{ title: props.t("Banner Management"), link: "#" },
	]);
	const [updateModal, setUpdateModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [token, setToken] = useState("");
	const [isloading, setIsloading] = useState(false);
	const [spinner, setSpinner] = useState(false);
	const [allBanners, setAllBanners] = useState([]);
	const [getAllBannerError, setGetAllBannerError] = useState(false);
	const [banner_id, setBannerId] = useState("");
	const [successAlert, setSuccessAlert] = useState(false);
	const [successMsg, setSuccessMsg] = useState("");
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const [banner_text, setBannerText] = useState("");
	const [errorText, setErrorText] = useState("");
	const [banner_image, setBannerImage] = useState("");
	const [errorImage, setErrorImage] = useState("");
	const [name, setName] = useState("");
	const [getAllCateError, setGetAllCateError] = useState("");
	const [addBanner, setAddBanner] = useState("");
	const [errorName, setErrorName] = useState("");
	const [category_id, setCategoryId] = useState(null);
	useEffect(() => {
		const token = JSON.parse(localStorage.getItem("token"));
		setToken(token);
		if (token) {
			getAllBanners();
		} else {
			console.log("no token");
			props.history.push("/login");
		}
		console.warn("All Banners", allBanners);
	}, [token]);

	const getAllBanners = async () => {
		setIsloading(true);
		try {
			let allBanner = await fetch(api_url + "get_all_banner", {
				method: "get",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (!allBanner.ok) {
				setIsloading(false);
				setGetAllCateError(true);
				throw Error("Error");
			} else {
				allBanner = await allBanner.json();
				setAllBanners(allBanner.data);
				setIsloading(false);
				console.warn("All Banners", allBanners);
			}
		} catch (err) {
			console.log("Error");
		}
	};

	const AddBanner = async () => {
		setSpinner(true);
		if (banner_text) {
			setErrorText("");
		} else {
			setErrorText("The Text field is required");
		}
		if (banner_image) {
			setErrorImage("");
		} else {
			setErrorImage("The Image field is required");
		}
		if (errorText || errorImage) {
			console.log("all field are required");
			setSpinner(false);
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${token}`);

			var formdata = new FormData();
			formdata.append("banner_text", banner_text);
			formdata.append("banner_image", banner_image);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "insert_banner", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						setError(true);
						setErrorMsg("Something Wrong");
						setSpinner(false);
					} else {
						setAddModal(false);
						setSuccessAlert(true);
						setSuccessMsg(props.t("Banner") + props.t("Added Successfully"));
						setSpinner(false);
						setBannerImage("");
						setBannerText("");
						setTimeout(() => {
							setSuccessAlert(true);
							setSuccessMsg("");
						}, 2000);
						getAllBanners();
					}
				})
				.catch((error) => console.log("error", error));
		}
	};

	const getBannerDetail = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		var formdata = new FormData();
		formdata.append("banner_id", category_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "detail_category", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					setError(true);
					setErrorMsg("Something Wrong");
				} else {
					setName(result.data.name);
				}
			})
			.catch((error) => console.log("error", error));
	};

	const updateBanner = async () => {
		setSpinner(true);
		if (name) {
			setErrorName("");
		} else {
			setErrorName("The Name field is required");
		}
		if (errorName) {
			console.log("all field are required");
			setSpinner(false);
		} else {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${token}`);

			var formdata = new FormData();
			formdata.append("banner_text", name);
			formdata.append("banner_id", banner_id);
			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: formdata,
				redirect: "follow",
			};

			fetch(api_url + "update_banner", requestOptions)
				.then((response) => response.json())
				.then((result) => {
					console.log(result);
					if (result.status === false) {
						setError(true);
						setErrorMsg("Something Wrong");
						setSpinner(false);
					} else {
						setUpdateModal(false);
						setSuccessAlert(true);
						setSuccessMsg(
							props.t("Category") + props.t("Updated Successfully")
						);
						setSpinner(false);
						setName("");
						setCategoryId("");

						setTimeout(() => {
							setSuccessAlert(false);
							setSuccessMsg("");
						}, 2000);
						getAllBanners();
					}
				})
				.catch((error) => console.log("error", error));
		}
	};

	const deleteBanner = async () => {
		setSpinner(true);
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${token}`);

		console.log("banner", banner_id);

		var formdata = new FormData();
		formdata.append("banner_id", banner_id);

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: formdata,
			redirect: "follow",
		};

		fetch(api_url + "delete_banner", requestOptions)
			.then((response) => response.json())
			.then((result) => {
				console.log(result);
				if (result.status === false) {
					setError(true);
					setErrorMsg("Something Wrong");
					setSpinner(false);
				} else {
					setSpinner(false);
					setDeleteModal(false);
					setSuccessAlert(true);
					setSuccessMsg(props.t("Category") + props.t("Deleted Successfully"));

					setTimeout(() => {
						setSuccessAlert(false);
						setSuccessMsg("");
					}, 2000);
					getAllBanners();
				}
			})
			.catch((error) => {
				console.log("error", error);
				setError(true);
				setErrorMsg("Something Wrong");
				setSpinner(false);
			});
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs
						title={props.t("Banner Management")}
						breadcrumbItems={breadcrumbItems}
					/>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Button
										color="success"
										className="mb-3 float-right"
										onClick={() => {
											setAddModal(true);
											setName("");
										}}
									>
										<i className="mdi mdi-plus-outline mr-3 font-size-18"></i>
										{props.t("Add Banner")}
									</Button>

									{/* <MDBDataTable responsive striped bordered data={data} /> */}
									<div className="table-rep-plugin">
										<div
											className="table-responsive mb-0"
											data-pattern="priority-columns"
										>
											<Table id="tech-companies-1" striped bordered responsive>
												<thead>
													<tr>
														<th>{props.t("Name")}</th>
														<th>{props.t("Banner")}</th>
														<th>{props.t("Actions")}</th>
													</tr>
												</thead>
												<tbody>
													{isloading ? (
														<tr>
															<td colSpan={3}>
																<center>
																	<div className="lds-ripple">
																		<div></div>
																		<div></div>
																	</div>
																</center>
															</td>
														</tr>
													) : allBanners ? (
														allBanners.length > 0 ? (
															allBanners.map((n) => (
																<tr>
																	<th>
																		<img
																			src={n.banner_image}
																			alt="banner"
																			style={{
																				height: "60px",
																				width: "60px",
																				padding: "0",
																			}}
																		/>
																	</th>
																	<th>{n.banner_text}</th>
																	<td>
																		<i
																			className="mdi mdi-pencil font-size-18 text-primary mr-3 cursor-pointer"
																			title="Edit"
																			onClick={(event) => {
																				console.log(
																					"Clicked:",
																					event.currentTarget.id
																				);
																				setBannerId(event.currentTarget.id);
																				setName(n.banner_text);
																				setUpdateModal(true);
																				getBannerDetail();
																			}}
																			id={n.id}
																		></i>
																		<i
																			className="mdi mdi-trash-can font-size-18 text-danger cursor-pointer"
																			title="Delete"
																			onClick={(event) => {
																				console.log(
																					"Clicked:",
																					event.currentTarget.id
																				);
																				setBannerId(event.currentTarget.id);
																				setDeleteModal(true);
																			}}
																			id={n.id}
																		></i>
																	</td>
																</tr>
															))
														) : (
															<tr>
																<td colSpan={8}>
																	<center>
																		<div>{props.t("No Banner Found")}</div>
																	</center>
																</td>
															</tr>
														)
													) : (
														""
													)}
												</tbody>
											</Table>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
					{/* Add modal */}
					<Modal isOpen={addModal} centered={true}>
						<ModalHeader>{props.t("Add Banner")}</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<Label for="Name">{props.t("Name")}</Label>
									<Input
										id="Name"
										type="text"
										value={banner_text}
										onChange={(e) => setBannerText(e.target.value)}
									/>
									{errorText ? (
										<span className="text-danger">{errorText}</span>
									) : (
										""
									)}
								</FormGroup>
								<FormGroup>
									<Label for="image">{props.t("Image")}</Label>
									<Input
										id="image"
										type="file"
										onChange={(e) => setBannerImage(e.target.files[0])}
									/>
									{errorImage ? (
										<span className="text-danger">{errorImage}</span>
									) : (
										""
									)}
								</FormGroup>
							</Form>
							{error ? <span className="text-danger">{errorMsg}</span> : ""}
						</ModalBody>
						<ModalFooter className="row d-flex justify-content-between align-items-center">
							<Col>
								<Button color="primary" className="w-100" onClick={AddBanner}>
									{spinner ? (
										<Spinner type="border" color="light" size="sm" />
									) : (
										props.t("Add Banner")
									)}
								</Button>{" "}
							</Col>
							<Col>
								<Button
									color="secondary"
									className="w-100"
									onClick={() => setAddModal(false)}
								>
									{props.t("Cancel")}
								</Button>
							</Col>
						</ModalFooter>
					</Modal>
					{/* update modal */}
					<Modal isOpen={updateModal} centered={true}>
						<ModalHeader>
							{props.t("Update")} {props.t("Banner")}
						</ModalHeader>
						<ModalBody>
							<Form>
								<FormGroup>
									<Label for="Name">{props.t("Name")}</Label>
									<Input
										id="Name"
										type="text"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
									{errorName ? (
										<span className="text-danger">{errorName}</span>
									) : (
										""
									)}
								</FormGroup>
							</Form>
							{error ? <span className="text-danger">{errorMsg}</span> : ""}
						</ModalBody>
						<ModalFooter className="row d-flex justify-content-between align-items-center">
							<Col>
								<Button
									color="primary"
									className="w-100"
									onClick={updateBanner}
								>
									{spinner ? (
										<Spinner type="border" color="light" size="sm" />
									) : (
										props.t("Update")
									)}
								</Button>{" "}
							</Col>
							<Col>
								<Button
									color="secondary"
									className="w-100"
									onClick={() => setUpdateModal(false)}
								>
									{props.t("Cancel")}
								</Button>
							</Col>
						</ModalFooter>
					</Modal>
					{/* Delete modal */}
					<Modal isOpen={deleteModal} centered={true}>
						<ModalHeader>
							{props.t("Delete")} {props.t("Banner")}
						</ModalHeader>
						<ModalBody>
							{props.t("delete text")}
							{error ? <div className="text-danger">{errorMsg}</div> : ""}
						</ModalBody>
						<ModalFooter className="row d-flex justify-content-between align-items-center">
							<Col>
								<Button
									color="primary"
									className="w-100"
									onClick={deleteBanner}
								>
									{spinner ? (
										<Spinner type="border" color="light" size="sm" />
									) : (
										props.t("Yes")
									)}
								</Button>{" "}
							</Col>
							<Col>
								<Button
									color="secondary"
									className="w-100"
									onClick={() => setDeleteModal(false)}
								>
									{props.t("No")}
								</Button>
							</Col>
						</ModalFooter>
					</Modal>
					{/* success alert */}
					{successAlert ? (
						<Alert
							color="success"
							style={{
								position: "absolute",
								top: "50px",
								right: "25px",
								zIndex: "10000",
							}}
						>
							{successMsg}
						</Alert>
					) : (
						""
					)}
				</Container>
			</div>
		</React.Fragment>
	);
}

export default withNamespaces()(Banner);
